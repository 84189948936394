import React from 'react'
import { useIntl } from 'react-intl'
import { slice } from 'lodash'
import { usePrevious } from 'react-use'
import {
  PdfGenerationType,
  HtmlGenerationType,
  ItemSearch,
  DetailAccount,
  InfoDataBaseAccount,
} from '../../types/guillotina'
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  TableRow,
  TableCell,
  Tooltip,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { messages } from './messages'
import { getFile, userHasFeaturePermission } from '../../helpers'
import { config } from '../../config'
import { SET_PDF } from '../../constants/FilesTypes'
import { FEAT_PDF_REPORT_DOWNLOAD_PDF } from '../../constants/Roles'
import { ClipLoaderSpinner } from '../../components'

interface Props {
  account: DetailAccount | null
  basic_info_account: InfoDataBaseAccount | null
  generation_item: PdfGenerationType | HtmlGenerationType
  match_params: any
  reset_values: boolean
  permissions: BeePermissions
  token: string | null
  setDeleteDialogData: (
    value: React.SetStateAction<
      DialogState & {
        type: string
      }
    >
  ) => void
  setAlertInfo: (value: React.SetStateAction<AlertInfo>) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    fill: '#000',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  chip: {
    marginLeft: theme.spacing(3),
  },
}))

export const GenerateReportsDetailsPageDetailGeneration: React.FC<Props> = ({
  account,
  basic_info_account,
  generation_item,
  match_params,
  permissions,
  setAlertInfo,
  setDeleteDialogData,
  token,
  reset_values,
}) => {
  const classes = useStyles({})

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage, formatDate, formatTime } = intl

  /**
   * React Hooks
   */
  const previous_reset_value = usePrevious(reset_values)
  const [loading_data, setLoadingData] = React.useState<boolean>(false)
  const [ws_token, setWsToken] = React.useState<string | null>(null)
  const [generation_full_item, setGenerationFullItem] = React.useState<
    PdfGenerationType | HtmlGenerationType | null
  >(null)
  const [page, setPage] = React.useState(1)

  React.useEffect(() => {
    if (
      (!generation_full_item && basic_info_account) ||
      (reset_values && previous_reset_value !== reset_values && basic_info_account)
    ) {
      setLoadingData(true)
      const url = `${config.apiUrl}/${basic_info_account.url}/${SET_PDF}/${match_params.name}/${generation_item['@name']}/?fullobjects=true`
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setLoadingData(false)
          if (response.status !== 200) {
            throw new Error(`Status: ${response.status} - Message: ${response.statusText}`)
          }
          response.json().then((data: any) => {
            setGenerationFullItem(data)
          })
        })
        .catch((error: any) => {
          setLoadingData(false)
          setAlertInfo({
            open: true,
            type: 'error',
            message: error.toString(),
          })
        })
    }
  }, [
    generation_item,
    basic_info_account,
    match_params.name,
    generation_full_item,
    token,
    setAlertInfo,
    reset_values,
    previous_reset_value,
  ])

  React.useEffect(() => {
    if (basic_info_account && ws_token) {
      const url = `${config.apiUrl}/${basic_info_account.url}/${SET_PDF}/${match_params.name}/${
        generation_item['@name']
      }/@${
        generation_item['@type'] === 'HtmlGeneration' ? 'downloadAndZipHtmls' : 'downloadAndZipPdfs'
      }?ws_token=${ws_token}`
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      setTimeout(() => {
        setWsToken(null)
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 100)
    }
  }, [basic_info_account, ws_token, generation_item, match_params.name])

  /**
   * Component functions
   */
  const getPdfOrHtmlReports = React.useMemo(
    () =>
      generation_full_item &&
      generation_full_item.items &&
      generation_full_item.items.filter((item: ItemSearch) => {
        return item.type_name !== 'PdfLogs' && item.type_name !== 'HtmlLogs'
      }),
    [generation_full_item]
  )

  const downloadFiles = () => {
    if (basic_info_account) {
      fetch(`${config.apiUrl}/${basic_info_account.url}/@wstoken`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(`Status: ${response.status} - Message: ${response.statusText}`)
          }
          response.json().then((data: any) => {
            setAlertInfo((prev_state) => {
              return { ...prev_state, open: false }
            })
            setWsToken(data.token)
          })
        })
        .catch((error: any) => {
          setAlertInfo({
            open: true,
            type: 'error',
            message: error.toString(),
          })
        })
    }
  }

  // const generateZipFile = () => {
  //   if (basic_info_account) {
  //     const url = `${config.apiUrl}/${basic_info_account.url}/${SET_PDF}/${
  //       match_params.name
  //     }/${generation_item["@name"]}/@${
  //       generation_item["@type"] === "HtmlGeneration"
  //         ? "downloadHtmls"
  //         : "downloadPdfs"
  //     }`;
  //     setLoadingPostZip(true);
  //     fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       redirect: "follow",
  //     })
  //       .then((response) => {
  //         setLoadingPostZip(false);

  //         if (response.status !== 200) {
  //           throw new Error(
  //             `Status: ${response.status} - Message: ${response.statusText}`
  //           );
  //         } else {
  //           setAlertInfo({
  //             open: true,
  //             type: "success",
  //             message: formatMessage(messages.generateZipSuccessful),
  //           });
  //         }
  //       })
  //       .catch((error: any) => {
  //         setLoadingPostZip(false);
  //         setAlertInfo({
  //           open: true,
  //           type: "error",
  //           message: error.toString(),
  //         });
  //       });
  //   }
  // };

  /**
   * Render
   */

  const renderPdfToDownload = () => {
    const items = getPdfOrHtmlReports
    if (items && items.length > 0) {
      return (
        <>
          {slice(items, 0, page * 50).map((item: ItemSearch) => (
            <TableRow key={item['@name']}>
              <TableCell scope="row">{item['@name']}</TableCell>
              <TableCell scope="row">
                {`${formatDate(new Date(item.modification_date))} ${formatTime(
                  new Date(item.modification_date)
                )}`}
              </TableCell>
              <TableCell align="right">
                <Tooltip
                  title={formatMessage(
                    generation_item['@type'] === 'PdfGeneration'
                      ? messages.downloadPdf
                      : messages.downloadHtml
                  )}
                >
                  <ArrowDownwardIcon
                    className={classes.icon}
                    onClick={() => {
                      if (account && token) {
                        getFile(
                          `${config.apiUrl}/db/${account['@name']}/${SET_PDF}/${match_params.name}/${generation_item['@name']}/${item['@name']}/@download/file`,
                          token
                        ).then((data: any) => {
                          const a = document.createElement('a')
                          a.href = data
                          if (generation_item['@type'] === 'PdfGeneration') {
                            a.download = `${item['@name']}.pdf`
                          } else {
                            a.download = `${item['@name']}.html`
                          }
                          a.click()
                        })
                      }
                    }}
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
          {generation_full_item && generation_full_item.length > page * 50 && (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setPage(page + 1)}
                >
                  {formatMessage(messages.loadMore)}
                </Button>
              </TableCell>
            </TableRow>
          )}
        </>
      )
    } else {
      return (
        <TableRow>
          <TableCell colSpan={3} align="center">
            <div>
              {formatMessage(
                generation_item['@type'] === 'PdfGeneration'
                  ? messages.noResultsPdf
                  : messages.noResultsHtml
              )}
            </div>
          </TableCell>
        </TableRow>
      )
    }
  }

  const renderDownloadDeleteAllSection = () => {
    if (
      userHasFeaturePermission(FEAT_PDF_REPORT_DOWNLOAD_PDF, permissions) &&
      generation_full_item
    ) {
      const items = getPdfOrHtmlReports
      return (
        <>
          {!generation_full_item.generating && items && items.length > 0 ? (
            <Box mb={2} mt={2} display="flex" justifyContent="space-between">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => downloadFiles()}
              >
                {formatMessage(messages.downloadAll)}
              </Button>
              {/* {!loadingPostZip ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => generateZipFile()}
                >
                  {formatMessage(messages.createZipFile)}
                </Button>
              ) : (
                <ClipLoaderSpinner loading={loadingPostZip} size={50} />
              )} */}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  setDeleteDialogData({
                    open: true,
                    itemName: generation_item['@name'],
                    itemTitle: '',
                    type: generation_item['@type'],
                  })
                }}
              >
                {formatMessage(messages.deleteAll)}
              </Button>
            </Box>
          ) : null}

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{formatMessage(messages.contractId)}</TableCell>
                <TableCell>{formatMessage(messages.lastModificationDate)}</TableCell>
                <TableCell align="right">{formatMessage(messages.actions)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderPdfToDownload()}</TableBody>
          </Table>
        </>
      )
    }
  }

  const renderLogsSection = () => {
    if (generation_full_item && generation_full_item.generating) {
      return (
        <ExpansionPanel key={generation_item['@name']}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              {formatMessage(messages.viewLogsTitle)}
            </Typography>
          </ExpansionPanelSummary>
          {generation_full_item.items && (
            <ExpansionPanelDetails>
              {generation_full_item.items.map((item: any, index) => {
                if (item.type_name === 'PdfLogs' || item.type_name === 'HtmlLogs') {
                  return (
                    <div key={`${item.id}_${index}`} style={{ whiteSpace: 'pre-wrap' }}>
                      {item.logs}
                    </div>
                  )
                }
                return null
              })}
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
      )
    }
  }

  return (
    <div className={classes.root}>
      {loading_data ? (
        <ClipLoaderSpinner loading={loading_data} />
      ) : (
        <>
          {renderLogsSection()}
          {renderDownloadDeleteAllSection()}
        </>
      )}
    </div>
  )
}

export default GenerateReportsDetailsPageDetailGeneration
