/**
 * Api helper.
 * @module helpers/Api
 */

import superagent, { Request } from 'superagent'

// import { settings } from '~/config';
import { config } from '../../config'

const methods = ['get', 'post', 'put', 'patch', 'del']

/**
 * Format the url.
 * @function formatUrl
 * @param {string} path Path to be formatted.
 * @returns {string} Formatted path.
 */
const formatUrl = (path: string) => {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path
  return `${config.apiUrl}${adjustedPath}`
}

export interface ApiHelper {
  [key: string]: any
}

/**
 * Api methods helper.
 */
export const Api = (): ApiHelper => {
  const methodsHelper: ApiHelper = {}

  methods.forEach((method: string) => {
    methodsHelper[method] = (path: string, { params, data, type, headers = {} }: any = {}) => {
      const request: Request = (superagent as IndexSignatureType)[method](formatUrl(path))
      const promise: any = new Promise((resolve, reject) => {
        if (params) {
          request.query(params)
        }

        const authToken = localStorage.getItem('auth_token')
        if (authToken) {
          request.set('Authorization', `Bearer ${authToken}`)
        }

        request.set('Accept', 'application/json')

        if (type) {
          request.type(type)
        }

        Object.keys(headers).forEach((key) => request.set(key, headers[key]))

        if (data) {
          request.send(data)
        }

        request.end((err, { body }: any = {}) => (err ? reject(err) : resolve(body)))
      })
      promise.request = request
      return promise
    }
  })

  return methodsHelper
}
