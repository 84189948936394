import {
  HomePage,
  ReportsPage,
  EditorPage,
  LoginPage,
  NotFoundPage,
  ApplicationsPage,
  AddApplicationPage,
  EditApplicationPage,
  ChoosePluginsPage,
  CustomizeLayoutPage,
  PreviewReportPage,
  GenerateReportsPage,
  ManageConfigGenerateReportPage,
  LanguagesPage,
  CustomizeApplicationPage,
  ManageImagesPage,
  PreviewApplicationPage,
  ServiceTokenPage,
  GenerateReportsDetailsPage,
  ChangeAccountRedirectPage,
  GenerateAccountText,
} from '../pages'

export const HOME_PAGE_ROUTE = 'home-page'
export const CHANGE_ACCOUNT_REDIRECT = 'change-account-redirect-page'
export const REPORTS_PAGE_ROUTE = 'reports-page'
export const ADD_REPORT_ROUTE = 'add-report'
export const EDIT_REPORT_ROUTE = 'edit-report'
export const LOGIN_PAGE_ROUTE = 'login-page'
export const NOT_FOUND_PAGE_ROUTE = 'not-found-page'
export const APPLICATIONS_PAGE_ROUTE = 'applications-page'
export const ADD_APPLICATION_ROUTE = 'add-application'
export const EDIT_APPLICATION_ROUTE = 'edit-application'
export const CHOOSE_PLUGINS_PAGE_ROUTE = 'choose-plugins-page'
export const MANAGE_LANGUAGES_PAGE_ROUTE = 'manage-languages-page'
export const MANAGE_IMAGES_PAGE_ROUTE = 'manage-images-page'
export const CUSTOMIZE_LAYOUT_PAGE_ROUTE = 'customize-layout-page'
export const CUSTOMIZE_APPLICATION_PAGE_ROUTE = 'customize-application-page'
export const PREVIEW_REPORT_PAGE_ROUTE = 'preview-report-page'
export const PREVIEW_VERSION_REPORT_PAGE_ROUTE = 'preview-version-report-page'
export const PREVIEW_APPLICATION_PAGE_ROUTE = 'preview-application-page'
export const GENERATE_ACCOUNT_TEXT = 'generate-account-text-page'
export const GENERATE_REPORTS_PAGE_ROUTE = 'generate-reports-page'
export const CREATE_CONFIGURATION_REPORT_PAGE_ROUTE = 'create-configuration-report-page'
export const EDIT_CONFIGURATION_REPORT_PAGE_ROUTE = 'edit-configuration-report-page'
export const VIEW_ALL_GENERATIONS_CONFIGURATION_REPORT_PAGE_ROUTE =
  'view-all-generations-configuration-report-page'
export const SERVICE_TOKENS_PAGE_ROUTE = 'service-tokens-page-route'

const routes: RoutesObject = {}
routes[HOME_PAGE_ROUTE] = {
  path: '/',
  component: HomePage,
  isPrivate: true,
}
routes[CHANGE_ACCOUNT_REDIRECT] = {
  path: '/change_account/:account_id',
  component: ChangeAccountRedirectPage,
  isPrivate: true,
  pathWithParam: (account_id: string) => `/change_account/${account_id}`,
}
routes[REPORTS_PAGE_ROUTE] = {
  path: '/reports',
  component: ReportsPage,
  isPrivate: true,
}
routes[ADD_REPORT_ROUTE] = {
  path: '/report/editor/add',
  component: EditorPage,
  isPrivate: true,
}
routes[EDIT_REPORT_ROUTE] = {
  path: '/report/editor/:name',
  component: EditorPage,
  isPrivate: true,
  pathWithParam: (name: string) => `/report/editor/${name}`,
}
routes[PREVIEW_REPORT_PAGE_ROUTE] = {
  path: '/report/preview/:name',
  component: PreviewReportPage,
  isPrivate: true,
  pathWithParam: (name: string) => `/report/preview/${name}`,
}
routes[PREVIEW_VERSION_REPORT_PAGE_ROUTE] = {
  path: '/report/preview/:name/:version',
  component: PreviewReportPage,
  isPrivate: true,
  pathWithParam: (name: string, version: string) => `/report/preview/${name}/${version}`,
}
routes[APPLICATIONS_PAGE_ROUTE] = {
  path: '/applications',
  component: ApplicationsPage,
  isPrivate: true,
}
routes[ADD_APPLICATION_ROUTE] = {
  path: '/application/add',
  component: AddApplicationPage,
  isPrivate: true,
}
routes[EDIT_APPLICATION_ROUTE] = {
  path: '/application/:name',
  component: EditApplicationPage,
  isPrivate: true,
  pathWithParam: (name: string) => `/application/${name}`,
}
routes[PREVIEW_APPLICATION_PAGE_ROUTE] = {
  path: '/application/preview/:name',
  component: PreviewApplicationPage,
  isPrivate: true,
  pathWithParam: (name: string) => `/application/preview/${name}`,
}
routes[CHOOSE_PLUGINS_PAGE_ROUTE] = {
  path: '/choose-plugins',
  component: ChoosePluginsPage,
  isPrivate: true,
}
routes[CUSTOMIZE_LAYOUT_PAGE_ROUTE] = {
  path: '/customize-layout',
  component: CustomizeLayoutPage,
  isPrivate: true,
}
routes[CUSTOMIZE_APPLICATION_PAGE_ROUTE] = {
  path: '/customize-application',
  component: CustomizeApplicationPage,
  isPrivate: true,
}
routes[MANAGE_LANGUAGES_PAGE_ROUTE] = {
  path: '/manage-languages',
  component: LanguagesPage,
  isPrivate: true,
}
routes[MANAGE_IMAGES_PAGE_ROUTE] = {
  path: '/manage-images',
  component: ManageImagesPage,
  isPrivate: true,
}
routes[GENERATE_REPORTS_PAGE_ROUTE] = {
  path: '/generate-reports',
  component: GenerateReportsPage,
  isPrivate: true,
}
routes[CREATE_CONFIGURATION_REPORT_PAGE_ROUTE] = {
  path: '/config-generate-report/add',
  component: ManageConfigGenerateReportPage,
  isPrivate: true,
}
routes[EDIT_CONFIGURATION_REPORT_PAGE_ROUTE] = {
  path: '/config-generate-reports/:name',
  component: ManageConfigGenerateReportPage,
  isPrivate: true,
  pathWithParam: (name: string) => `/config-generate-reports/${name}`,
}
routes[VIEW_ALL_GENERATIONS_CONFIGURATION_REPORT_PAGE_ROUTE] = {
  path: '/config-generate-reports/:name/generations',
  component: GenerateReportsDetailsPage,
  isPrivate: true,
  pathWithParam: (name: string) => `/config-generate-reports/${name}/generations`,
}
routes[SERVICE_TOKENS_PAGE_ROUTE] = {
  path: '/service-tokens',
  component: ServiceTokenPage,
  isPrivate: true,
}
routes[LOGIN_PAGE_ROUTE] = {
  path: '/login',
  component: LoginPage,
  isPrivate: false,
}
routes[GENERATE_ACCOUNT_TEXT] = {
  path: '/generate-account-text',
  component: GenerateAccountText,
  isPrivate: true,
}
// El path the NOT_FOUND_PAGE_ROUTE ha de ser l'últim element.
routes[NOT_FOUND_PAGE_ROUTE] = {
  path: '*',
  component: NotFoundPage,
  isPrivate: false,
}

export default routes
