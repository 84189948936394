import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { createContent, deleteContent, getContent, resetContent } from '../../actions'
import {
  ClipLoaderSpinner,
  CustomBreadcrumbs,
  DialogGenericAction,
  SnackbarWrapper,
} from '../../components'
import { FEAT_IMAGE_CREATE, FEAT_IMAGE_DELETE } from '../../constants/Roles'
import routes, { HOME_PAGE_ROUTE } from '../../constants/Routes'
import {
  getBase64,
  getErrorMessage,
  getFile,
  getFormattedObjectId,
  userHasFeaturePermission,
} from '../../helpers'
import { AppState } from '../../reducers'
import { RowImage } from './RowImage'
import CollectionsIcon from '@material-ui/icons/Collections'

const messages = defineMessages({
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  manageImages: {
    id: 'manage-images',
    defaultMessage: 'Manage images',
  },
  createImageSuccessful: {
    id: 'create-image-successful',
    defaultMessage: 'Great, image has been created',
  },
  deleteImageSuccessful: {
    id: 'delete-image-successful',
    defaultMessage: 'Great, image has been deleted',
  },
  uploadNewImage: {
    id: 'upload-new-image',
    defaultMessage: 'Upload new image',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  copy: {
    id: 'copy',
    defaultMessage: 'Copy',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  image: {
    id: 'image',
    defaultMessage: 'Image',
  },
  actions: {
    id: 'actions',
    defaultMessage: 'Actions',
  },
  deleteImageDialogTitle: {
    id: 'delete-image-dialog-title',
    defaultMessage: 'Do you want to delete the image "{name}"?',
  },
  copyImageDialogTitle: {
    id: 'copy-image-dialog-title',
    defaultMessage: 'Do you want to copy the selected images?',
  },
  selectProjectToCopymageDialogTitle: {
    id: 'select-project-to-copy',
    defaultMessage: 'Select Project to copy:',
  },
  noResultsImages: {
    id: 'no-results-images',
    defaultMessage: 'There are no images',
  },
  identifierText: {
    id: 'identifier-text',
    defaultMessage: 'Id',
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}))

export const ManageImagesPage = () => {
  const classes = useStyles({})

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    content,
    accounts: { list_accounts },
    userSession: { basic_info_account, current_account_selected, token, permissions },
  } = useSelector((state: AppState) => state)
  /**
   * React Hooks
   */

  const [delete_dialog_data, setDeleteDialogData] = useState<DialogState>({
    open: false,
    itemName: '',
    itemTitle: '',
  })

  const [copy_dialog, setCopyDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'error',
    message: '',
    open: false,
  })

  const [selected, setSelected] = useState<string[]>([])
  const [projectToCopy, setProjectToCopy] = useState<string>('')

  useEffect(() => {
    return () => {
      dispatch(resetContent())
    }
  }, [dispatch])

  useEffect(() => {
    if (
      basic_info_account &&
      !content.data &&
      !content.get.loading &&
      !content.get.error &&
      !content.delete.loaded
    ) {
      dispatch(getContent(`${basic_info_account.url}/images?fullobjects=true`))
    }
  }, [basic_info_account, dispatch, content])

  useEffect(() => {
    if (content.create.loaded && !content.create.loading && !content.create.error) {
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.createImageSuccessful),
      })
    } else if (content.delete.loaded && !content.delete.loading && !content.delete.error) {
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.deleteImageSuccessful),
      })
    } else if (content.get.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(content.get.error),
      })
    }
  }, [dispatch, content, formatMessage])

  useEffect(() => {
    if (
      basic_info_account &&
      (content.delete.loaded || content.create.loaded) &&
      !content.delete.loading &&
      !content.create.loading &&
      !content.get.loading
    ) {
      dispatch(getContent(`${basic_info_account.url}/images?fullobjects=true`))
    }
  }, [dispatch, basic_info_account, content])

  /**
   * Component functions
   */

  const actionDeleteApplication = () => {
    if (basic_info_account) {
      dispatch(deleteContent(`${basic_info_account.url}/images/${delete_dialog_data.itemName}`))
    }
  }

  const handleClose = () => {
    setAlertInfo((prevState) => {
      return { ...prevState, open: false }
    })
  }

  const handleCloseModal = () => {
    setDeleteDialogData((prevState) => {
      return { ...prevState, open: false }
    })
  }

  const handleChangeFile = (e: React.SyntheticEvent) => {
    const { files } = e.target as HTMLInputElement
    if (files && files[0] && basic_info_account) {
      getBase64(files[0]).then((data: any) => {
        const objToSend = {
          '@type': 'File',
          id: getFormattedObjectId(files[0].name),
          file: {
            data: data.split(',')[1],
            encoding: 'base64',
            content_type: files[0].type,
            filename: files[0].name,
          },
        }
        dispatch(createContent(`${basic_info_account.url}/images/`, objToSend))
      })
    }
  }

  /**
   * Render
   */

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
    ]

    return (
      <CustomBreadcrumbs links={links} currentPageName={formatMessage(messages.manageImages)} />
    )
  }

  const renderItems = () => {
    if (!content.create.loading && content.data && content.data.items && token) {
      return content.data.items.map((item: any) => {
        return (
          <RowImage
            key={item['@name']}
            token={token}
            itemImage={item}
            setDeleteDialogData={setDeleteDialogData}
            permissions={permissions}
            checked={selected.includes(item['@name'])}
            onChecked={(checked) => {
              if (checked) {
                const newSelected = [...selected]
                newSelected.push(item['@name'])
                setSelected(newSelected)
              } else {
                const newSelected = [...selected]
                const index = newSelected.indexOf(item['@name'])
                if (index > -1) {
                  newSelected.splice(index, 1)
                  setSelected(newSelected)
                }
              }
            }}
          />
        )
      })
    }
  }

  const renderSpinner = () => {
    if (content.create.loading || content.delete.loading) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <ClipLoaderSpinner loading={content.create.loading || content.delete.loading} />
          </TableCell>
        </TableRow>
      )
    }
  }

  const renderCellNoResults = () => {
    if (
      content.get.loaded &&
      content.data &&
      content.data.items &&
      content.data.items.length === 0
    ) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <div>{formatMessage(messages.noResultsImages)}</div>
          </TableCell>
        </TableRow>
      )
    }
  }

  const handleChangeSelect = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target as HTMLInputElement
    setProjectToCopy(value)
  }

  const copyFile = (idImage) => {
    return new Promise((resolve, reject) => {
      const infoImage = content.data.items.find((item) => item['@name'] === idImage)
      if (infoImage) {
        fetch(`${infoImage['@id']}/@download/file`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (response.status !== 200) {
              reject(response)
            }

            response.blob().then((data) => {
              const file = new File([data], infoImage.file.filename, {
                type: infoImage.file.content_type,
              })
              getBase64(file).then((data: any) => {
                const objToSend = {
                  '@type': 'File',
                  id: getFormattedObjectId(file.name),
                  file: {
                    data: data.split(',')[1],
                    encoding: 'base64',
                    content_type: file.type,
                    filename: file.name,
                  },
                }
                dispatch(createContent(`db/${projectToCopy}/images/`, objToSend))

                resolve('ok')
              })
            })
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  const copyImages = () => {
    setLoading(true)
    Promise.all(
      selected.map((imgId) => {
        return copyFile(imgId)
      })
    )
      .then((values) => {
        setSelected([])
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  return (
    <Container maxWidth="lg">
      {renderBreadcrumbs()}
      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />

      <Typography variant="h4">{formatMessage(messages.manageImages)}</Typography>
      {loading && (
        <Box
          position={'fixed'}
          display="flex"
          justifyContent={'center'}
          alignItems="center"
          top={0}
          bottom={0}
          left={0}
          right={0}
          style={{ background: 'rgba(0,0,0,.7)', zIndex: 9999 }}
        >
          <Box>
            <CircularProgress size={200} />
          </Box>
        </Box>
      )}
      {userHasFeaturePermission(FEAT_IMAGE_CREATE, permissions) && (
        <Box mt={4} mb={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleChangeFile}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" component="span">
                  {formatMessage(messages.uploadNewImage)}
                  <CloudUploadIcon className={classes.rightIcon} />
                </Button>
              </label>
            </Grid>
          </Grid>
        </Box>
      )}

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ maxWidth: '40px', padding: '0 0 0 5px' }}>
                {selected.length > 0 && (
                  <Button
                    component="span"
                    onClick={() => {
                      setCopyDialog(true)
                    }}
                  >
                    <CollectionsIcon />
                  </Button>
                )}
              </TableCell>
              <TableCell>{formatMessage(messages.image)}</TableCell>
              <TableCell>{formatMessage(messages.identifierText)}</TableCell>
              <TableCell>{formatMessage(messages.name)}</TableCell>
              {userHasFeaturePermission(FEAT_IMAGE_DELETE, permissions) && (
                <TableCell align="right">{formatMessage(messages.actions)}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderSpinner()}
            {renderCellNoResults()}
            {renderItems()}
          </TableBody>
        </Table>
      </Paper>

      <DialogGenericAction
        data={delete_dialog_data}
        handleCloseModal={handleCloseModal}
        action={actionDeleteApplication}
        messages={{
          title: formatMessage(messages.deleteImageDialogTitle, {
            name: delete_dialog_data.itemTitle,
          }),
          cancel: formatMessage(messages.cancel),
          actionText: formatMessage(messages.delete),
        }}
      />
      <Dialog
        open={copy_dialog}
        onClose={() => {
          setCopyDialog(false)
          setProjectToCopy('')
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {formatMessage(messages.copyImageDialogTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {formatMessage(messages.selectProjectToCopymageDialogTitle)}
          </DialogContentText>
          <Select
            native
            value={projectToCopy}
            onChange={handleChangeSelect}
            style={{ minWidth: '200px' }}
          >
            <option aria-label="None" value="" />
            {list_accounts.map((item) => {
              if (current_account_selected === item.id) return <></>
              return (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              )
            })}
          </Select>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setCopyDialog(false)
              setProjectToCopy('')
            }}
            color="primary"
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button
            onClick={() => {
              setCopyDialog(false)
              setProjectToCopy('')
              copyImages()
            }}
            color="primary"
            autoFocus
          >
            {formatMessage(messages.copy)}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
