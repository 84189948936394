import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import { login, logout } from '../../actions'
import { ClipLoaderSpinner, SnackbarWrapper } from '../../components'
import { getErrorMessage } from '../../helpers'
import { AppState } from '../../reducers'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spinnerLoading: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const messages = defineMessages({
  loginBtn: {
    id: 'Login',
    defaultMessage: 'Login',
  },
  titleLoginPage: {
    id: 'title-login-page',
    defaultMessage: 'Sign In',
  },
  username: {
    id: 'username',
    defaultMessage: 'Username',
  },
  usernameError: {
    id: 'username-error',
    defaultMessage: 'Username is required',
  },
  password: {
    id: 'password',
    defaultMessage: 'Password',
  },
  passwordError: {
    id: 'password-error',
    defaultMessage: 'Password is required',
  },
  forgotPassword: {
    id: 'forgot-password',
    defaultMessage: 'Forgot password?',
  },
})

export const LoginPage = () => {
  const classes = useStyles({})

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const { userSession } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */
  const [form_state, setFormState] = useState({
    username: '',
    password: '',
    submitted: false,
  })
  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'error',
    message: '',
    open: false,
  })

  const { username, password, submitted } = form_state

  useEffect(() => {
    dispatch(logout())
  }, [dispatch])

  useEffect(() => {
    if (userSession.login.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(userSession.login.error),
      })
    }
  }, [userSession.login.error])

  /**
   * Component functions
   */

  const handleClose = () => {
    setAlertInfo((prevState) => {
      return { ...prevState, open: false }
    })
  }

  const handleChange = (e: React.SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement
    setFormState((prevState) => {
      return { ...prevState, [name]: value }
    })
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    setFormState((prevState) => {
      return { ...prevState, submitted: true }
    })

    if (username && password) {
      dispatch(login(username, password))
    }
  }

  /**
   * Render
   */

  const btnSubmit = () => {
    if (!userSession.login.loading) {
      return (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          id="btn-submit-login"
        >
          {formatMessage(messages.loginBtn)}
        </Button>
      )
    }
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {formatMessage(messages.titleLoginPage)}
        </Typography>

        <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            error={submitted && !username}
            helperText={submitted && !username ? formatMessage(messages.usernameError) : ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label={formatMessage(messages.username)}
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={handleChange}
          />
          <TextField
            error={submitted && !password}
            helperText={submitted && !password ? formatMessage(messages.passwordError) : ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={formatMessage(messages.password)}
            type="password"
            id="password"
            value={password}
            onChange={handleChange}
            autoComplete="current-password"
          />

          {btnSubmit()}

          <ClipLoaderSpinner loading={userSession.login.loading} />

          <Grid container>
            <Grid item xs>
              <Link to="/">{formatMessage(messages.forgotPassword)}</Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}
