import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import RestorePageIcon from '@material-ui/icons/RestorePage'
import {
  TableRow,
  TableCell,
  Tooltip,
  Paper,
  Grid,
  Table,
  TableHead,
  TableBody,
  Typography,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core'

import { isEmpty } from 'lodash'

import { messages } from './messages'
import { ReportType } from '../../types/guillotina'

import { AppState } from '../../reducers'
import { getVersions, getTranslations } from '../../actions'
import { userHasFeaturePermission } from '../../helpers'
import { ClipLoaderSpinner } from '../../components'
import routes, {
  NOT_FOUND_PAGE_ROUTE,
  PREVIEW_VERSION_REPORT_PAGE_ROUTE,
} from '../../constants/Routes'

import {
  DialogDeleteRestoreReport,
  DownloadTranslationFileData,
  UploadTranslationFileData,
  RESTORE_VERSION_REPORT_ACTION,
  DELETE_REPORT_ACTION,
  DELETE_VERSION_REPORT_ACTION,
} from './ReportsPage'

import {
  FEAT_REPORT_EDIT,
  FEAT_REPORT_PREVIEW,
  FEAT_REPORT_CLONE,
  FEAT_REPORT_DELETE,
  FEAT_REPORT_DOWNLOAD,
  FEAT_REPORT_RECOVER_PREVIOUS_VERSION,
  FEAT_REPORT_DELETE_PREVIOUS_VERSION,
  FEAT_REPORT_UPLOAD_TRANSLATIONS,
  FEAT_REPORT_DOWNLOAD_TRANSLATIONS,
} from '../../constants/Roles'
import { REPORT_DESIGN } from '../../constants/FilesTypes'

enum TabSection {
  REPORT_VERSIONS = 0,
  REPORT_TRANSLATIONS,
}

type Props = {
  report: ReportType
  reset_data: boolean
  getUrlToEditReport: (report: ReportType) => string
  getUrlPreviewReport: (report: ReportType) => string
  downloadReport: (report: ReportType) => void
  setCloneDialogData: (item: DialogState) => void
  setDialogData: (item: DialogDeleteRestoreReport) => void
  setAlertInfo: (item: AlertInfo) => void
  saveReportTranslationFile: (item: UploadTranslationFileData) => void
  downloadReportTranslationFile: (item: DownloadTranslationFileData) => void
}

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    fill: '#000',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    minHeight: 30,
  },
  noBottomBorder: {
    borderBottom: 0,
  },
  inputFile: {
    display: 'none',
  },
}))

type TabPanelProps = {
  children: any
  index: Number
  value: Number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

export const RowReport = (props: Props) => {
  const classes = useStyles({})

  const {
    report,
    reset_data,
    getUrlToEditReport,
    getUrlPreviewReport,
    downloadReport,
    setCloneDialogData,
    setDialogData,
    setAlertInfo,
    saveReportTranslationFile,
    downloadReportTranslationFile,
  } = props

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage, formatDate, formatTime } = intl

  /**
   * Redux Hooks
   */

  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account, permissions },
    content: { data, get },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [open_info, setOpenInfo] = useState<boolean>(false)
  const [active_tab_section, setActiveTabSection] = useState<TabSection>(TabSection.REPORT_VERSIONS)
  const [versions, setVersions] = useState<any>(null)
  const [languages, setLanguages] = useState<any>(null)
  const [is_get_version, setIsGetVersions] = useState<boolean>(false)
  const [is_get_languages, setIsGetLanguages] = useState<boolean>(false)

  useEffect(() => {
    if (
      open_info &&
      report &&
      basic_info_account &&
      active_tab_section === TabSection.REPORT_VERSIONS &&
      (!versions || reset_data)
    ) {
      setIsGetVersions(true)
      dispatch(getVersions(`${basic_info_account.url}/${REPORT_DESIGN}/${report['@name']}`))
    }
  }, [open_info, versions, report, basic_info_account, dispatch, reset_data, active_tab_section])

  useEffect(() => {
    if (
      open_info &&
      report &&
      basic_info_account &&
      active_tab_section === TabSection.REPORT_TRANSLATIONS &&
      (!languages || reset_data)
    ) {
      setIsGetLanguages(true)
      dispatch(getTranslations(`${basic_info_account.url}/${REPORT_DESIGN}/${report['@name']}`))
    }
  }, [open_info, languages, report, basic_info_account, dispatch, reset_data, active_tab_section])

  useEffect(() => {
    if (!get.loaded) {
      return
    }

    if (is_get_version) {
      setVersions(data)
      setIsGetVersions(false)
    } else if (is_get_languages) {
      setLanguages(data)
      setIsGetLanguages(false)
    }
  }, [is_get_version, is_get_languages, get.loaded, data])

  /**
   * Component functions
   */

  const getUrlPreviewVersionReport = (report: ReportType, version: string): string => {
    const routerObj = routes[PREVIEW_VERSION_REPORT_PAGE_ROUTE]
    if (routerObj.pathWithParam) {
      return routerObj.pathWithParam(report['@name'], version)
    }
    return routes[NOT_FOUND_PAGE_ROUTE].path
  }

  const onChangeTab = (event: React.ChangeEvent<{}>, newSection: number) => {
    setActiveTabSection(newSection)
  }

  /**
   * Render
   */

  const renderVersions = () => {
    if (get.loading && is_get_version) {
      return <ClipLoaderSpinner loading={get.loading} />
    } else if (versions && !isEmpty(versions)) {
      return (
        <Grid container spacing={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{formatMessage(messages.name)}</TableCell>
                <TableCell>{formatMessage(messages.date)}</TableCell>
                <TableCell>{formatMessage(messages.user)}</TableCell>
                <TableCell align="right">{formatMessage(messages.actions)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(versions)
                .reverse()
                .map((version: any) => {
                  return (
                    <TableRow key={version[0]}>
                      <TableCell>{version[1].name}</TableCell>
                      <TableCell>{`${formatDate(new Date(version[1].date))} ${formatTime(
                        new Date(version[1].date)
                      )}`}</TableCell>
                      <TableCell>{version[1].user}</TableCell>
                      <TableCell align="right">
                        {userHasFeaturePermission(
                          FEAT_REPORT_RECOVER_PREVIOUS_VERSION,
                          permissions
                        ) && (
                          <Tooltip title={formatMessage(messages.restoreVersion)}>
                            <RestorePageIcon
                              className={classes.icon}
                              onClick={() => {
                                setDialogData({
                                  open: true,
                                  itemName: report['@name'],
                                  itemTitle: report.title,
                                  title: formatMessage(messages.restoreVersionDialogTitle, {
                                    version: version[1].name,
                                  }),
                                  data: { layout: version[1].layout },
                                  action: RESTORE_VERSION_REPORT_ACTION,
                                  actionText: formatMessage(messages.restore),
                                })
                              }}
                            />
                          </Tooltip>
                        )}
                        {userHasFeaturePermission(
                          FEAT_REPORT_DELETE_PREVIOUS_VERSION,
                          permissions
                        ) && (
                          <Tooltip title={formatMessage(messages.deleteVersion)}>
                            <DeleteIcon
                              className={classes.icon}
                              onClick={() => {
                                setDialogData({
                                  open: true,
                                  itemName: report['@name'],
                                  itemTitle: report.title,
                                  title: formatMessage(messages.deleteVersionReportDialogTitle, {
                                    name: report['@name'],
                                    version: version[1].name,
                                  }),
                                  data: { version: version[0] },
                                  action: DELETE_VERSION_REPORT_ACTION,
                                  actionText: formatMessage(messages.delete),
                                })
                              }}
                            />
                          </Tooltip>
                        )}

                        <Tooltip title={formatMessage(messages.preview)}>
                          <Link to={getUrlPreviewVersionReport(report, version[0])}>
                            <VisibilityIcon className={classes.icon} />
                          </Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Grid>
      )
    } else {
      return (
        <Paper className={classes.paper}>
          <Typography align="center" variant="body1">
            {formatMessage(messages.noResultsVersions)}
          </Typography>
        </Paper>
      )
    }
  }

  const renderTranslations = () => {
    if (get.loading && is_get_languages) {
      return <ClipLoaderSpinner loading={get.loading} />
    } else if (languages && !isEmpty(languages)) {
      return (
        <Grid container spacing={4}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{formatMessage(messages.langName)}</TableCell>
                <TableCell>{formatMessage(messages.dateUpdated)}</TableCell>
                <TableCell>{formatMessage(messages.translateCompleted)}</TableCell>
                <TableCell align="right">{formatMessage(messages.actions)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(languages).map((language: any) => {
                return (
                  <TableRow key={language[0]}>
                    <TableCell>{language[0]}</TableCell>
                    <TableCell>
                      {language[1].date
                        ? `${formatDate(new Date(language[1].date))} ${formatTime(
                            new Date(language[1].date)
                          )}`
                        : '--'}
                    </TableCell>
                    <TableCell>{language[1].completed}%</TableCell>
                    <TableCell align="right">
                      <input
                        id={`input-upload-translations-file${language[0]}`}
                        type="file"
                        className={classes.inputFile}
                        onChange={(e: React.ChangeEvent) => {
                          const { files } = e.target as HTMLInputElement
                          const currentLanguage = language[0]
                          const reader = new FileReader()

                          reader.onload = () => {
                            const { result } = reader
                            saveReportTranslationFile({
                              language: currentLanguage,
                              reportName: report['@name'],
                              content: result as string,
                            })
                          }
                          if (files) {
                            if (
                              files[0].type === 'text/x-gettext-translation' ||
                              files[0].type === 'text/plain' ||
                              files[0].type === ''
                            ) {
                              reader.readAsText(files[0])
                            } else {
                              reader.abort()
                              setAlertInfo({
                                open: true,
                                type: 'error',
                                message: formatMessage(messages.typeTranslationFileIncorrect),
                              })
                            }
                          }
                        }}
                      />

                      {userHasFeaturePermission(FEAT_REPORT_UPLOAD_TRANSLATIONS, permissions) && (
                        <label
                          htmlFor={`input-upload-translations-file${language[0]}`}
                          id={`input-upload-translations-file${language[0]}`}
                        >
                          <Tooltip title={formatMessage(messages.uploadTranslationFile)}>
                            <CloudUploadIcon className={classes.icon} />
                          </Tooltip>
                        </label>
                      )}
                      {userHasFeaturePermission(FEAT_REPORT_DOWNLOAD_TRANSLATIONS, permissions) && (
                        <label
                          htmlFor="input-download-translations-file"
                          id="input-download-translations-file"
                        >
                          <Tooltip title={formatMessage(messages.downloadTranslationFile)}>
                            <CloudDownloadIcon
                              className={classes.icon}
                              onClick={() => {
                                downloadReportTranslationFile({
                                  language: language[0],
                                  reportName: report['@name'],
                                  filename: report['@name'] + '_translation_' + language[0] + '.po',
                                })
                              }}
                            />
                          </Tooltip>
                        </label>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Grid>
      )
    } else {
      return (
        <Paper className={classes.paper}>
          <Typography align="center" variant="body1">
            {formatMessage(messages.noResultsTranslations)}
          </Typography>
        </Paper>
      )
    }
  }

  return (
    <>
      <TableRow key={report['@name']}>
        <TableCell scope="row" className={open_info ? classes.noBottomBorder : ''}>
          {report.title}
        </TableCell>

        <TableCell scope="row" className={open_info ? classes.noBottomBorder : ''}>
          {`${formatDate(new Date(report.modification_date))} ${formatTime(
            new Date(report.modification_date)
          )}`}
        </TableCell>

        <TableCell scope="row" className={open_info ? classes.noBottomBorder : ''}>
          {report.last_modification_user
            ? report.last_modification_user
            : report['guillotina.behaviors.dublincore.IDublinCore'].creators[0]}
        </TableCell>

        <TableCell align="right" className={open_info ? classes.noBottomBorder : ''}>
          {userHasFeaturePermission(FEAT_REPORT_EDIT, permissions) && (
            <Tooltip title={formatMessage(messages.edit)}>
              <Link to={getUrlToEditReport(report)}>
                <EditIcon className={classes.icon} />
              </Link>
            </Tooltip>
          )}

          {userHasFeaturePermission(FEAT_REPORT_PREVIEW, permissions) && (
            <Tooltip title={formatMessage(messages.preview)}>
              <Link to={getUrlPreviewReport(report)}>
                <VisibilityIcon className={classes.icon} />
              </Link>
            </Tooltip>
          )}

          {userHasFeaturePermission(FEAT_REPORT_DELETE, permissions) && (
            <Tooltip title={formatMessage(messages.delete)}>
              <DeleteIcon
                className={classes.icon}
                onClick={() => {
                  setDialogData({
                    open: true,
                    itemName: report['@name'],
                    itemTitle: report.title,
                    title: formatMessage(messages.deleteReportDialogTitle, {
                      name: report['@name'],
                    }),
                    action: DELETE_REPORT_ACTION,
                    actionText: formatMessage(messages.delete),
                  })
                }}
              />
            </Tooltip>
          )}

          {userHasFeaturePermission(FEAT_REPORT_CLONE, permissions) && (
            <Tooltip title={formatMessage(messages.clone)}>
              <FileCopyIcon
                className={classes.icon}
                onClick={() => {
                  setCloneDialogData({
                    open: true,
                    itemName: report['@name'],
                    itemTitle: report.title,
                    item: report,
                  })
                }}
              />
            </Tooltip>
          )}

          {userHasFeaturePermission(FEAT_REPORT_DOWNLOAD, permissions) && (
            <Tooltip
              title={formatMessage(messages.download)}
              onClick={() => {
                downloadReport(report)
              }}
            >
              <ArrowDownwardIcon className={classes.icon} />
            </Tooltip>
          )}

          <Tooltip title={formatMessage(messages.viewVersions)}>
            {!open_info ? (
              <KeyboardArrowDownIcon
                className={classes.icon}
                onClick={() => {
                  setOpenInfo(true)
                }}
              />
            ) : (
              <KeyboardArrowUpIcon
                className={classes.icon}
                onClick={() => {
                  setOpenInfo(false)
                }}
              />
            )}
          </Tooltip>
        </TableCell>
      </TableRow>

      {open_info && (
        <TableRow>
          <TableCell colSpan={4}>
            <Paper square>
              <Tabs
                value={active_tab_section}
                indicatorColor="primary"
                textColor="primary"
                onChange={onChangeTab}
              >
                <Tab label={formatMessage(messages.titleVersions)} />
                <Tab label={formatMessage(messages.titleTranslations)} />
              </Tabs>
              <TabPanel value={active_tab_section} index={TabSection.REPORT_VERSIONS}>
                {renderVersions()}
              </TabPanel>
              <TabPanel value={active_tab_section} index={TabSection.REPORT_TRANSLATIONS}>
                {renderTranslations()}
              </TabPanel>
            </Paper>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
