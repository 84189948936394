import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { RouteComponentProps } from 'react-router'
import { useSelector } from 'react-redux'

import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Drawer,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CommentIcon from '@material-ui/icons/Comment'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import moment from 'moment'
import 'moment/min/locales'

import { messages } from './messages'
import { CustomBreadcrumbs, SnackbarWrapper, ClipLoaderSpinner } from '../../components'
import routes, { HOME_PAGE_ROUTE, REPORTS_PAGE_ROUTE } from '../../constants/Routes'

import { AppState } from '../../reducers'
//import { getErrorMessage } from '../../helpers'

import { ManageComments } from '../EditorPage/ManageComments'

import { Report } from 'reportRenderer'
import 'reportRenderer/lib/index.css'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  containerIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    cursor: 'pointer',
  },
  drawerPaper: {
    width: 300,
  },
}))

export const PreviewReportPage = (props: RouteComponentProps) => {
  const { match } = props
  const match_params: any = match.params
  const is_version_mode = 'version' in match.params

  const classes = useStyles({})

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Redux Hooks
   */
  const {
    userSession: { token },
    content: { data, get },
    currentAccount: { account },
    intl: { locale },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks *
   */
  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })
  const [selected_contract, setSelectedContract] = useState<string>('40965')
  const [selected_language, setSelectedLanguage] = useState<string>('ca')
  const [selected_date, setSelectedDate] = useState<any>(moment())
  const [is_comments_menu_open, setIsCommentsMenuOpen] = useState<boolean>(false)

  /*useEffect(() => {
    if (get.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(get.error),
      })
    }
  }, [dispatch, get])

  useEffect(() => {
    if (account && account.languages.length === 0) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: formatMessage(messages.notLanguagesDefined),
      })
    } else if (account) {
      setSelectedLanguage(account.default_language)
    }
  }, [account, formatMessage])*/

  /**
   * Component functions
   */

  const handleClose = () => {
    setAlertInfo((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const onDateChange = (selected_date: any) => {
    setSelectedDate(selected_date)
  }

  const handleCommentMenuToggle = () => {
    setIsCommentsMenuOpen(!is_comments_menu_open)
  }

  /**
   * Render
   */

  const renderSideBarComments = () => {
    return (
      <nav>
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={is_comments_menu_open}
          onClose={handleCommentMenuToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <ManageComments report={data} />
        </Drawer>
      </nav>
    )
  }

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
      {
        path: routes[REPORTS_PAGE_ROUTE].path,
        message: formatMessage(messages.reports),
      },
    ]

    return <CustomBreadcrumbs links={links} currentPageName={formatMessage(messages.preview)} />
  }

  const renderSpinner = () => {
    if (get.loading) {
      return <ClipLoaderSpinner loading={get.loading} size={150} />
    }
  }

  const renderCommentIcon = () => {
    if (!is_version_mode) {
      return (
        <CommentIcon
          className={classes.icon}
          fontSize="large"
          color="primary"
          onClick={handleCommentMenuToggle}
        />
      )
    }
  }

  const renderSelectedLanguage = () => {
    if (!is_version_mode) {
      return (
        <Grid item xs={2}>
          <FormControl className={classes.formControl}>
            <InputLabel>{formatMessage(messages.selectLanguage)}</InputLabel>
            <Select
              value={selected_language}
              onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                const { value } = e.target as HTMLInputElement
                setSelectedLanguage(value)
              }}
            >
              {account &&
                account.languages.map((language: string) => {
                  return (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Grid>
      )
    }
  }

  return (
    <>
      <Container maxWidth="lg">
        <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />

        {renderBreadcrumbs()}

        <Typography component="h1" variant="h4" align="center" className={classes.title}>
          {data && data.layout && account
            ? is_version_mode
              ? `${data.parentTitle} -- ${data.name}`
              : data.title
            : ''}
        </Typography>
      </Container>

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormControl className={classes.formControl}>
              <TextField
                label={formatMessage(messages.contractId)}
                value={selected_contract}
                onChange={(e) => setSelectedContract(e.target.value)}
              />
            </FormControl>
          </Grid>
          {renderSelectedLanguage()}
          <Grid item xs={3}>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                <DatePicker
                  value={selected_date}
                  onChange={onDateChange}
                  format={'DD/MM/YYYY'}
                  label={formatMessage(messages.selectDate)}
                  views={['year', 'month', 'date']}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={4} className={classes.containerIcons}>
            {renderCommentIcon()}
          </Grid>
        </Grid>
        {renderSpinner()}

        {renderSideBarComments()}
      </Container>

      {account && (
        <Report
          props={{
            match_params: match_params,
            selected_report: match_params.name,
            token: token,
            selected_account: account['@name'],
            selected_language: selected_language,
            selected_contract: selected_contract,
            selected_year: moment(selected_date).year(),
            selected_month: ('0' + (moment(selected_date).month() + 1)).slice(-2),
            selected_day: ('0' + moment(selected_date).date()).slice(-2),
            account: account,
          }}
        />
      )}
    </>
  )
}
