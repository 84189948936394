/**
 * Current accounts reducer.
 * @module reducers/accounts/accounts
 */
import { ReducerStatusAsync } from '../../types/reducer'
import { AnyAction } from 'redux'
import { GET_ACCOUNTS, LOGOUT, LOGIN } from '../../constants/ActionTypes'
import { SimpleAccount } from '../../types/guillotina'

export type AccountsReducer = {
  list_accounts: SimpleAccount[]
  get_accounts: ReducerStatusAsync
}

const initialState: AccountsReducer = {
  list_accounts: [],
  get_accounts: {
    error: null,
    loaded: false,
    loading: false,
  },
}

/**
 * Currenta account reducer.
 * @function actions
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function actions(
  state: AccountsReducer = initialState,
  action: AnyAction
): AccountsReducer {
  switch (action.type) {
    case `${GET_ACCOUNTS}_PENDING`:
      return {
        ...state,
        get_accounts: {
          loading: true,
          loaded: false,
          error: null,
        },
      }
    case `${GET_ACCOUNTS}_SUCCESS`:
      return {
        ...state,
        list_accounts: action.result,
        get_accounts: {
          loading: false,
          loaded: true,
          error: null,
        },
      }
    case `${GET_ACCOUNTS}_FAIL`:
      return {
        ...state,
        get_accounts: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      }
    case LOGOUT:
    case LOGIN:
      return initialState
    default:
      return state
  }
}
