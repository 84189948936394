export const createFile = (content: string, fileName: string, contentType: string) => {
  const a = document.createElement('a')
  const file = new Blob([content], { type: contentType })

  const url = window.URL.createObjectURL(file)
  a.href = url
  a.download = fileName
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  setTimeout(() => {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 100)
}
