import React, { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from '@material-ui/core'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { intersection, not } from '../../helpers'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 175,
      height: 400,
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: 150,
      },
      [theme.breakpoints.down('xs')]: {
        width: 85,
      },
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    itemIcon: {
      minWidth: 'unset',
    },
    listItem: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  })
)

type Props = {
  initData: any[]
  allData: any
  showSelectedItems?: boolean
  updateResult(...args: any[]): void
}

const TransferList = (props: Props) => {
  const classes = useStyles({})
  const { initData = [], allData, updateResult, showSelectedItems = true } = props

  /**
   * React Hooks
   */
  const [checked, setChecked] = React.useState<any[]>([])
  const [left, setLeft] = useState<any[]>([])
  const [right, setRight] = useState<any[]>(initData)

  useEffect(() => {
    if (initData) {
      if (Array.isArray(allData)) {
        setLeft(not(allData, initData))
      } else {
        setLeft(not(Object.keys(allData), initData))
      }

      setRight(initData)
    }
  }, [initData, allData])

  useEffect(() => {
    updateResult(right)
  }, [right, updateResult])

  /**
   * Component functions
   */

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
  }

  /**
   * Render
   */

  const customList = (items: any[]) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value: any) => {
          const labelId = `transfer-list-item-${value}-label`

          return (
            <ListItem
              key={value}
              className={classes.listItem}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon className={classes.itemIcon}>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Paper>
  )

  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      <Grid item>{customList(left)}</Grid>
      {showSelectedItems && (
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
      )}
      {showSelectedItems && <Grid item>{customList(right)}</Grid>}
    </Grid>
  )
}

export { TransferList }
