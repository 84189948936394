import { Grid } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'

export const RichText = ({
  val,
  setValue,
  disabled,
}: {
  val: string
  setValue: (val: string) => void
  disabled: boolean
}) => {
  return (
    <Grid>
      <input id="my-file" type="file" name="my-file" style={{ display: 'none' }} />
      <Editor
        tinymceScriptSrc={'/tinymce/tinymce.min.js'}
        disabled={disabled}
        init={{
          menubar: false,
          force_br_newlines: true,
          force_p_newlines: false,
          convert_newlines_to_brs: true,
          paste_as_text: true,
          contextmenu: false,
          height: '480',
          init_instance_callback: (editor) => {
            const selectednode = editor.selection.getNode()
            selectednode.setAttribute('data-test', 'inputFieldTestRich')
          },
          file_picker_callback: function (callback, value, meta) {
            if (meta.filetype == 'image') {
              const input: Record<string, any> = document.getElementById('my-file')
              input.click()
              input.onchange = function () {
                const file = input.files[0]
                const reader = new FileReader()
                reader.onload = function (e) {
                  callback(e.target.result as any, {
                    alt: file.name,
                  })
                }
                reader.readAsDataURL(file)
              }
            }
          },
          content_style: '.mce-content-body {font-size:18pt;}',
        }}
        id={`richTextField`}
        value={val}
        plugins="lists table link paste image"
        toolbar="bold italic underline strikethrough | numlist bullist | image | alignleft aligncenter alignright alignjustify | fontsize forecolor | link | lineheight | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol"
        onBeforePaste={(pl, o) => {
          //Enganxa sense estils
        }}
        onEditorChange={(newValue) => {
          console.log(newValue)
          setValue(newValue)
        }}
      />
    </Grid>
  )
}
