/**
 * Api middleware.
 * @module middleware/api
 */

import { history } from '../helpers'

/**
 * Api middleware.
 * @function
 * @param {Object} api Api object.
 * @returns {Promise} Action promise.
 */
export const ApiMiddleware = (api: any) => () => (next: any) => (action: any) => {
  const { request, type, ...rest } = action
  let actionPromise = null

  if (!request) {
    return next(action)
  }

  next({ ...rest, type: `${type}_PENDING` })

  actionPromise = Array.isArray(request)
    ? Promise.all(
        request.map((item) =>
          api[item.op](item.path, {
            data: item.data,
            type: item.type,
            headers: item.headers,
          })
        )
      )
    : api[request.op](request.path, {
        data: request.data,
        type: request.type,
        headers: request.headers,
      })

  actionPromise.then(
    (result: any) => {
      next({ ...rest, result, type: `${type}_SUCCESS` })
      if (action.request.redirectTo !== undefined) {
        history.push(action.request.redirectTo)
      }
    },
    (error: any) => {
      next({ ...rest, error, type: `${type}_FAIL` })
      if (error.status === 401) {
        history.push('/login')
      }
    }
  )

  return actionPromise
}
