import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { ClipLoaderSpinner, CustomBreadcrumbs, DialogGenericAction } from '../../components'
import { AppState } from '../../reducers'

import { push } from 'connected-react-router'
import { cloneContent, deleteContent, getAccountInfo, getApplications } from '../../actions'
import { catalogReindex } from '../../actions/content/content'
import { getApplication, getReportFolder } from '../../actions/currentAccount/currentAccount'
import { SnackbarWrapper } from '../../components'
import { DialogCopyApplication } from '../../components/DialogCopyApplication.tsx/DialogCopyApplication'
import { WEB_APPLICATION } from '../../constants/FilesTypes'
import {
  FEAT_APP_CREATE,
  FEAT_APP_DELETE,
  FEAT_APP_EDIT,
  FEAT_APP_PREVIEW,
} from '../../constants/Roles'
import routes, {
  ADD_APPLICATION_ROUTE,
  EDIT_APPLICATION_ROUTE,
  HOME_PAGE_ROUTE,
  NOT_FOUND_PAGE_ROUTE,
  PREVIEW_APPLICATION_PAGE_ROUTE,
} from '../../constants/Routes'
import { getErrorMessage, userHasFeaturePermission } from '../../helpers'
import { ApplicationType } from '../../types/guillotina'
import { messages } from './messages'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  btnCreateApplication: {
    marginTop: theme.spacing(4),
  },
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    fill: '#000',
  },
}))

export const ApplicationsPage = () => {
  const classes = useStyles({})
  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage, formatDate, formatTime } = intl

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account, permissions },
    currentAccount: { applications, get_applications },
    intl: { locale },
    content,
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })

  const [delete_dialog_data, setDeleteDialogData] = useState<DialogState>({
    open: false,
    itemName: '',
    itemTitle: '',
  })

  const [clone_dialog_data, setCloneDialogData] = useState<DialogState>({
    open: false,
    itemName: '',
    itemTitle: '',
    item: null,
  })

  useEffect(() => {
    if (basic_info_account !== null) {
      dispatch(getApplications(basic_info_account.url))
    }
  }, [basic_info_account, dispatch])

  useEffect(() => {
    if (basic_info_account && (content.delete.loaded || content.clone.loaded)) {
      dispatch(getApplications(basic_info_account.url))
    }
  }, [dispatch, basic_info_account, content.delete, content.clone])

  useEffect(() => {
    if (content.delete.loaded && !content.delete.loading && !content.delete.error) {
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.deleteApplicationSuccessful),
      })
    } else if (get_applications.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(get_applications.error),
      })
    } else if (content.delete.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(content.delete.error),
      })
    } else {
      setAlertInfo((prevState) => {
        return { ...prevState, open: false }
      })
    }
  }, [get_applications, content.delete, formatMessage])

  /**
   * Component functions
   */

  const actionDeleteApplication = () => {
    if (basic_info_account) {
      dispatch(
        deleteContent(`${basic_info_account.url}/${WEB_APPLICATION}/${delete_dialog_data.itemName}`)
      )
    }
  }

  const handleCloseSnackbar = () => {
    setAlertInfo((prevState) => {
      return { ...prevState, open: false }
    })
  }

  const handleCloseModal = () => {
    setDeleteDialogData((prevState) => {
      return { ...prevState, open: false }
    })
  }

  const getUrlPreviewApp = (application: ApplicationType): string => {
    const routerObj = routes[PREVIEW_APPLICATION_PAGE_ROUTE]
    if (routerObj.pathWithParam) {
      return routerObj.pathWithParam(application['@name'])
    }
    return routes[NOT_FOUND_PAGE_ROUTE].path
  }

  const getUrlToEditApp = (application: ApplicationType): string => {
    const routerObj = routes[EDIT_APPLICATION_ROUTE]
    if (routerObj.pathWithParam) {
      return routerObj.pathWithParam(application['@name'])
    }
    return routes[NOT_FOUND_PAGE_ROUTE].path
  }

  /**
   * Render
   */

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
    ]

    return (
      <CustomBreadcrumbs links={links} currentPageName={formatMessage(messages.applications)} />
    )
  }

  const renderSpinner = () => {
    if (get_applications.loading) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <ClipLoaderSpinner loading={get_applications.loading} />
          </TableCell>
        </TableRow>
      )
    }
  }

  const renderCellNoResults = () => {
    if (get_applications.loaded && applications.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <div>{formatMessage(messages.noResultsApplications)}</div>
          </TableCell>
        </TableRow>
      )
    }
  }

  const handleCloseCloneDialog = () => {
    setCloneDialogData((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const copyApplication = async (old_id: string, new_id: string, copy_to: string) => {
    try {
      const new_web_application = await dispatch(getAccountInfo(`db/${copy_to}/web_application`))
      const bodyPost = {
        destination: new_web_application['@uid'],
        new_id: new_id,
      }

      const new_report_design = await dispatch(getReportFolder(`db/${copy_to}/report_design`))
      const aplication = await dispatch(
        getApplication(`${basic_info_account.url}/web_application/${old_id}`)
      )
      if (aplication) {
        await Promise.all(
          (aplication as any).menus.map(async (report) => {
            const bodyPost = {
              destination: new_report_design['@uid'],
              new_id: report.reportId,
            }
            await dispatch(
              cloneContent(`${basic_info_account.url}/report_design/${report.reportId}`, bodyPost)
            )
          })
        ).catch(function (err) {
          //console.log(err)
        })
      }
      await dispatch(cloneContent(`${basic_info_account.url}/web_application/${old_id}`, bodyPost))
      await dispatch(catalogReindex(`db/${copy_to}`))
      setAlertInfo({
        open: true,
        type: 'success',
        message: `Duplicated ${old_id} to ${new_id} in ${copy_to} application`,
      })
    } catch (e) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: `Error duplicate ${old_id}, check if application already exist in ${copy_to}`,
      })
    }
  }

  return (
    <Container>
      {renderBreadcrumbs()}

      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleCloseSnackbar} />

      <Typography variant="h4">{formatMessage(messages.applicationsPageTitle)}</Typography>

      {userHasFeaturePermission(FEAT_APP_CREATE, permissions) && (
        <Button
          variant="contained"
          color="primary"
          className={classes.btnCreateApplication}
          onClick={() => {
            dispatch(push(`${routes[ADD_APPLICATION_ROUTE].path}?lang=${locale}`))
          }}
        >
          {formatMessage(messages.btnCreateApplication)}
        </Button>
      )}

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{formatMessage(messages.name)}</TableCell>
              <TableCell>{formatMessage(messages.lastModificationDate)}</TableCell>
              <TableCell align="right">{formatMessage(messages.actions)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderSpinner()}
            {renderCellNoResults()}
            {applications.map((application: ApplicationType) => (
              <TableRow key={application['@name']}>
                <TableCell scope="row">{application.title}</TableCell>
                <TableCell scope="row">
                  {`${formatDate(new Date(application.modification_date))} ${formatTime(
                    new Date(application.modification_date)
                  )}`}
                </TableCell>

                <TableCell align="right">
                  {userHasFeaturePermission(FEAT_APP_EDIT, permissions) && (
                    <Tooltip title={formatMessage(messages.edit)}>
                      <Link to={getUrlToEditApp(application)}>
                        <EditIcon className={classes.icon} />
                      </Link>
                    </Tooltip>
                  )}

                  {userHasFeaturePermission(FEAT_APP_PREVIEW, permissions) && (
                    <Tooltip title={formatMessage(messages.preview)}>
                      <Link to={getUrlPreviewApp(application)}>
                        <VisibilityIcon className={classes.icon} />
                      </Link>
                    </Tooltip>
                  )}

                  <Tooltip title={formatMessage(messages.copy)}>
                    <FileCopyIcon
                      className={classes.icon}
                      onClick={() => {
                        setCloneDialogData({
                          open: true,
                          itemName: application['@name'],
                          itemTitle: application.title,
                          item: application,
                        })
                      }}
                    />
                  </Tooltip>
                  {userHasFeaturePermission(FEAT_APP_DELETE, permissions) && (
                    <Tooltip title={formatMessage(messages.delete)}>
                      <DeleteIcon
                        className={classes.icon}
                        onClick={() => {
                          setDeleteDialogData({
                            open: true,
                            itemName: application['@name'],
                            itemTitle: application.title,
                          })
                        }}
                      />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <DialogGenericAction
        data={delete_dialog_data}
        handleCloseModal={handleCloseModal}
        action={actionDeleteApplication}
        messages={{
          title: formatMessage(messages.deleteApplicationDialogTitle, {
            name: delete_dialog_data.itemTitle,
          }),
          cancel: formatMessage(messages.cancel),
          actionText: formatMessage(messages.delete),
        }}
      />

      <DialogCopyApplication
        copyDialogData={clone_dialog_data}
        handleCloseCopyDialog={handleCloseCloneDialog}
        handleCopyCopyDialog={(new_id, copy_to) => {
          copyApplication(clone_dialog_data.itemName, new_id.replace(/_/g, ''), copy_to)
        }}
      />
    </Container>
  )
}
