import React from 'react'
import { Snackbar } from '@material-ui/core'
import { SnackbarContentWrapper } from '..'

interface Props {
  alertInfo: AlertInfo
  handleCloseSnackbar(...args: any[]): void
  hideDuration?: number
  hideOnClickInOverflow?: boolean
  verticalPosition?: VerticallPositionSnackbar
  horizontalPosition?: HorizontalPositionSnackbar
}

const SnackbarWrapper = (props: Props) => {
  const {
    alertInfo,
    handleCloseSnackbar,
    hideDuration = null,
    verticalPosition = 'bottom',
    horizontalPosition = 'left',
    hideOnClickInOverflow = false,
  } = props

  return (
    <Snackbar
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPosition,
      }}
      open={alertInfo.open}
      autoHideDuration={hideDuration}
      onClose={hideOnClickInOverflow ? handleCloseSnackbar : null}
    >
      <SnackbarContentWrapper
        variant={alertInfo.type}
        message={alertInfo.message}
        onClose={handleCloseSnackbar}
      />
    </Snackbar>
  )
}

export { SnackbarWrapper }
