import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import {
  Divider,
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'

import moment from 'moment'

import { AppState } from '../../reducers'
import { updateContent } from '../../actions'
import { getErrorMessage } from '../../helpers'
import { SnackbarWrapper, ClipLoaderSpinner } from '../../components'
import { ReportType } from '../../types/guillotina'
import { REPORT_DESIGN } from '../../constants/FilesTypes'

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  titleComments: {
    padding: '0px 15px',
    fontSize: '22px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnAddCommentContainer: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 268,
  },
  actionsSection: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noResults: {
    minHeight: 50,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderLeft: '5px solid #ccc',
  },
  cardRoot: {
    margin: theme.spacing(1),
  },
  cardHeaderRoot: {
    padding: theme.spacing(1),
  },
  cardContentRoot: {
    padding: theme.spacing(1),
  },
  wrapperCommentsList: {
    height: 'calc( 100vh - 140px )',
    overflow: 'scroll',
  },
}))

const messages = defineMessages({
  addComment: {
    id: 'add-comment',
    defaultMessage: 'Add comment',
  },
  comments: {
    id: 'comments',
    defaultMessage: 'Comments',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  genericTextRequired: {
    id: 'generic-text-required',
    defaultMessage: 'This field is required',
  },
  createCommentSuccessful: {
    id: 'create-comment-successful',
    defaultMessage: 'Great! comment has been created',
  },
  noResultsComments: {
    id: 'no-results-comments',
    defaultMessage: 'There are no comments created',
  },
})

type Props = {
  report: ReportType
}

export const ManageComments = (props: Props) => {
  const classes = useStyles({})
  const { report } = props

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage, formatDate, formatTime } = intl

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account, user_id },
    content: { update },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */
  const [is_add_mode, setIsAddMode] = useState(false)
  const [is_comment_created, setIsCommentCreated] = useState(false)
  const [form_state, setFormState] = useState<any>({
    text_new_comment: '',
    submitted: false,
  })
  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'error',
    message: '',
    open: false,
  })

  useEffect(() => {
    if (update.loaded && !update.loading && !update.error && is_comment_created) {
      setIsCommentCreated(false)
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.createCommentSuccessful),
      })
    } else if (update.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(update.error),
      })
    }
  }, [dispatch, update, formatMessage, is_comment_created])

  /**
   * Component functions
   */

  const { text_new_comment, submitted } = form_state

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    setFormState((prevState: any) => {
      return { ...prevState, submitted: true }
    })
    if (basic_info_account && text_new_comment) {
      setFormState({ text_new_comment: '', submitted: true })
      setIsAddMode(false)
      setIsCommentCreated(true)
      const current_comments = report.comments
      current_comments.unshift({
        user: user_id,
        text: text_new_comment,
        date: moment(),
      })
      const objToSend = {
        comments: current_comments,
      }
      dispatch(
        updateContent(`${basic_info_account.url}/${REPORT_DESIGN}/${report['@name']}`, objToSend)
      )
    }
  }

  const handleClose = () => {
    setAlertInfo((prevState) => {
      return { ...prevState, open: false }
    })
  }

  /**
   * Render
   */

  const renderAddSection = () => {
    if (is_add_mode) {
      return (
        <div>
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              error={submitted && !text_new_comment}
              helperText={
                submitted && !text_new_comment ? formatMessage(messages.genericTextRequired) : ''
              }
              label={formatMessage(messages.addComment)}
              multiline
              name="text_new_comment"
              id="text_new_comment"
              value={text_new_comment}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = ev.target as HTMLInputElement
                setFormState((prevState: any) => {
                  return { ...prevState, [name]: value }
                })
              }}
              rowsMax="4"
              className={classes.textField}
              margin="normal"
            />
            <div className={classes.actionsSection}>
              <Button variant="contained" color="primary" type="submit">
                {formatMessage(messages.save)}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setIsAddMode(false)
                  setFormState({
                    text_new_comment: '',
                    submitted: false,
                  })
                }}
              >
                {formatMessage(messages.cancel)}
              </Button>
            </div>
          </form>
        </div>
      )
    } else {
      return (
        <div className={classes.btnAddCommentContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsAddMode(true)
            }}
          >
            {formatMessage(messages.addComment)}
          </Button>
        </div>
      )
    }
  }

  const renderListSection = () => {
    if (report) {
      if (report.comments && report.comments.length > 0) {
        return (
          <div className={classes.wrapperCommentsList}>
            {report.comments.map((comment: any) => {
              return (
                <Card
                  key={comment.date}
                  classes={{
                    root: classes.cardRoot,
                  }}
                >
                  <CardHeader
                    avatar={<AccountCircle />}
                    title={comment.user}
                    subheader={`${formatDate(new Date(comment.date))} ${formatTime(
                      new Date(comment.date)
                    )}`}
                    classes={{
                      root: classes.cardHeaderRoot,
                    }}
                  />
                  <CardContent classes={{ root: classes.cardContentRoot }}>
                    <Typography variant="body1" color="textSecondary" component="p">
                      {comment.text}
                    </Typography>
                  </CardContent>
                </Card>
              )
            })}
          </div>
        )
      } else {
        return (
          <div className={classes.wrapperCommentsList}>
            <div className={classes.noResults}>{formatMessage(messages.noResultsComments)}</div>
          </div>
        )
      }
    }
  }

  return (
    <div>
      <SnackbarWrapper
        alertInfo={alert_info}
        handleCloseSnackbar={handleClose}
        hideDuration={3000}
        hideOnClickInOverflow={true}
      />
      <Typography className={`${classes.toolbar} ${classes.titleComments}`}>
        {formatMessage(messages.comments)}
      </Typography>
      {update.loading ? (
        <ClipLoaderSpinner loading={update.loading} />
      ) : (
        <>
          <Divider />
          {renderAddSection()}
          <Divider />
          {renderListSection()}
        </>
      )}
    </div>
  )
}
