import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { updateIntl } from 'react-intl-redux'
import { ConnectedRouter, push } from 'connected-react-router'
import { history, getErrorMessage, userHasRoutePermission } from './helpers'
import { Menu, PrivateRoute, SnackbarWrapper } from './components'
import { AppState } from './reducers'
import { locales } from './constants/locale'
import routes, { HOME_PAGE_ROUTE } from './constants/Routes'
import { getAccountInfo, getAccounts, updateContent } from './actions'

const queryString = require('query-string')

const App = () => {
  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account, current_account_selected, is_superuser, permissions },
    accounts: { list_accounts, get_accounts },
    currentAccount: { get_account, account },
    content: { update },
    intl: { locale },
    router: { location },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    type: 'error',
    message: '',
    open: false,
  })

  useEffect(() => {
    if (
      is_superuser &&
      !get_accounts.loading &&
      !get_accounts.loaded &&
      !get_accounts.error &&
      list_accounts.length === 0
    ) {
      dispatch(getAccounts('db'))
    }
  }, [is_superuser, dispatch, list_accounts, get_accounts])

  useEffect(() => {
    const parsed = queryString.parse(location.search)
    if (parsed['lang'] && locale !== parsed['lang']) {
      let newLocale = parsed['lang']
      if (!(parsed['lang'] in locales)) {
        newLocale = locale
      }
      dispatch(
        updateIntl({
          locale: newLocale,
          messages: locales[newLocale],
        })
      )
    }
  }, [dispatch, locale, location.search])

  useEffect(() => {
    if (get_account.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(get_account.error),
      })
    } else if (get_accounts.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(get_accounts.error),
      })
    } else if (update.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(update.error),
      })
    } else if (
      update.loaded &&
      !update.loading &&
      account &&
      basic_info_account &&
      !account.__behaviors__.includes('guillotina_serviceaccount.interfaces.IServiceAccount')
    ) {
      /**
       * Si acabem d'actualitzar l'account afegint el behavior, i encara no està l'account del
       * reducer actualitat, el tornem a obtenir
       */
      dispatch(getAccountInfo(basic_info_account.url))
    } else if (
      !update.loading &&
      get_account.loaded &&
      !get_account.loading &&
      account &&
      basic_info_account &&
      !account.__behaviors__.includes('guillotina_serviceaccount.interfaces.IServiceAccount')
    ) {
      /**
       * Si l'account no te el behaviour definit, l'afegim per poder obtenir service tokens
       */
      dispatch(
        updateContent(basic_info_account.url, {
          '@behaviors': ['guillotina_serviceaccount.interfaces.IServiceAccount'],
        })
      )
    } else if (!get_account.loading && !get_account.loaded && basic_info_account) {
      if (current_account_selected) {
        dispatch(getAccountInfo(basic_info_account.url))
      } else if (location.pathname !== routes[HOME_PAGE_ROUTE].path) {
        dispatch(push(`${routes[HOME_PAGE_ROUTE].path}?lang=${locale}`))
      }
    } else {
      setAlertInfo((prevState) => {
        return { ...prevState, open: false }
      })
    }
  }, [
    dispatch,
    get_account,
    location,
    current_account_selected,
    basic_info_account,
    get_accounts,
    update,
    account,
    locale,
  ])

  /**
   * Component functions
   */

  const handleClose = () => {
    setAlertInfo((prevState) => {
      return { ...prevState, open: false }
    })
  }

  /**
   * Render
   */

  return (
    <>
      <ConnectedRouter history={history}>
        <Menu>
          <SnackbarWrapper alertInfo={alertInfo} handleCloseSnackbar={handleClose} />
          <Switch>
            {Object.entries(routes).map(([route_id, route]) => {
              if (
                route_id === HOME_PAGE_ROUTE ||
                !route.isPrivate ||
                userHasRoutePermission(route_id, permissions)
              ) {
                if (route.isPrivate) {
                  return <PrivateRoute key={route_id} exact {...route} />
                } else {
                  return <Route key={route_id} exact {...route} />
                }
              } else {
                // The following line is intended to return a non-component response. I guess returning a null value is a valid way to do it in React.
                return null
              }
            })}
          </Switch>
        </Menu>
      </ConnectedRouter>
    </>
  )
}

export { App }
