import { DetailAccount } from '../../types/guillotina'
import { v4 } from 'uuid'

export const InitMenuItemJsonSchema = (
  account: DetailAccount | null,
  level: number,
  parentId: string | null = null
) => {
  return {
    id: v4(),
    parentId: parentId,
    name: '',
    reportId: '',
    renderHeader: true,
    renderSidebar: true,
    isReportLinkMode: true,
    externalLink: '',
    nameTranslations: account
      ? account.languages.map((lang: string) => {
          return { lang: lang, name: '' }
        })
      : [],
    level: level,
    children: [],
  }
}
