export const ALL_CONTENT_PLUGINS: IndexSignatureType = {
  Text: 'slate',
  Spacer: 'spacer',
  Divider: 'divider',
  'HTML 5 video': 'html5video',
  Video: 'video',
  'Savings comparative indicator': 'indicatorSavingsComparative',
  'Efficiency level indicator': 'efficiencyLevelIndicator',
  'Image with caption': 'imageWithCaption',
  'Common capabilities list': 'commonCapabilities',
  'Consume by hours': 'd3consumeByHoursChart',
  'Anual bar chart': 'd3AnualBarChart',
  'Contracted power': 'contractedPower',
  'Custom button': 'customButton',
  'Text box': 'textBox',
  'Page break': 'pageBreak',
}

export const ALL_LAYOUT_PLUGINS = {
  Background: 'background',
  'Layout Som Energia': 'layoutSomEnergia',
}
