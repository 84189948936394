import MomentUtils from '@date-io/moment'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import 'moment/min/locales'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
//import { getErrorMessage, getFileCss } from '../../helpers'
import { AppState } from '../../reducers'
import { messages } from './messages'
import {
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
} from '@material-ui/core'
import { Application } from 'applicationRenderer'
import 'applicationRenderer/lib/index.css'

import routes, { HOME_PAGE_ROUTE, APPLICATIONS_PAGE_ROUTE } from '../../constants/Routes'
import { CustomBreadcrumbs, SnackbarWrapper, ClipLoaderSpinner } from '../../components'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  formControlSelectContracts: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  select: {
    zIndex: 5,
  },
}))

export const PreviewApplicationPage = (props: RouteComponentProps) => {
  const { match } = props
  const matchParams: any = match.params

  const classes = useStyles({})

  /**
   * Intl Hook
   */

  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Redux Hooks
   */
  const {
    userSession: { token },
    content: { get },
    currentAccount: { account },
    intl: { locale },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })

  const [selected_contract, setSelectedContract] = useState<string>('40965')
  const [selected_language, setSelectedLanguage] = useState<string>('')
  const [selected_date, setSelectedDate] = useState<any>(moment())

  /*useEffect(() => {
    if (get.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(get.error),
      })
    }
  }, [dispatch, get])*/

  /**
   * Selected effects
   */

  useEffect(() => {
    if (account && account.languages.length === 0) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: formatMessage(messages.notLanguagesDefined),
      })
    } else if (account) {
      setSelectedLanguage(account.default_language)
    }
  }, [account, formatMessage])

  /**
   * Component functions
   */

  const handleClose = () => {
    setAlertInfo((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const onDateChange = (selected_date: any) => {
    setSelectedDate(selected_date)
  }

  /**
   * Render
   */
  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
      {
        path: routes[APPLICATIONS_PAGE_ROUTE].path,
        message: formatMessage(messages.applications),
      },
    ]

    return <CustomBreadcrumbs links={links} currentPageName={formatMessage(messages.preview)} />
  }

  const renderSpinner = () => {
    if (get.loading) {
      return (
        <Box mt={15}>
          <ClipLoaderSpinner loading={get.loading} size={150} />
        </Box>
      )
    }
  }

  const renderFilters = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormControl className={classes.formControl}>
            <TextField
              label={formatMessage(messages.contractId)}
              value={selected_contract}
              onChange={(e) => setSelectedContract(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formControl}>
            <InputLabel>{formatMessage(messages.selectLanguage)}</InputLabel>
            <Select
              value={selected_language}
              onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                const { value } = e.target as HTMLInputElement
                setSelectedLanguage(value)
              }}
            >
              {account &&
                account.languages.map((language: string) => {
                  return (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
              <DatePicker
                value={selected_date}
                onChange={onDateChange}
                format={'DD/MM/YYYY'}
                label={formatMessage(messages.selectDate)}
                views={['year', 'month', 'date']}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  return (
    <Container maxWidth="lg">
      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />

      {renderBreadcrumbs()}
      {renderFilters()}
      {renderSpinner()}
      {account && (
        <Application
          props={{
            selected_application: matchParams.name,
            token: token,
            account: account,
            selected_account: account['@name'],
            selected_language: selected_language,
            selected_contract: selected_contract,
            selected_year: moment(selected_date).year(),
            selected_month: ('0' + (moment(selected_date).month() + 1)).slice(-2),
            selected_day: ('0' + moment(selected_date).date()).slice(-2),
            preview: true,
          }}
        />
      )}
    </Container>
  )
}
