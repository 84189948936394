import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import routes, { HOME_PAGE_ROUTE } from '../../constants/Routes'
import { selectAccount, resetContent } from '../../actions'
import { AppState } from '../../reducers'

export const ChangeAccountRedirectPage = () => {
  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    intl: { locale },
  } = useSelector((state: AppState) => state)

  /**
   * React router Hook
   */
  const params: any = useParams()

  useEffect(() => {
    if ('account_id' in params) {
      dispatch(selectAccount(params.account_id))
      dispatch(resetContent())
    }
    dispatch(push(`${routes[HOME_PAGE_ROUTE].path}?lang=${locale}`))
  }, [params, dispatch, locale])

  return <div />
}
