import React, { useEffect, useState } from 'react'
import { ApplicationMenuItem } from './ApplicationMenuItem'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../reducers'
import { getReports } from '../../actions'
import { SnackbarWrapper } from '../../components'
import { getErrorMessage } from '../../helpers'

type Props = {
  data: MenuItemJsonSchema[]
  addChildrenMenuItem: (id: any) => void
  updateMenuItemInfo: (id: any, data: MenuItemInfo) => void
  deleteMenuItem: (id: any, parentId: any) => void
}

const ApplicationManageMenuItem = (props: Props) => {
  const { data, addChildrenMenuItem, updateMenuItemInfo, deleteMenuItem } = props

  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account },
    currentAccount: { reports, get_reports },
  } = useSelector((state: AppState) => state)

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    type: 'error',
    message: '',
    open: false,
  })

  useEffect(() => {
    if (basic_info_account !== null) {
      dispatch(getReports(basic_info_account.url))
    }
  }, [basic_info_account, dispatch])

  useEffect(() => {
    if (get_reports.error) {
      setAlertInfo({ open: true, type: 'error', message: getErrorMessage(get_reports.error) })
    }
  }, [get_reports])

  return (
    <>
      <SnackbarWrapper
        alertInfo={alertInfo}
        handleCloseSnackbar={() => setAlertInfo((prev) => ({ ...prev, open: false }))}
      />

      {data.map((menuItem: MenuItemJsonSchema) => (
        <ApplicationMenuItem
          key={menuItem.id}
          data={menuItem}
          reports={reports}
          isParent
          addChildrenMenuItem={addChildrenMenuItem}
          updateMenuItemInfo={updateMenuItemInfo}
          deleteMenuItem={deleteMenuItem}
        />
      ))}
    </>
  )
}

export { ApplicationManageMenuItem }
