import Raven from 'raven-js'
import { Middleware } from 'redux'

export const crashReporter: Middleware = (store) => (next) => (action) => {
  try {
    return next(action)
  } catch (error) {
    Raven.captureException(error, {
      extra: {
        action,
        state: store.getState(),
      },
    })
    throw error
  }
}
