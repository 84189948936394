import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  Container,
  Typography,
  Box,
  Paper,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Chip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { messages } from './messages'

import routes, { GENERATE_REPORTS_PAGE_ROUTE, HOME_PAGE_ROUTE } from '../../constants/Routes'
import {
  CustomBreadcrumbs,
  SnackbarWrapper,
  ClipLoaderSpinner,
  DialogGenericAction,
} from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../reducers'
import { resetContent, getContent } from '../../actions'
import { getErrorMessage } from '../../helpers'
import { RouteComponentProps } from 'react-router'
import { PdfGenerationType, HtmlGenerationType } from '../../types/guillotina'
import { config } from '../../config'
import { SET_PDF } from '../../constants/FilesTypes'
import { GenerateReportsDetailsPageDetailGeneration } from './GenerateReportsDetailsPageDetailGeneration'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    fill: '#000',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  chip: {
    marginLeft: theme.spacing(3),
  },
}))

export const GenerateReportsDetailsPage = (props: RouteComponentProps) => {
  const classes = useStyles({})
  const { match } = props

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage, formatDate, formatTime } = intl

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account, token, permissions },
    currentAccount: { account },
    content: { data, get },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })
  const [delete_dialog_data, setDeleteDialogData] = useState<DialogState & { type: string }>({
    open: false,
    itemName: '',
    itemTitle: '',
    type: '',
  })
  const [item_to_reset, setItemToReset] = React.useState<string | null>(null)

  useEffect(() => {
    return () => {
      dispatch(resetContent())
    }
  }, [dispatch])

  useEffect(() => {
    if (basic_info_account && data === null && !get.loading && !get.error && !get.loaded) {
      const match_params: any = match.params
      dispatch(getContent(`${basic_info_account.url}/${SET_PDF}/${match_params.name}`))
    }
  }, [basic_info_account, data, dispatch, match.params, get])

  useEffect(() => {
    if (get.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(get.error),
      })
    }
  }, [dispatch, get, formatMessage])

  /**
   * Component functions
   */

  const handleCloseModal = () => {
    setDeleteDialogData((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const deleteAllPdf = (type: string) => {
    if (basic_info_account) {
      const match_params: any = match.params
      fetch(
        `${config.apiUrl}/${basic_info_account.url}/${SET_PDF}/${match_params.name}/${
          delete_dialog_data.itemName
        }/@${type === 'PdfGeneration' ? 'deletePdfs' : 'deleteHtmls'}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(`Status: ${response.status} - Message: ${response.statusText}`)
          }
          setItemToReset(delete_dialog_data.itemName)
          setAlertInfo({
            open: true,
            type: 'success',
            message: formatMessage(
              type === 'PdfGeneration'
                ? messages.deletePdfSuccessful
                : messages.deleteHtmlSuccessful
            ),
          })
        })
        .catch((error: any) => {
          setAlertInfo({
            open: true,
            type: 'error',
            message: error.toString(),
          })
        })
    }
  }

  const handleClose = () => {
    setAlertInfo((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const getReverseItems = () => {
    if (data.items) return data.items.slice().reverse()
    return []
  }

  const getItemDateFromName = (generation_item: PdfGenerationType | HtmlGenerationType): Date => {
    return new Date(
      parseInt(generation_item['@name'].substr(0, 4), 0),
      parseInt(generation_item['@name'].substr(4, 2), 0),
      parseInt(generation_item['@name'].substr(6, 2), 0),
      parseInt(generation_item['@name'].substr(8, 2), 0),
      parseInt(generation_item['@name'].substr(10, 2), 0),
      parseInt(generation_item['@name'].substr(12, 2), 0)
    )
  }

  /**
   * Render
   */

  const renderGenerateIcon = (generation_item: PdfGenerationType | HtmlGenerationType) => {
    if (generation_item.generating) {
      return (
        <>
          <Chip
            variant="outlined"
            size="small"
            className={classes.chip}
            label={`${generation_item.length} of ${data.contract_ids.length}`}
          />
          <Chip
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.chip}
            label={formatMessage(messages.generating)}
          />
        </>
      )
    } else if (!generation_item.generating) {
      return (
        <Chip
          variant="outlined"
          size="small"
          className={classes.chip}
          label={formatMessage(messages.generated)}
        />
      )
    }
  }

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
      {
        path: routes[GENERATE_REPORTS_PAGE_ROUTE].path,
        message: formatMessage(messages.generateReports),
      },
    ]

    return (
      <CustomBreadcrumbs links={links} currentPageName={formatMessage(messages.allGenerations)} />
    )
  }

  return (
    <Container>
      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />
      {renderBreadcrumbs()}

      {data ? (
        <>
          <Typography variant="h4" align="center">
            <Box fontWeight="fontWeightBold" mb={3}>
              {data.title} - {formatMessage(messages.allGenerations)}
            </Box>
          </Typography>

          <Paper className={classes.root}>
            {getReverseItems().map((generation_item: PdfGenerationType | HtmlGenerationType) => {
              return (
                <ExpansionPanel key={generation_item['@name']}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                      {`${formatDate(getItemDateFromName(generation_item))} 
                        ${formatTime(getItemDateFromName(generation_item))}`}
                    </Typography>
                    {renderGenerateIcon(generation_item)}
                    <Chip
                      variant="outlined"
                      size="small"
                      className={classes.chip}
                      label={generation_item['@type'] === 'PdfGeneration' ? 'PDF' : 'HTML'}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <GenerateReportsDetailsPageDetailGeneration
                      account={account}
                      basic_info_account={basic_info_account}
                      generation_item={generation_item}
                      match_params={match.params}
                      permissions={permissions}
                      token={token}
                      setDeleteDialogData={setDeleteDialogData}
                      setAlertInfo={setAlertInfo}
                      reset_values={item_to_reset === generation_item['@name']}
                    />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )
            })}
          </Paper>
        </>
      ) : (
        <ClipLoaderSpinner loading={get.loading} size={150} />
      )}

      <DialogGenericAction
        data={delete_dialog_data}
        handleCloseModal={handleCloseModal}
        action={() => deleteAllPdf(delete_dialog_data.type)}
        messages={{
          title: formatMessage(
            delete_dialog_data.type === 'PdfGeneration'
              ? messages.deletePdfReportDialogTitle
              : messages.deleteHtmlReportDialogTitle
          ),
          cancel: formatMessage(messages.cancel),
          actionText: formatMessage(messages.delete),
        }}
      />
    </Container>
  )
}
