import React, { useState, useEffect } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  FormControl,
} from '@material-ui/core'

import { DialogTranslateType } from '../../pages/ApplicationsPage/ApplicationMenuItem'
import { AppState } from '../../reducers'

const useStyles = makeStyles((theme) => ({
  gridMargin: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  textField: {
    width: '100%',
    margin: '0',
  },
}))

const messages = defineMessages({
  dialogTitle: {
    id: 'translations-dialog-title',
    defaultMessage: 'Set translations to "{name}" ?',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
})

type Props = {
  dialogData: DialogTranslateType
  handleCloseDialog: () => void
  action: (data: any) => void
}

export const DialogSetTranslationsApplicationItemMenu = (props: Props) => {
  const classes = useStyles({})
  const { dialogData, handleCloseDialog, action } = props

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  const {
    currentAccount: { account },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [form_data, setFormData] = useState<any[]>(dialogData.data)

  useEffect(() => {
    if (account && dialogData.data) {
      // Només agafem els idiomes seleccionats a l'account
      const newTranslations: any[] = account.languages.map((lang: string) => {
        const itemLang = dialogData.data.find((item: any) => item.lang === lang)
        if (!itemLang) {
          return {
            lang: lang,
            name: '',
          }
        } else {
          return itemLang
        }
      })
      setFormData(newTranslations)
    }
  }, [dialogData.data, account])

  /**
   * Component functions
   */

  const handleClickClone = () => {
    action(form_data)
    handleCloseDialog()
  }

  const handleChangeTranslations = (lang: string, value: string) => {
    const newTranslations: any[] = Object.assign([], form_data)
    newTranslations.some((item: any) => {
      if (item.lang === lang) {
        item.name = value
        return true
      }
      return false
    })

    setFormData(newTranslations)
  }
  /**
   * Render
   */

  return (
    <Dialog open={dialogData.open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {formatMessage(messages.dialogTitle, { name: dialogData.itemToTranslate })}
      </DialogTitle>
      <DialogContent>
        {form_data &&
          form_data.map((obj: any) => {
            return (
              <Grid container spacing={3} className={classes.gridMargin} key={obj.lang}>
                <Grid item xs={12} sm={4}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      disabled
                      id="outlined-disabled"
                      defaultValue={obj.lang}
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      margin="dense"
                      label={formatMessage(messages.name)}
                      type="text"
                      fullWidth
                      value={obj.name}
                      onChange={(e: React.ChangeEvent) => {
                        const { value } = e.target as HTMLInputElement
                        handleChangeTranslations(obj.lang, value)
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )
          })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          {formatMessage(messages.cancel)}
        </Button>
        <Button onClick={handleClickClone} color="primary">
          {formatMessage(messages.save)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
