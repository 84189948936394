/**
 * Action types.
 * @module constants/ActionTypes
 */

export const CLONE_CONTENT = 'CLONE_CONTENT'
export const CREATE_CONTENT = 'CREATE_CONTENT'
export const DELETE_CONTENT = 'DELETE_CONTENT'
export const GET_ACCOUNT = 'GET_ACCOUNT'
export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const GET_APPLICATIONS = 'GET_APPLICATIONS'
export const GET_CONTENT = 'GET_CONTENT'
export const GET_VERSIONS = 'GET_VERSIONS'
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS'
export const GET_CONTRACTS = 'GET_CONTRACTS'
export const LOAD_MORE_CONTRACTS = 'LOAD_MORE_CONTRACTS'
export const GET_GENERATE_REPORTS = 'GET_GENERATE_REPORTS'
export const LOAD_MORE_GENERATE_REPORTS = 'LOAD_MORE_GENERATE_REPORTS'
export const GET_REPORTS = 'GET_REPORTS'
export const LOAD_MORE_REPORTS = 'LOAD_MORE_REPORTS'
export const GET_SERVICE_TOKENS = 'GET_SERVICE_TOKENS'
export const MAKE_PDFS = 'MAKE_PDFS'
export const MAKE_HTMLS = 'MAKE_HTMLS'
export const LOGIN = 'LOGIN'
export const LOGIN_RENEW = 'LOGIN_RENEW'
export const LOGOUT = 'LOGOUT'
export const RESET_CONTENT = 'RESET_CONTENT'
export const SEARCH = 'SEARCH'
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT'
export const UPDATE_CONTENT = 'UPDATE_CONTENT'
