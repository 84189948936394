import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { AppState } from '../../reducers'
import { makeStyles } from '@material-ui/core/styles'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { TableRow, TableCell, Tooltip, Paper, Grid, Box, Chip } from '@material-ui/core'

import { messages } from './messages'
import { SetPdfType, PdfGenerationType } from '../../types/guillotina'
import { Typography } from '@material-ui/core'
import {
  FEAT_PDF_REPORT_GENERATE,
  FEAT_PDF_REPORT_LOG_VIEW,
  FEAT_PDF_REPORT_EDIT,
  FEAT_PDF_REPORT_CLONE,
  FEAT_PDF_REPORT_DELETE,
  FEAT_PDF_REPORT_DOWNLOAD_PDF,
} from '../../constants/Roles'
import { userHasFeaturePermission } from '../../helpers'

type Props = {
  configReport: SetPdfType
  generations_list: PdfGenerationType[]
  getUrlToEditReport: (config_report: SetPdfType) => string
  getUrlViewAllGenerations: (config_report: SetPdfType) => string
  setCloneDialogData: (item: DialogState) => void
  setDeleteDialogData: (item: DialogState) => void
  makePdfs: (config_name: string, buildPdf: boolean, buildHtml: boolean) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'inherit',
  },
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    fill: '#000',
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  anchor: {
    display: 'flex',
  },
  chip_total_generated: {
    marginRight: theme.spacing(1),
  },
}))

export const RowGenerateReports = (props: Props) => {
  const classes = useStyles({})
  const {
    configReport,
    generations_list,
    getUrlToEditReport,
    getUrlViewAllGenerations,
    setCloneDialogData,
    setDeleteDialogData,
    makePdfs,
  } = props

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage, formatDate, formatTime } = intl

  /**
   * Redux Hooks
   */

  const {
    userSession: { permissions },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [open_info, setOpenInfo] = useState<boolean>(false)

  /**
   * Component functions
   */

  const getCountGenerated = () => {
    let result = 0
    generations_list.some((item: PdfGenerationType) => {
      if (item.parent['@name'] === configReport['@name'] && item.generating) {
        result = item.length
        return true
      }
      return false
    })
    return result
  }

  const isGenerating = () => {
    let result = false

    result = generations_list.some((item: PdfGenerationType) => {
      if (item.parent['@name'] === configReport['@name'] && item.generating) {
        return true
      }
      return false
    })
    return result
  }

  const haveGenerated = () => {
    return configReport.items.length > 0
  }

  /**
   * Render
   */

  const renderGenerateIcon = () => {
    if (!isGenerating() && userHasFeaturePermission(FEAT_PDF_REPORT_GENERATE, permissions)) {
      return (
        <Tooltip title={formatMessage(messages.generate)}>
          <PlayArrowIcon
            className={classes.icon}
            onClick={() => {
              makePdfs(
                configReport['@name'],
                configReport.filters.buildPdf,
                configReport.filters.buildHtml
              )
            }}
          />
        </Tooltip>
      )
    } else if (isGenerating()) {
      return (
        <>
          <Chip
            variant="outlined"
            size="small"
            className={classes.chip_total_generated}
            label={`${getCountGenerated()} of ${configReport.contract_ids.length}`}
          />
          <Chip
            variant="outlined"
            color="secondary"
            size="small"
            label={formatMessage(messages.generating)}
          />
        </>
      )
    }
  }

  const renderIconCheckOrClose = (build_it: boolean) => {
    if (build_it) {
      return <DoneIcon style={{ color: 'green' }} />
    } else {
      return <CloseIcon color="error" />
    }
  }

  return (
    <>
      <TableRow>
        <TableCell scope="row">{configReport.title}</TableCell>

        <TableCell scope="row">
          {`${formatDate(new Date(configReport.modification_date))} ${formatTime(
            new Date(configReport.modification_date)
          )}`}
        </TableCell>

        <TableCell align="right" className={classes.root}>
          {renderGenerateIcon()}

          {(userHasFeaturePermission(FEAT_PDF_REPORT_LOG_VIEW, permissions) ||
            userHasFeaturePermission(FEAT_PDF_REPORT_DOWNLOAD_PDF, permissions)) &&
            (configReport.is_generated || haveGenerated()) && (
              <Tooltip title={formatMessage(messages.viewAllGenerations)}>
                <Link to={getUrlViewAllGenerations(configReport)} className={classes.anchor}>
                  <InfoIcon className={classes.icon} />
                </Link>
              </Tooltip>
            )}

          {userHasFeaturePermission(FEAT_PDF_REPORT_EDIT, permissions) &&
            !configReport.is_generated &&
            !haveGenerated() && (
              <Tooltip title={formatMessage(messages.edit)}>
                <Link to={getUrlToEditReport(configReport)} className={classes.anchor}>
                  <EditIcon className={classes.icon} />
                </Link>
              </Tooltip>
            )}

          {userHasFeaturePermission(FEAT_PDF_REPORT_DELETE, permissions) && (
            <Tooltip title={formatMessage(messages.delete)}>
              <DeleteIcon
                className={classes.icon}
                onClick={() => {
                  setDeleteDialogData({
                    open: true,
                    itemName: configReport['@name'],
                    itemTitle: configReport.title,
                  })
                }}
              />
            </Tooltip>
          )}

          {userHasFeaturePermission(FEAT_PDF_REPORT_CLONE, permissions) && (
            <Tooltip title={formatMessage(messages.clone)}>
              <FileCopyIcon
                className={classes.icon}
                onClick={() => {
                  setCloneDialogData({
                    open: true,
                    item: configReport,
                    itemName: configReport['@name'],
                    itemTitle: configReport.title,
                  })
                }}
              />
            </Tooltip>
          )}

          <Tooltip title={formatMessage(messages.viewMoreInfoConfigGeneratePdf)}>
            {!open_info ? (
              <KeyboardArrowDownIcon
                className={classes.icon}
                onClick={() => {
                  setOpenInfo(true)
                }}
              />
            ) : (
              <KeyboardArrowUpIcon
                className={classes.icon}
                onClick={() => {
                  setOpenInfo(false)
                }}
              />
            )}
          </Tooltip>
        </TableCell>
      </TableRow>

      {open_info && (
        <TableRow>
          <TableCell colSpan={3}>
            <Paper className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={3} sm={3}>
                  <Typography component="div">
                    <Box fontWeight="fontWeightBold" m={1}>
                      {formatMessage(messages.detailInfoReportId)}:
                    </Box>
                    <Box fontWeight="fontWeightRegular" m={1}>
                      {configReport.filters.report}
                      {configReport.filters.version && configReport.filters.version.name !== ''
                        ? ` -- ${configReport.filters.version.name}`
                        : null}
                    </Box>
                  </Typography>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <Typography component="div">
                    <Box fontWeight="fontWeightBold" m={1}>
                      {formatMessage(messages.version)}:
                    </Box>
                    <Box fontWeight="fontWeightRegular" m={1}>
                      {configReport.filters.version && configReport.filters.version.name !== ''
                        ? configReport.filters.version.name
                        : '--'}
                    </Box>
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6}>
                  <Typography component="div">
                    <Box fontWeight="fontWeightBold" m={1}>
                      {formatMessage(messages.detailInfoDate)}:
                    </Box>
                    <Box fontWeight="fontWeightRegular" m={1}>
                      {`${formatDate(new Date(configReport.filters.date))} ${formatTime(
                        new Date(configReport.filters.date)
                      )}`}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography component="div">
                    <Box display="flex" alignItems="center">
                      <Box fontWeight="fontWeightBold" m={1}>
                        {formatMessage(messages.buildPdf)}:
                      </Box>
                      <Box fontWeight="fontWeightRegular" m={1}>
                        {renderIconCheckOrClose(configReport.filters.buildPdf)}
                      </Box>
                    </Box>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography component="div">
                    <Box display="flex" alignItems="center">
                      <Box fontWeight="fontWeightBold" m={1} style={{ lineHeight: 1 }}>
                        {formatMessage(messages.buildHtml)}:
                      </Box>
                      <Box fontWeight="fontWeightRegular" m={1} style={{ lineHeight: 1 }}>
                        {renderIconCheckOrClose(configReport.filters.buildHtml)}
                      </Box>
                    </Box>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography component="div">
                    <Box fontWeight="fontWeightBold" m={1}>
                      {formatMessage(messages.detailInfoContracts)}(
                      {configReport.contract_ids.length}):
                    </Box>
                    <Box fontWeight="fontWeightRegular" m={1}>
                      [{configReport.contract_ids.slice(0, 14).join(', ')}
                      {configReport.contract_ids.length > 14 ? '...' : ''}]
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
