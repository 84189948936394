import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Button, TextField, Typography } from '@material-ui/core'

import { AppState } from '../../reducers'
import routes, { HOME_PAGE_ROUTE, APPLICATIONS_PAGE_ROUTE } from '../../constants/Routes'

import { CustomBreadcrumbs, SnackbarWrapper } from '../../components'
import { messages } from './messages'
import { ApplicationManageMenuItem } from './ApplicationManageMenuItem'
import { findItemEmpty, findItemNested, getErrorMessage, getFormattedObjectId } from '../../helpers'
import { resetContent, createContent } from '../../actions'
import { InitMenuItemJsonSchema } from './InitMenuItemJsonSchema'
import { useIntl } from 'react-intl'
import { WEB_APPLICATION } from '../../constants/FilesTypes'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
  },
  containerForm: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
  btnAddMenuItem: {
    marginTop: theme.spacing(2),
  },
}))

const AddApplicationPage = () => {
  const classes = useStyles({})

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account },
    currentAccount: { account },
    content: { create },
    intl: { locale },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [application_name, setApplicationName] = useState<string>('')
  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })

  const [data_menu, setDataMenu] = useState<MenuItemJsonSchema[]>([])

  useEffect(() => {
    return () => {
      dispatch(resetContent())
    }
  }, [dispatch])

  useEffect(() => {
    if (account) {
      setDataMenu([InitMenuItemJsonSchema(account, 0)])
    }
  }, [account])

  useEffect(() => {
    if (create.loaded && !create.loading && !create.error) {
      dispatch(push(`${routes[APPLICATIONS_PAGE_ROUTE].path}?lang=${locale}`))
    } else if (create.error) {
      setAlertInfo({ open: true, type: 'error', message: getErrorMessage(create.error) })
    } else {
      setAlertInfo((prev_state) => {
        return { ...prev_state, open: false }
      })
    }
  }, [dispatch, create, locale])

  /**
   * Component functions
   */

  const handleClose = () => {
    setAlertInfo((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setSubmitted(true)
    const itemEmpty = findItemEmpty(data_menu)
    if (basic_info_account && application_name && !itemEmpty) {
      const bodyPost = {
        menus: data_menu,
        title: application_name,
        id: getFormattedObjectId(application_name),
        '@type': 'WebApplication',
      }
      dispatch(createContent(`${basic_info_account.url}/${WEB_APPLICATION}/`, bodyPost))
    } else {
      setAlertInfo({
        open: true,
        type: 'error',
        message: formatMessage(messages.errorMsgMenuNotFill),
      })
    }
  }

  const addMenuItem = () => {
    setDataMenu((prev_state: MenuItemJsonSchema[]) => [
      ...prev_state,
      InitMenuItemJsonSchema(account, 0),
    ])
  }

  const addChildrenMenuItem = (id: any) => {
    const item: MenuItemJsonSchema = findItemNested(data_menu, id)
    item.children = [...item.children, InitMenuItemJsonSchema(account, item.level + 1, id)]
    setDataMenu((prev_state: MenuItemJsonSchema[]) => Object.assign([], prev_state))
  }

  const deleteMenuItem = (id: any, parentId: any) => {
    if (parentId !== null) {
      const menuItem: MenuItemJsonSchema = findItemNested(data_menu, parentId)
      const index = menuItem.children.findIndex((item: MenuItemJsonSchema) => item.id === id)
      if (index !== -1) {
        menuItem.children.splice(index, 1)
      }
    } else {
      const index = data_menu.findIndex((item: MenuItemJsonSchema) => item.id === id)
      if (index !== -1) {
        data_menu.splice(index, 1)
      }
    }

    setDataMenu((prev_state: MenuItemJsonSchema[]) => Object.assign([], prev_state))
  }

  const updateMenuItemInfo = (id: any, data: MenuItemInfo) => {
    const item: MenuItemJsonSchema = findItemNested(data_menu, id)
    item.name = data.name
    item.reportId = data.reportId
    item.externalLink = data.externalLink
    item.isReportLinkMode = data.isReportLinkMode
    item.nameTranslations = data.nameTranslations
    item.renderHeader = data.renderHeader
    item.renderSidebar = data.renderSidebar
  }

  /**
   * Render
   */
  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
      {
        path: routes[APPLICATIONS_PAGE_ROUTE].path,
        message: formatMessage(messages.applications),
      },
    ]
    const pageName = formatMessage(messages.add)
    return <CustomBreadcrumbs links={links} currentPageName={pageName} />
  }

  return (
    <Container className={classes.containerForm}>
      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />

      {renderBreadcrumbs()}
      <form noValidate onSubmit={handleSubmit} className={classes.form}>
        <TextField
          error={submitted && !application_name}
          helperText={
            submitted && !application_name ? formatMessage(messages.applicationNameError) : ''
          }
          id="application-name"
          label={formatMessage(messages.labelApplicationName)}
          value={application_name}
          className={classes.textField}
          onChange={(e: React.ChangeEvent) => {
            const { value } = e.target as HTMLInputElement
            setApplicationName(value)
          }}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          {formatMessage(messages.btnSaveAppplication)}
        </Button>
      </form>

      <Typography component="h2" variant="h5">
        {formatMessage(messages.defineMenus)}
      </Typography>

      <ApplicationManageMenuItem
        data={data_menu}
        addChildrenMenuItem={addChildrenMenuItem}
        updateMenuItemInfo={updateMenuItemInfo}
        deleteMenuItem={deleteMenuItem}
      />

      <Button
        variant="contained"
        color="primary"
        className={classes.btnAddMenuItem}
        onClick={() => addMenuItem()}
      >
        {formatMessage(messages.btnAddMenuItem)}
      </Button>
    </Container>
  )
}

export { AddApplicationPage }
