import React from 'react'

import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'

type Props = {
  data: DialogState
  handleCloseModal(...args: any[]): void
  action(...args: any[]): void
  messages: {
    title: string
    cancel: string
    actionText: string
  }
}

export const DialogGenericAction = (props: Props) => {
  const { data, handleCloseModal, action, messages } = props
  return (
    <Dialog
      open={data.open}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{messages.title}</DialogTitle>

      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          {messages.cancel}
        </Button>
        <Button
          onClick={() => {
            action()
            handleCloseModal()
          }}
          color="primary"
          autoFocus
        >
          {messages.actionText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
