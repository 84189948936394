/**
 * Hook per saber si el component s'ha muntat
 * Ja que tenim alguns casos que al compartir reducer per les accions de l'objecte
 * creen incoherencies al respondre als events.
 *
 * Per exmple: actualitzar l'account en la pantalla de llenguatges, al anar a la
 * pantalla de plugins actius, ens detecta com si tenim canvis d'estat de l'objecte, quan
 * són canvis de la pantalla anterior.
 */
import { useState, useEffect } from 'react'

export const useIsMount = () => {
  const [isMount, setisMount] = useState(false)
  useEffect(() => setisMount(true), [])

  return isMount
}
