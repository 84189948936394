export const getErrorMessage = (error: any) => {
  let message = error.message
  if (error.response && error.response.body) {
    if (error.response.body.error && error.response.body.error.message) {
      message = error.response.body.error.message
    } else if (error.response.body.message) {
      message = error.response.body.message
    } else if (error.response.body.text) {
      message = error.response.body.text
    }
  }
  return message
}
