import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ClipLoader } from 'react-spinners'

const useStyles = makeStyles((theme) => ({
  spinnerLoading: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

type Props = {
  sizeUnit?: string
  size?: number
  color?: string
  loading: boolean
}

const ClipLoaderSpinner = (props: Props) => {
  const classes = useStyles({})
  const { sizeUnit = 'px', size = 50, color = '#123abc', loading } = props

  return (
    <div className={classes.spinnerLoading}>
      <ClipLoader sizeUnit={sizeUnit} size={size} color={color} loading={loading} />
    </div>
  )
}

export { ClipLoaderSpinner }
