import React from 'react'
import { render } from 'react-dom'

import { Provider } from 'react-intl-redux'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/ca'
import '@formatjs/intl-relativetimeformat/dist/locale-data/es'
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'

import { configureStore, history, persistAuthToken, Api } from './helpers'
import * as serviceWorker from './serviceWorker'
import allMessages from './locales'
import { App } from './App'
import { get_default_language } from './constants/locale'
import { createGenerateClassName, StylesProvider } from '@material-ui/styles'

import './index.scss'

const userLang: string = navigator.language || (navigator as IndexSignatureType)['userLanguage']
const language: string = get_default_language(userLang)
const messages: IndexSignatureType = (allMessages as IndexSignatureType)[language]

const initialState: any = {
  intl: {
    locale: language,
    messages: messages,
  },
}

const api = Api()
const store = configureStore(initialState, history, api)
persistAuthToken(store)

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'dashboard-beedata',
})

render(
  <Provider store={store as any}>
    <StylesProvider generateClassName={generateClassName}>
      <App />
    </StylesProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
