import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  GridSize,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BlockIcon from '@material-ui/icons/Block'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountInfo, resetContent, updateContent } from '../../actions'
import { CustomBreadcrumbs, SnackbarWrapper } from '../../components'
import { config } from '../../config'
import { FEAT_CSS_LAYOUT_EDIT } from '../../constants/Roles'
import routes, { HOME_PAGE_ROUTE } from '../../constants/Routes'
import {
  getBase64,
  getErrorMessage,
  getFile,
  useIsMount,
  userHasFeaturePermission,
} from '../../helpers'
import { AppState } from '../../reducers'

const messages = defineMessages({
  fontFamily1: { id: 'font-family-1', defaultMessage: 'font-family (primary)' },
  fontFamily2: { id: 'font-family-2', defaultMessage: 'font-family (secondary)' },
  colors: { id: 'colors', defaultMessage: 'Colors' },
  colorMain: { id: 'main', defaultMessage: 'Main' },
  colorSecondary: { id: 'secondary', defaultMessage: 'Secondary' },
  colorInteraction: { id: 'interaction', defaultMessage: 'Interaction' },
  colorText: { id: 'text', defaultMessage: 'Text' },
  colorH1: { id: 'h1', defaultMessage: 'H1' },
  colorH2: { id: 'h2', defaultMessage: 'H2' },
  colorBackground: { id: 'background', defaultMessage: 'Background' },
  colorMenuLogoBackground: { id: 'menu-logo-background', defaultMessage: 'Menu logo background' },
  colorMenuTitleBackground: { id: 'menu-text-background', defaultMessage: 'Menu text background' },
  colorMenuIcon: { id: 'menu-icon', defaultMessage: 'Menu Icon' },
  colorMenuBackground: { id: 'menu-background', defaultMessage: 'Menu sidebar background' },
  colorMenuText: { id: 'menu-text', defaultMessage: 'Menu sidebar text' },
  colorSidebarBackground: { id: 'sidebar-background', defaultMessage: 'Sidebar Background' },
  colorSidebarText: { id: 'sidebar-text', defaultMessage: 'Sidebar text' },
  colorOK: { id: 'ok', defaultMessage: 'OK' },
  colorNeutral: { id: 'neutral', defaultMessage: 'Neutral' },
  colorAlert: { id: 'alert', defaultMessage: 'Alert' },
  colorKO: { id: 'ko', defaultMessage: 'KO' },
  colorMessageDefault: { id: 'msg-default', defaultMessage: 'Message Default' },
  colorMessagesInfoBorder: { id: 'info-border', defaultMessage: 'Border' },
  colorMessagesInfoBackground: { id: 'info-background', defaultMessage: 'Background' },
  colorMessagesInfoText: { id: 'info-text', defaultMessage: 'Text' },
  colorMessagesInfoIcon: { id: 'info-icon', defaultMessage: 'Icon' },
  colorMessagesAlertBorder: { id: 'alert-border', defaultMessage: 'Border' },
  colorMessagesAlertBackground: { id: 'alert-background', defaultMessage: 'Background' },
  colorMessagesAlertText: { id: 'alert-text', defaultMessage: 'Text' },
  colorMessagesAlertIcon: { id: 'alert-icon', defaultMessage: 'Icon' },
  colorMessagesTipBorder: { id: 'tip-border', defaultMessage: 'Border' },
  colorMessagesTipBackground: { id: 'tip-background', defaultMessage: 'Background' },
  colorMessagesTipText: { id: 'tip-text', defaultMessage: 'Text' },
  colorMessagesTipIcon: { id: 'tip-icon', defaultMessage: 'Icon' },
  colorMessagesCongratulationsBorder: { id: 'congratulations-border', defaultMessage: 'Border' },
  colorMessagesCongratulationsBackground: {
    id: 'congratulations-background',
    defaultMessage: 'Background',
  },
  colorMessagesCongratulationsText: { id: 'congratulations-text', defaultMessage: 'Text' },
  colorMessagesCongratulationsIcon: { id: 'congratulations-icon', defaultMessage: 'Icon' },
  colorChartsBarDefault: { id: 'bar-default', defaultMessage: 'Bar Default' },
  colorChartsBarPast: { id: 'bar-past', defaultMessage: 'Bar Past' },
  colorChartsP1: { id: 'p1-peak', defaultMessage: 'P1' },
  colorChartsP2: { id: 'p2-flat', defaultMessage: 'P2' },
  colorChartsP3: { id: 'p3-valley', defaultMessage: 'P3' },
  colorChartsPermanent: { id: 'permanent', defaultMessage: 'Permanent' },
  colorChartsIndexedPrice: { id: 'indexed-price', defaultMessage: 'Indexed price' },
  colorChartsReactive: { id: 'reactive', defaultMessage: 'Reactive' },
  colorChartsPowerP1: { id: 'power-p1', defaultMessage: 'Power P1' },
  colorChartsPowerP2P3: { id: 'power-p2-p3', defaultMessage: 'Power P2/P3' },
  colorChartsLastMonth: { id: 'last-month', defaultMessage: 'Last month' },
  colorChartsMaxAverage: { id: 'max-average', defaultMessage: 'Max./Average' },
  colorChartsContractedOptimal: { id: 'contracted-optimal', defaultMessage: 'Contracted/Optimal' },
  colorChartsBillEnergy: { id: 'bill-energy', defaultMessage: 'Bill Energy' },
  colorChartsBillPower: { id: 'bill-power', defaultMessage: 'Bill Power' },
  colorChartsBillOther: { id: 'bill-other', defaultMessage: 'Bill Other' },
  colorChartsBillCompensatedSurplus: {
    id: 'bill-compensated-surplus',
    defaultMessage: 'Bill Compensated Surplus',
  },
  colorChartsBillSaves: { id: 'bill-saves', defaultMessage: 'Bill Saves' },
  colorChartsTertiaryP1: { id: 'tertiary-p1', defaultMessage: 'Tertiary P1' },
  colorChartsTertiaryP2: { id: 'tertiary-p2', defaultMessage: 'Tertiary P2' },
  colorChartsTertiaryP3: { id: 'tertiary-p3', defaultMessage: 'Tertiary P3' },
  colorChartsTertiaryP4: { id: 'tertiary-p4', defaultMessage: 'Tertiary P4' },
  colorChartsTertiaryP5: { id: 'tertiary-p5', defaultMessage: 'Tertiary P5' },
  colorChartsTertiaryP6: { id: 'tertiary-p6', defaultMessage: 'Tertiary P6' },
  colorChartsTertiaryPast1: { id: 'tertiary-p1', defaultMessage: 'Tertiary Past 1' },
  colorChartsTertiaryPast2: { id: 'tertiary-p2', defaultMessage: 'Tertiary Past 2' },
  colorChartsTertiaryPast3: { id: 'tertiary-p3', defaultMessage: 'Tertiary Past 3' },

  colorChartsConsumptionFromGrid: {
    id: 'consumption-from-grid',
    defaultMessage: 'Consumption from grid',
  },
  colorChartsSolarProduction: { id: 'solar-production', defaultMessage: 'Solar Production' },
  colorChartsSelfConsumption: { id: 'self-consumption', defaultMessage: 'Self-consumption' },
  colorChartsCompensatedSurplus: {
    id: 'compensated-surplus',
    defaultMessage: 'Compensated Surplus',
  },
  colorChartsUncompensatedSurplus: {
    id: 'uncompensated-surplus',
    defaultMessage: 'Uncompensated Surplus',
  },
  colorChartsSummer: { id: 'summer', defaultMessage: 'Summer' },
  colorChartsWinter: { id: 'winter', defaultMessage: 'Winter' },
})

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: theme.spacing(10),
  },
  paper: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: theme.spacing(2),
  },
  paperDark: {
    backgroundColor: '#f5f5f5',
    margin: `${theme.spacing(2)}px 0px`,
    padding: theme.spacing(2),
  },
  select: {
    '& ul': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}))

export const CustomizeLayoutPage = () => {
  const classes = useStyles({})

  const intl = useIntl()
  const { formatMessage } = intl
  const isMount = useIsMount()
  const dispatch = useDispatch()
  const {
    currentAccount: { account },
    content: { update },
    userSession: { token, basic_info_account, permissions },
  } = useSelector((state: AppState) => state)

  const [previewLogo, setPreviewLogo] = useState(null)
  const [formState, setFormState] = useState({
    fontFamily1: '',
    fontFamily2: '',
    colors: '',
    colorMain: '', // Actualment no aplica
    colorSecondary: '', // Actualment no aplica
    colorInteraction: '', // Color de tot allò clicable (botons, tabs, links...)
    colorText: '', // Color del text
    colorH1: '', // Color del títol del report
    colorH2: '', // Color del títols dels components
    colorBackground: '', // Color de fons del report
    colorMenuLogoBackground: '', // Color de fons del logo del menú de l'aplicació
    colorMenuTitleBackground: '', // Color de fons del title del menú de l'aplicació
    colorMenuBackground: '', // Color de fons del sidebar del menú de l'aplicació
    colorMenuIcon: '',
    colorMenuText: '', // Color del text del sidebar del menú de l'aplicació
    colorSidebarBackground: '', // Color de fons del sibedar dels components
    colorSidebarText: '', // Color de text del sibedar dels components
    colorOK: '', // Actualment no aplica
    colorNeutral: '', // Actualment no aplica
    colorAlert: '', // Actualment no aplica
    colorKO: '', // Actualment no aplica
    colorMessageDefault: '',
    colorMessagesInfoBorder: '', // Color del border dels missatges tipus "info" de Peusa
    colorMessagesInfoBackground: '', // Color de fons dels missatges tipus "info" de Peusa
    colorMessagesInfoText: '', // Color del text dels missatges tipus "info" de Peusa
    colorMessagesInfoIcon: '', // Color de la icona dels missatges tipus "info" de Peusa
    colorMessagesAlertBorder: '', // Color del border dels missatges tipus "alert" de Peusa
    colorMessagesAlertBackground: '', // Color de fons dels missatges tipus "alert" de Peusa
    colorMessagesAlertText: '', // Color del text dels missatges tipus "alert" de Peusa
    colorMessagesAlertIcon: '', // Color de la icona dels missatges tipus "alert" de Peusa
    colorMessagesTipBorder: '', // Color del border dels missatges tipus "advice" de Peusa
    colorMessagesTipBackground: '', // Color de fons dels missatges tipus "advice" de Peusa
    colorMessagesTipText: '', // Color del text dels missatges tipus "advice" de Peusa
    colorMessagesTipIcon: '', // Color de la icona dels missatges tipus "advice" de Peusa
    colorMessagesCongratulationsBorder: '', // Color del border dels missatges tipus "congratulation" de Peusa
    colorMessagesCongratulationsBackground: '', // Color de fons dels missatges tipus "congratulation" de Peusa
    colorMessagesCongratulationsText: '', // Color del text dels missatges tipus "congratulation" de Peusa
    colorMessagesCongratulationsIcon: '', // Color de la icona dels missatges tipus "congratulation" de Peusa
    colorChartsBarDefault: '', // Color per defecte de les barres dels gràfics de barres
    colorChartsBarPast: '', // Color de l'any anterior de Peusa
    colorChartsP1: '', // Color de P1 en els gràfics de Peusa (indexat)
    colorChartsP2: '', // Color de P2 en els gràfics de Peusa (indexat)
    colorChartsP3: '', // Color de P3 en els gràfics de Peusa (indexat)
    colorChartsPermanent: '', // Color de "permanent" en els gràfics de Peusa
    colorChartsIndexedPrice: '', // Color del preu de Peusa (indexat)
    colorChartsReactive: '', // Actualment no aplica
    colorChartsPowerP1: '', // Color del fons de P1 del "Cost per potència contractada" de Peusa
    colorChartsPowerP2P3: '', // Color del fons de P2/P3 del "Cost per potència contractada" de Peusa
    colorChartsLastMonth: '', // Color de l'últim mes del "Cost per potència contractada" de Peusa
    colorChartsMaxAverage: '', // Color de "màxima" i "mitjana"" del "Cost per potència contractada" de Peusa
    colorChartsContractedOptimal: '', // Color de "contractada" i "optima" del "Cost per potència contractada" de Peusa
    colorChartsBillEnergy: '', // Color d'"energia" en els gràfics de Peusa
    colorChartsBillPower: '', // Color de "potència" en els gràfics de Peusa
    colorChartsBillOther: '', // Color d'"altres" en els gràfics de Peusa
    colorChartsTertiaryP1: '', // Color de la gràfica de l'aplicació de terciaris Peusa
    colorChartsTertiaryP2: '', // Color de la gràfica de l'aplicació de terciaris Peusa
    colorChartsTertiaryP3: '', // Color de la gràfica de l'aplicació de terciaris Peusa
    colorChartsTertiaryP4: '', // Color de la gràfica de l'aplicació de terciaris Peusa
    colorChartsTertiaryP5: '', // Color de la gràfica de l'aplicació de terciaris Peusa
    colorChartsTertiaryP6: '', // Color de la gràfica de l'aplicació de terciaris Peusa
    colorChartsTertiaryPast1: '', // Color de la gràfica de l'aplicació de terciaris Peusa
    colorChartsTertiaryPast2: '', // Color de la gràfica de l'aplicació de terciaris Peusa
    colorChartsTertiaryPast3: '', // Color de la gràfica de l'aplicació de terciaris Peusa
    colorChartsConsumptionFromGrid: '',
    colorChartsSolarProduction: '',
    colorChartsSelfConsumption: '',
    colorChartsCompensatedSurplus: '', // Actualment no aplica
    colorChartsUncompensatedSurplus: '', // Actualment no aplica
    colorChartsSummer: '',
    colorChartsWinter: '',
    fontFamily: '', //old
    logo: null,
  })
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    type: 'error',
    message: '',
    open: false,
  })

  useEffect(() => {
    if (account) {
      if (account.cssvars) {
        setFormState((prev) => ({ ...prev, ...account.cssvars }))
      }
      if (account.logo) {
        getFile(`${config.apiUrl}/db/${account['@name']}/@download/logo`, token!).then((data) => {
          setPreviewLogo(data)
        })
      }
    }
    return () => dispatch(resetContent())
  }, [account, dispatch, token])

  useEffect(() => {
    if (
      isMount &&
      update.loaded &&
      !update.loading &&
      !update.error &&
      account &&
      basic_info_account
    ) {
      dispatch(getAccountInfo(basic_info_account.url))
      setAlertInfo({ open: true, type: 'success', message: 'Account has been updated' })
    } else if (update.error) {
      setAlertInfo({ open: true, type: 'error', message: getErrorMessage(update.error) })
    }
  }, [dispatch, update, account, formatMessage, isMount, basic_info_account])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (account) {
      let data = { cssvars: { ...formState } }

      if (formState.logo) {
        data = { ...data, ...{ logo: formState.logo } }
      }

      dispatch(updateContent(`db/${account['@name']}/`, data))
    }
  }

  const handleChangeFile = (e: React.SyntheticEvent) => {
    const { files } = e.target as HTMLInputElement

    if (files && files[0]) {
      getBase64(files[0]).then((data: any) => {
        setPreviewLogo(data)
        setFormState((prev) => ({
          ...prev,
          logo: {
            data: data.split(',')[1],
            encoding: 'base64',
            content_type: files[0].type,
            filename: files[0].name,
          },
        }))
      })
    }
  }

  const textField = (id, xs = 12, sm = 12) => (
    <Grid item xs={xs as GridSize} sm={sm as GridSize}>
      <TextField
        id={id}
        label={formatMessage(messages[id])}
        fullWidth
        size="small"
        value={formState[id]}
        onChange={(e) => setFormState((prev) => ({ ...prev, [e.target.id]: e.target.value }))}
      />
    </Grid>
  )

  const simpleSelect = (id, xs = 12, md = 12) => {
    return (
      <Grid key={id} item xs={xs as GridSize} md={md as GridSize}>
        <FormControl size="small" fullWidth>
          <Select
            displayEmpty
            name={id}
            value={formState[id] || ''}
            onChange={(e) => setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }))}
            MenuProps={{ classes: { paper: classes.select } }}
          >
            <MenuItem value="">
              <BlockIcon style={{ color: '#f5f5f5' }} />
            </MenuItem>
            {formState.colors &&
              formState.colors.split(',').map((item) => (
                <MenuItem key={item} value={item}>
                  <Brightness1Icon style={{ color: item }} />
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{formatMessage(messages[id])}</FormHelperText>
        </FormControl>
      </Grid>
    )
  }

  const renderPalette = () => (
    <Paper className={classes.paperDark} elevation={0}>
      <Typography variant="subtitle2">Default Values</Typography>
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="colors"
              label="Default Values"
              fullWidth
              size="small"
              value={formState['colors']}
              multiline
              onChange={(e) => setFormState((prev) => ({ ...prev, [e.target.id]: e.target.value }))}
            />
          </Grid>
        </Grid>
      </Paper>
      <Typography variant="subtitle2">Globals</Typography>
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={2}>
          {[
            'colorMain',
            'colorSecondary',
            'colorInteraction',
            'colorText',
            'colorH1',
            'colorH2',
            'colorBackground',
            'colorMenuLogoBackground',
            'colorMenuTitleBackground',
            'colorMenuIcon',
            'colorMenuBackground',
            'colorMenuText',
            'colorSidebarBackground',
            'colorSidebarText',
            'colorOK',
            'colorNeutral',
            'colorAlert',
            'colorKO',
            'colorMessageDefault',
          ].map((item) => simpleSelect(item, 4, 2))}
        </Grid>
      </Paper>
      <Typography variant="subtitle2">Messages</Typography>
      <Paper className={classes.paper} variant="outlined">
        <Typography variant="subtitle2">Info</Typography>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          {[
            'colorMessagesInfoBorder',
            'colorMessagesInfoBackground',
            'colorMessagesInfoText',
            'colorMessagesInfoIcon',
          ].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
        <Typography variant="subtitle2">Alert</Typography>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          {[
            'colorMessagesAlertBorder',
            'colorMessagesAlertBackground',
            'colorMessagesAlertText',
            'colorMessagesAlertIcon',
          ].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
        <Typography variant="subtitle2">Tip</Typography>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          {[
            'colorMessagesTipBorder',
            'colorMessagesTipBackground',
            'colorMessagesTipText',
            'colorMessagesTipIcon',
          ].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
        <Typography variant="subtitle2">Congratulations</Typography>
        <Grid container spacing={2}>
          {[
            'colorMessagesCongratulationsBorder',
            'colorMessagesCongratulationsBackground',
            'colorMessagesCongratulationsText',
            'colorMessagesCongratulationsIcon',
          ].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
      </Paper>
      <Typography variant="subtitle2">Charts</Typography>
      <Paper className={classes.paper} variant="outlined">
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          {['colorChartsBarDefault', 'colorChartsBarPast'].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          {[
            'colorChartsP1',
            'colorChartsP2',
            'colorChartsP3',
            'colorChartsPermanent',
            'colorChartsIndexedPrice',
            'colorChartsReactive',
          ].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          {[
            'colorChartsPowerP1',
            'colorChartsPowerP2P3',
            'colorChartsLastMonth',
            'colorChartsMaxAverage',
            'colorChartsContractedOptimal',
          ].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          {[
            'colorChartsBillEnergy',
            'colorChartsBillPower',
            'colorChartsBillOther',
            'colorChartsBillCompensatedSurplus',
            'colorChartsBillSaves',
          ].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          {[
            'colorChartsTertiaryP1',
            'colorChartsTertiaryP2',
            'colorChartsTertiaryP3',
            'colorChartsTertiaryP4',
            'colorChartsTertiaryP5',
            'colorChartsTertiaryP6',
          ].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
        <Grid container spacing={2}>
          {['colorChartsTertiaryPast1', 'colorChartsTertiaryPast2', 'colorChartsTertiaryPast3'].map(
            (item) => simpleSelect(item, 3, 2)
          )}
        </Grid>
        <Grid container spacing={2}>
          {[
            'colorChartsConsumptionFromGrid',
            'colorChartsSolarProduction',
            'colorChartsSelfConsumption',
            'colorChartsCompensatedSurplus',
            'colorChartsUncompensatedSurplus',
          ].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
        <Grid container spacing={2}>
          {['colorChartsSummer', 'colorChartsWinter'].map((item) => simpleSelect(item, 3, 2))}
        </Grid>
      </Paper>
    </Paper>
  )

  const renderTypography = () => (
    <Paper className={classes.paperDark} elevation={0}>
      <Grid container spacing={2}>
        {textField('fontFamily1', 12, 6)}
        {textField('fontFamily2', 12, 6)}
      </Grid>
    </Paper>
  )

  const renderLogo = () => (
    <Paper className={classes.paperDark} elevation={0}>
      <Grid container spacing={2}>
        {previewLogo && (
          <Grid item xs={12}>
            <img src={previewLogo} alt="Preview logo" />
          </Grid>
        )}
        {userHasFeaturePermission(FEAT_CSS_LAYOUT_EDIT, permissions) && (
          <Grid item xs={12}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="logo-file"
              multiple
              type="file"
              onChange={handleChangeFile}
            />
            <label htmlFor="logo-file">
              <Button color="primary" component="span" variant="contained">
                Upload
              </Button>
            </label>
          </Grid>
        )}
      </Grid>
    </Paper>
  )

  return (
    <Container maxWidth="lg">
      <CustomBreadcrumbs
        links={[
          {
            path: routes[HOME_PAGE_ROUTE].path,
            message: 'Home',
          },
        ]}
        currentPageName="Customize Layout Report"
      />
      <SnackbarWrapper
        alertInfo={alertInfo}
        handleCloseSnackbar={() => setAlertInfo((prev) => ({ ...prev, open: false }))}
      />
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Typography variant="h4" align="center">
          Customize Layout Report
        </Typography>
        <Typography variant="h5">Palette Colors</Typography>
        {renderPalette()}
        <Typography variant="h5">Typography</Typography>
        {renderTypography()}
        <Typography variant="h5">Logo</Typography>
        {renderLogo()}

        {userHasFeaturePermission(FEAT_CSS_LAYOUT_EDIT, permissions) && (
          <Button color="primary" disabled={update.loading} type="submit" variant="contained">
            Save
          </Button>
        )}
      </form>
    </Container>
  )
}
