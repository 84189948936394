import React, { useState, useEffect } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import { TableRow, TableCell, Tooltip, Checkbox } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { getFile, userHasFeaturePermission } from '../../helpers'
import { FEAT_IMAGE_DELETE } from '../../constants/Roles'

const messages = defineMessages({
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
})

const useStyles = makeStyles((theme) => ({
  imgLogo: {
    maxWidth: '75px',
    height: 'auto',
    backgroundColor: '#f3f3f3',
  },
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    fill: '#000',
  },
}))

type Props = {
  itemImage: any
  token: string
  setDeleteDialogData: any
  permissions: BeePermissions
  checked: boolean
  onChecked: (checked: boolean) => void
}

export const RowImage = (props: Props) => {
  const classes = useStyles({})
  const { itemImage, setDeleteDialogData, token, permissions, checked, onChecked } = props

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * React Hooks
   */

  const [preview_image, setPreviewImage] = useState<any>(null)

  useEffect(() => {
    getFile(`${itemImage['@id']}/@download/file`, token!).then((data: any) => {
      setPreviewImage(data)
    })
  }, [token, itemImage])

  /**
   * Render
   */
  const renderPreviewImg = () => {
    if (preview_image) {
      return <img src={preview_image} className={classes.imgLogo} alt="preview" />
    }
  }
  return (
    <TableRow>
      <TableCell scope="row">
        <Checkbox
          checked={checked}
          onChange={() => {
            onChecked(!checked)
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </TableCell>
      <TableCell scope="row">{renderPreviewImg()}</TableCell>
      <TableCell scope="row">{itemImage['@id']}</TableCell>
      <TableCell scope="row">{itemImage.file.filename}</TableCell>

      {userHasFeaturePermission(FEAT_IMAGE_DELETE, permissions) && (
        <TableCell align="right">
          <Tooltip title={formatMessage(messages.delete)}>
            <DeleteIcon
              className={classes.icon}
              onClick={() => {
                setDeleteDialogData({
                  open: true,
                  itemName: itemImage['@name'],
                  itemTitle: itemImage['@name'],
                })
              }}
            />
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  )
}
