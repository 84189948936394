import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core'

import { InfoDataBaseAccount } from '../../types/guillotina'

const messages = defineMessages({
  cloneReportDialogTitle: {
    id: 'clone-report-dialog-title',
    defaultMessage: 'Do you want to clone "{name}"?',
  },
  cloneDialogText: {
    id: 'clone-dialog-text',
    defaultMessage: 'Enter name',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  nameError: {
    id: 'name-error',
    defaultMessage: 'Name is mandatory',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  clone: {
    id: 'clone',
    defaultMessage: 'Clone',
  },
})

type CloneFormData = {
  submitted: boolean
  reportName: string
}

type Props = {
  cloneDialogData: DialogState
  handleCloseCloneDialog: () => void
  actionCloneReport: (url: string, data: any, nameForm: string) => void
  currentAccount: InfoDataBaseAccount | null
}

export const DialogCloneAction = (props: Props) => {
  const { cloneDialogData, handleCloseCloneDialog, currentAccount, actionCloneReport } = props

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * React Hooks
   */

  const [clone_form_data, setCloneFormData] = useState<CloneFormData>({
    submitted: false,
    reportName: '',
  })

  /**
   * Component functions
   */

  const handleClickClone = () => {
    setCloneFormData((prevState) => {
      return { ...prevState, submitted: true }
    })

    if (currentAccount && clone_form_data.reportName) {
      actionCloneReport(currentAccount.url, cloneDialogData.item, clone_form_data.reportName)

      handleCloseCloneDialog()
    }
  }

  /**
   * Render
   */

  return (
    <Dialog
      open={cloneDialogData.open}
      onClose={handleCloseCloneDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {formatMessage(messages.cloneReportDialogTitle, { name: cloneDialogData.itemTitle })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{formatMessage(messages.cloneDialogText)}</DialogContentText>
        <TextField
          error={clone_form_data.submitted && !clone_form_data.reportName}
          helperText={
            clone_form_data.submitted && !clone_form_data.reportName
              ? formatMessage(messages.nameError)
              : ''
          }
          autoFocus
          margin="dense"
          label={formatMessage(messages.name)}
          type="text"
          fullWidth
          value={clone_form_data.reportName}
          onChange={(e: React.ChangeEvent) => {
            const { value } = e.target as HTMLInputElement
            setCloneFormData((prevState) => {
              return { ...prevState, reportName: value }
            })
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCloneDialog} color="primary">
          {formatMessage(messages.cancel)}
        </Button>
        <Button onClick={handleClickClone} color="primary">
          {formatMessage(messages.clone)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
