import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { push } from 'connected-react-router'

import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Button,
  TextField,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Box,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/min/locales'

import { AppState } from '../../reducers'
import routes, { GENERATE_REPORTS_PAGE_ROUTE, HOME_PAGE_ROUTE } from '../../constants/Routes'

import { messages } from './messages'
import { getErrorMessage, getFormattedObjectId, useIsMount } from '../../helpers'

import { CustomBreadcrumbs, SnackbarWrapper, ClipLoaderSpinner } from '../../components'

import {
  getReports,
  getContent,
  resetContent,
  updateContent,
  createContent,
  getVersions,
} from '../../actions'

import { ReportType } from '../../types/guillotina'
import { SET_PDF, REPORT_DESIGN } from '../../constants/FilesTypes'

const useStyles = makeStyles((theme) => ({
  containerForm: {
    marginBottom: theme.spacing(2),
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  formControl: {
    width: '100%',
  },
  textField: {
    width: '100%',
    margin: '0',
  },
  gridMargin: {
    marginTop: theme.spacing(3),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  error: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
}))

export const ManageConfigGenerateReportPage = (props: RouteComponentProps) => {
  const classes = useStyles({})
  const { match } = props

  const is_edit_mode = 'name' in match.params

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Custom hooks
   */

  const isMount = useIsMount()

  /**
   * Redux Hooks
   */

  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account },
    currentAccount: { reports, get_reports },
    intl: { locale },
    content: { data, create, update, get },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [versions, setVersions] = useState<any>(null)
  const [content_title, setContentTitle] = useState<string>('')
  const [is_get_version, setIsGetVersions] = useState<boolean>(false)

  const [form_state, setFormState] = useState<any>({
    contracts_selected: '',
    name: '',
    filters: {
      report: '',
      version: {
        name: '',
        title: '',
        layout: {},
      },
      buildHtml: false,
      buildPdf: true,
      date: moment(),
    },
    submitted: false,
  })

  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })

  useEffect(() => {
    return () => {
      dispatch(resetContent())
    }
  }, [dispatch])

  useEffect(() => {
    if (is_edit_mode && data && data.is_generated) {
      dispatch(push(`${routes[GENERATE_REPORTS_PAGE_ROUTE].path}?lang=${locale}`))
    }
  }, [dispatch, is_edit_mode, data, locale])

  useEffect(() => {
    if (
      is_edit_mode &&
      basic_info_account &&
      data === null &&
      !get.loading &&
      !get.error &&
      !get.loaded
    ) {
      const matchParams: any = match.params
      setIsGetVersions(false)
      dispatch(getContent(`${basic_info_account.url}/${SET_PDF}/${matchParams.name}`))
    }
  }, [basic_info_account, data, dispatch, match.params, get, is_edit_mode])

  useEffect(() => {
    if (data !== null && !is_get_version && basic_info_account) {
      // Inicialitzar formulari
      setContentTitle(data.title)
      setFormState((prev_state: any) => {
        return {
          ...prev_state,
          filters: data.filters,
          name: data.title,
          contracts_selected: data.contract_ids.join(','),
        }
      })

      setIsGetVersions(true)
      dispatch(getVersions(`${basic_info_account.url}/${REPORT_DESIGN}/${data.filters.report}`))
    }
  }, [data, is_get_version, basic_info_account, dispatch])

  useEffect(() => {
    if (basic_info_account !== null) {
      if (!get_reports.loaded && !get_reports.loading) {
        dispatch(getReports(basic_info_account.url))
      }
    }
  }, [get_reports, basic_info_account, dispatch])

  useEffect(() => {
    if (isMount && update.loaded && !update.loading && !update.error) {
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.updateConfigGenerateReportSuccessful),
      })
    } else if (isMount && create.loaded && !create.loading && !create.error) {
      dispatch(push(`${routes[GENERATE_REPORTS_PAGE_ROUTE].path}?lang=${locale}`))
    } else if (update.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(update.error),
      })
    } else if (create.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(create.error),
      })
    } else if (get_reports.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(get_reports.error),
      })
    }
  }, [dispatch, create, update, get_reports, formatMessage, isMount, locale])

  useEffect(() => {
    if (get.loaded && is_get_version) {
      setVersions(data)
    }
  }, [is_get_version, get.loaded, data])

  /**
   * Component functions
   */

  const { contracts_selected, filters, name, submitted } = form_state

  const handleClose = () => {
    setAlertInfo((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = e.target as HTMLInputElement
    setFormState((prev_state: any) => {
      return { ...prev_state, [name]: value }
    })
  }

  const onReportChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = e.target as HTMLInputElement
    setVersions(null)
    setFormState((prev_state: any) => {
      return {
        ...prev_state,
        filters: {
          ...filters,
          report: value,
          version: {
            name: '',
            title: '',
            layout: {},
          },
        },
      }
    })

    if (basic_info_account && value) {
      setIsGetVersions(true)
      dispatch(getVersions(`${basic_info_account.url}/${REPORT_DESIGN}/${value}`))
    }
  }

  const onVersionChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = e.target as HTMLInputElement
    const currentVersion: any = Object.values(versions).find((v: any) => v.name === value)
    const currentReport: ReportType | undefined = reports.find(
      (r: ReportType) => r['@name'] === filters.report
    )
    if (currentVersion && currentReport) {
      setFormState((prev_state: any) => {
        return {
          ...prev_state,
          filters: {
            ...filters,
            version: {
              name: currentVersion.name,
              title: currentReport.title,
              layout: currentVersion.layout,
            },
          },
        }
      })
    }
  }

  const onDateChange = (date: any) => {
    setFormState((prev_state: any) => {
      return { ...prev_state, filters: { ...filters, date: date } }
    })
  }

  const onSwitchChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prev_state: any) => {
      return { ...prev_state, filters: { ...filters, [name]: event.target.checked } }
    })
  }

  const canSubmitForm = () => {
    const buildAnyThing = checkAnyBuildIsMarked()
    if (contracts_selected && buildAnyThing && name && filters.report !== '' && filters.date) {
      return true
    } else if (filters.report === '') {
      setAlertInfo({
        open: true,
        type: 'error',
        message: formatMessage(messages.errorMsgReportNotFill),
      })
    } else if (!filters.date) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: formatMessage(messages.errorMessageSetDateRange),
      })
    } else if (!buildAnyThing) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: formatMessage(messages.errorMsgBuildAnyThing),
      })
    }

    return false
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    setFormState((prev_state: any) => {
      return { ...prev_state, submitted: true }
    })

    if (basic_info_account && canSubmitForm()) {
      if (is_edit_mode) {
        // Update
        const bodyPost = {
          filters: filters,
          title: name,
          contract_ids: contracts_selected.split(','),
        }
        const matchParams: any = match.params
        dispatch(
          updateContent(`${basic_info_account.url}/${SET_PDF}/${matchParams.name}`, bodyPost)
        )
      } else {
        // Create
        const bodyPost = {
          filters: filters,
          title: name,
          contract_ids: contracts_selected.split(','),
          id: getFormattedObjectId(name),
          '@type': 'SetPDF',
        }
        dispatch(createContent(`${basic_info_account.url}/${SET_PDF}/`, bodyPost))
      }
    }
  }

  const checkAnyBuildIsMarked = () => {
    return filters.buildHtml || filters.buildPdf
  }

  /**
   * Render
   */

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
      {
        path: routes[GENERATE_REPORTS_PAGE_ROUTE].path,
        message: formatMessage(messages.generateReports),
      },
    ]

    const pageName = !('name' in match.params)
      ? formatMessage(messages.add)
      : formatMessage(messages.edit)

    return <CustomBreadcrumbs links={links} currentPageName={pageName} />
  }

  const btnSubmit = () => {
    if (!create.loading) {
      return (
        <Button variant="contained" color="primary" type="submit">
          {formatMessage(messages.btnSave)}
        </Button>
      )
    }
  }

  const renderInputName = () => {
    if (!is_edit_mode) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              error={submitted && !name}
              helperText={submitted && !name ? formatMessage(messages.genericTextRequired) : ''}
              id="name"
              name="name"
              label={formatMessage(messages.name)}
              fullWidth
              margin="normal"
              value={name}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Container className={classes.containerForm}>
      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />

      {renderBreadcrumbs()}

      <Typography component="h1" variant="h4" align="center">
        {content_title}
      </Typography>

      <form noValidate onSubmit={handleSubmit}>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {renderInputName()}

            <Grid item xs={12}>
              <Box mt={3}>
                <Typography component="h5">
                  {formatMessage(messages.titleInputContractsId)}
                </Typography>
              </Box>
              <Box mt={3}>
                <FormControl
                  className={classes.formControl}
                  error={submitted && contracts_selected.length === 0}
                >
                  <TextField
                    multiline
                    rowsMax="25"
                    id="contracts_selected"
                    name="contracts_selected"
                    placeholder={formatMessage(messages.enterListContracts)}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    value={contracts_selected}
                    onChange={handleChange}
                    helperText={'Example: (contract_1,contract_2)'}
                  />

                  {submitted && contracts_selected === '' ? (
                    <FormHelperText>{formatMessage(messages.contractError)}</FormHelperText>
                  ) : null}
                </FormControl>
              </Box>
            </Grid>

            <Box mt={3}>
              <Typography component="h5">
                {formatMessage(messages.uploadCsvFileContracts)}
              </Typography>
            </Box>
            <Grid item xs={12}>
              <Box mt={3}>
                <input
                  id="input-upload-file"
                  type="file"
                  className={classes.inputFile}
                  onChange={(e: React.ChangeEvent) => {
                    const { files } = e.target as HTMLInputElement
                    const reader = new FileReader()
                    reader.onload = () => {
                      const { result } = reader
                      const allTextLines = (result as string).split(/\r\n|\n/)
                      setFormState((prev_state: any) => {
                        return {
                          ...prev_state,
                          contracts_selected: allTextLines[0],
                        }
                      })
                    }
                    if (files) {
                      if (files[0].type !== 'text/csv') {
                        reader.abort()
                        setAlertInfo({
                          open: true,
                          type: 'error',
                          message: formatMessage(messages.typeFileIncorrect),
                        })
                      } else {
                        reader.readAsText(files[0])
                      }
                    }
                  }}
                />
                <label htmlFor="input-upload-file" id="input-upload-file">
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    component="span"
                  >
                    {formatMessage(messages.upload)}
                    <CloudUploadIcon className={classes.rightIcon} />
                  </Button>
                </label>
              </Box>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box mt={2} mb={2}>
                  <Typography component="h5">{formatMessage(messages.setDateRange)}</Typography>
                </Box>

                <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                  <DatePicker
                    value={filters.date}
                    onChange={onDateChange}
                    format={'MM/YYYY'}
                    label={formatMessage(messages.selectDate)}
                    views={['month', 'year']}
                    className={classes.formControl}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filters.buildHtml}
                      onChange={onSwitchChange('buildHtml')}
                      value="buildHtml"
                      color="primary"
                    />
                  }
                  label={formatMessage(messages.buildHtml)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filters.buildPdf}
                      onChange={onSwitchChange('buildPdf')}
                      value="buildPdf"
                      color="primary"
                    />
                  }
                  label={formatMessage(messages.buildPdf)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box mt={2} mb={2}>
                  <Typography component="h5">
                    {formatMessage(messages.titleSectionSetReports)}
                  </Typography>
                </Box>

                <FormControl className={classes.formControl}>
                  <InputLabel>{formatMessage(messages.reportTitle)}</InputLabel>
                  <Select value={filters.report} onChange={onReportChange}>
                    {reports.map((report: ReportType) => {
                      return (
                        <MenuItem key={report['@name']} value={report['@name']}>
                          {report.title}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {filters.report &&
              versions &&
              Object.entries(versions).length > 0 &&
              versions.constructor === Object ? (
                <Grid item xs={12} sm={6}>
                  <Box mt={2} mb={2}>
                    <Typography component="h5">
                      {formatMessage(messages.titleSectionSetVersion)}
                    </Typography>
                  </Box>

                  <FormControl className={classes.formControl}>
                    <InputLabel>{formatMessage(messages.version)}</InputLabel>
                    <Select value={filters.version.name} onChange={onVersionChange}>
                      {Object.entries(versions).map((version: any) => {
                        return (
                          <MenuItem key={version[0]} value={version[1].name}>
                            {version[1].name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
            </Grid>
          </Paper>

          <Grid container>
            <ClipLoaderSpinner loading={create.loading} />
            {btnSubmit()}
          </Grid>
        </main>
      </form>
    </Container>
  )
}
