import caMessages from '../locales/ca.json'
import esMessages from '../locales/es.json'
import enMessages from '../locales/en.json'
import allMessages from '../locales'

export const locales: { [id: string]: any } = {
  en: enMessages,
  es: esMessages,
  ca: caMessages,
}

export const get_default_language = (navigator_language: string) => {
  let language = 'en'
  if (navigator_language in allMessages) {
    language = navigator_language
  }
  return language
}

export const UTILITIES_LANGUAGES = ['en', 'es', 'ca', 'it', 'fr']
