import * as routes from './Routes.ts'

/* ROLES definitions: */
/* Roles added/deleted/edited in the openam should be also updated in the list bellow */
export const ROLE_ADMIN = 'beedata.Admin'
export const ROLE_SUPER_USER = 'beedata.SuperUser'
export const ROLE_UTILITY_MOCKUP_USER = 'beedata.UtilityMockup'
export const ROLE_MOCKUP_VIEWER_USER = 'beedata.MockupViewer'
export const ROLE_BI_USER = 'beedata.BIUser'

/* FEATURE definitions: */
/* All new features implemented in the dashboard such access or behaviour depends on the user
role, must be defined bellow and linked to the proper roles using the roles_catalog structure */

// Reports feature definitions
export const FEAT_REPORT_CREATE = 'feat-report-create'
export const FEAT_REPORT_EDIT = 'feat-report-edit'
export const FEAT_REPORT_PREVIEW = 'feat-report-preview'
export const FEAT_REPORT_CLONE = 'feat-report-clone'
export const FEAT_REPORT_DELETE = 'feat-report-delete'
export const FEAT_REPORT_DOWNLOAD = 'feat-report-download'
export const FEAT_REPORT_RECOVER_PREVIOUS_VERSION = 'feat-report-recover-previous-version'
export const FEAT_REPORT_DELETE_PREVIOUS_VERSION = 'feat-report-delete-previous-version'
export const FEAT_REPORT_UPLOAD_TRANSLATIONS = 'feat-report-upload-translations'
export const FEAT_REPORT_DOWNLOAD_TRANSLATIONS = 'feat-report-download-translations'

// Applications feature definitions
export const FEAT_APP_CREATE = 'feat-app-create'
export const FEAT_APP_EDIT = 'feat-app-edit'
export const FEAT_APP_DELETE = 'feat-app-delete'
export const FEAT_APP_PREVIEW = 'feat-app-preview'

// Plugin management feature definitions
export const FEAT_PLUGIN_ACTIVES_VIEW = 'feat-plugin-actives-view'
export const FEAT_PLUGIN_ACTIVES_EDIT = 'feat-plugin-actives-edit'

// Layout CSS management feature definitions
export const FEAT_CSS_LAYOUT_EDIT = 'feat-css-layout-edit'

// Application CSS management feature definitions
export const FEAT_CSS_APP_EDIT = 'feat-css-app-edit'

// PDF generation management feature definitions
export const FEAT_PDF_REPORT_GENERATE = 'feat-pdf-report-generate'
export const FEAT_PDF_REPORT_LOG_VIEW = 'feat-pdf-report-log-view'
export const FEAT_PDF_REPORT_CREATE_CONFIGURATION = 'feat-pdf-report-create-configuration'
export const FEAT_PDF_REPORT_CLONE = 'feat-pdf-report-clone'
export const FEAT_PDF_REPORT_DELETE = 'feat-pdf-report-delete'
export const FEAT_PDF_REPORT_EDIT = 'feat-pdf-report-edit'
export const FEAT_PDF_REPORT_DOWNLOAD_PDF = 'feat-pdf-report-download-pdf'

// Link to BI feature definition
export const FEAT_BI_LINK = 'feat-bi-link'

// Service Token management feature definitions
export const FEAT_SERVICE_TOKEN_CREATE = 'feat-service-token-create'
export const FEAT_SERVICE_TOKEN_DELETE = 'feat-service-token-delete'

// Images feature definitions
export const FEAT_IMAGE_CREATE = 'feat-image-create'
export const FEAT_IMAGE_DELETE = 'feat-image-delete'

const roles_catalog: RolesObject = {}

// Super user role
roles_catalog[ROLE_SUPER_USER] = {
  routes: [
    routes.HOME_PAGE_ROUTE,
    routes.REPORTS_PAGE_ROUTE,
    routes.ADD_REPORT_ROUTE,
    routes.EDIT_REPORT_ROUTE,
    routes.LOGIN_PAGE_ROUTE,
    routes.NOT_FOUND_PAGE_ROUTE,
    routes.APPLICATIONS_PAGE_ROUTE,
    routes.ADD_APPLICATION_ROUTE,
    routes.EDIT_APPLICATION_ROUTE,
    routes.PREVIEW_APPLICATION_PAGE_ROUTE,
    routes.CHOOSE_PLUGINS_PAGE_ROUTE,
    routes.MANAGE_LANGUAGES_PAGE_ROUTE,
    routes.MANAGE_IMAGES_PAGE_ROUTE,
    routes.CUSTOMIZE_LAYOUT_PAGE_ROUTE,
    routes.CUSTOMIZE_APPLICATION_PAGE_ROUTE,
    routes.PREVIEW_REPORT_PAGE_ROUTE,
    routes.PREVIEW_VERSION_REPORT_PAGE_ROUTE,
    routes.GENERATE_REPORTS_PAGE_ROUTE,
    routes.CREATE_CONFIGURATION_REPORT_PAGE_ROUTE,
    routes.EDIT_CONFIGURATION_REPORT_PAGE_ROUTE,
    routes.VIEW_ALL_GENERATIONS_CONFIGURATION_REPORT_PAGE_ROUTE,
    routes.SERVICE_TOKENS_PAGE_ROUTE,
    routes.CHANGE_ACCOUNT_REDIRECT,
    routes.GENERATE_ACCOUNT_TEXT,
  ],
  features: [
    FEAT_REPORT_CREATE,
    FEAT_REPORT_EDIT,
    FEAT_REPORT_PREVIEW,
    FEAT_REPORT_CLONE,
    FEAT_REPORT_DELETE,
    FEAT_REPORT_DOWNLOAD,
    FEAT_REPORT_RECOVER_PREVIOUS_VERSION,
    FEAT_REPORT_DELETE_PREVIOUS_VERSION,
    FEAT_REPORT_UPLOAD_TRANSLATIONS,
    FEAT_REPORT_DOWNLOAD_TRANSLATIONS,
    FEAT_APP_CREATE,
    FEAT_APP_EDIT,
    FEAT_APP_DELETE,
    FEAT_APP_PREVIEW,
    FEAT_PLUGIN_ACTIVES_VIEW,
    FEAT_PLUGIN_ACTIVES_EDIT,
    FEAT_CSS_LAYOUT_EDIT,
    FEAT_CSS_APP_EDIT,
    FEAT_PDF_REPORT_GENERATE,
    FEAT_PDF_REPORT_LOG_VIEW,
    FEAT_PDF_REPORT_CREATE_CONFIGURATION,
    FEAT_PDF_REPORT_CLONE,
    FEAT_PDF_REPORT_DELETE,
    FEAT_PDF_REPORT_EDIT,
    FEAT_PDF_REPORT_DOWNLOAD_PDF,
    FEAT_BI_LINK,
    FEAT_SERVICE_TOKEN_CREATE,
    FEAT_SERVICE_TOKEN_DELETE,
    FEAT_IMAGE_CREATE,
    FEAT_IMAGE_DELETE,
  ],
}

// Admin role (clone of the super user role)
roles_catalog[ROLE_ADMIN] = roles_catalog[ROLE_SUPER_USER]

// User mockup generator role
roles_catalog[ROLE_UTILITY_MOCKUP_USER] = {
  routes: [
    routes.HOME_PAGE_ROUTE,
    routes.REPORTS_PAGE_ROUTE,
    routes.ADD_REPORT_ROUTE,
    routes.EDIT_REPORT_ROUTE,
    routes.LOGIN_PAGE_ROUTE,
    routes.NOT_FOUND_PAGE_ROUTE,
    routes.APPLICATIONS_PAGE_ROUTE,
    routes.ADD_APPLICATION_ROUTE,
    routes.EDIT_APPLICATION_ROUTE,
    routes.PREVIEW_APPLICATION_PAGE_ROUTE,
    routes.MANAGE_IMAGES_PAGE_ROUTE,
    routes.CUSTOMIZE_LAYOUT_PAGE_ROUTE,
    routes.PREVIEW_REPORT_PAGE_ROUTE,
    routes.PREVIEW_VERSION_REPORT_PAGE_ROUTE,
    routes.CREATE_CONFIGURATION_REPORT_PAGE_ROUTE,
    routes.EDIT_CONFIGURATION_REPORT_PAGE_ROUTE,
    routes.VIEW_ALL_GENERATIONS_CONFIGURATION_REPORT_PAGE_ROUTE,
    routes.GENERATE_ACCOUNT_TEXT,
  ],
  features: [
    FEAT_REPORT_CREATE,
    FEAT_REPORT_EDIT,
    FEAT_REPORT_PREVIEW,
    FEAT_REPORT_CLONE,
    FEAT_REPORT_DOWNLOAD,
    FEAT_REPORT_UPLOAD_TRANSLATIONS,
    FEAT_REPORT_DOWNLOAD_TRANSLATIONS,
    FEAT_APP_CREATE,
    FEAT_APP_EDIT,
    FEAT_APP_PREVIEW,
    FEAT_IMAGE_CREATE,
    FEAT_IMAGE_DELETE,
  ],
}

// User mockup visualizer role
roles_catalog[ROLE_MOCKUP_VIEWER_USER] = {
  routes: [
    routes.HOME_PAGE_ROUTE,
    routes.REPORTS_PAGE_ROUTE,
    routes.LOGIN_PAGE_ROUTE,
    routes.NOT_FOUND_PAGE_ROUTE,
    routes.APPLICATIONS_PAGE_ROUTE,
    routes.MANAGE_IMAGES_PAGE_ROUTE,
    routes.PREVIEW_REPORT_PAGE_ROUTE,
    routes.PREVIEW_VERSION_REPORT_PAGE_ROUTE,
    routes.PREVIEW_APPLICATION_PAGE_ROUTE,
    routes.GENERATE_ACCOUNT_TEXT,
  ],
  features: [FEAT_REPORT_PREVIEW, FEAT_REPORT_DOWNLOAD, FEAT_APP_PREVIEW],
}

// Business Intelligence role
roles_catalog[ROLE_BI_USER] = {
  routes: [routes.HOME_PAGE_ROUTE, routes.LOGIN_PAGE_ROUTE, routes.NOT_FOUND_PAGE_ROUTE],
  features: [FEAT_BI_LINK],
}

export default roles_catalog
