import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Paper, Breadcrumbs, Typography } from '@material-ui/core'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { AppState } from '../../reducers'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
  },
  linkBreadcrumb: {
    color: 'rgba(0, 0, 0, 0.54)',
    textDecoration: 'none',
  },
}))

interface Props {
  currentPageName: string
  links?: any[]
}

const CustomBreadcrumbs = (props: Props) => {
  const classes = useStyles({})
  const { currentPageName, links = [] } = props

  /**
   * Redux Hooks
   */
  const {
    intl: { locale },
  } = useSelector((state: AppState) => state)

  const getLinksComponent = (links: any) => {
    return links.map((link: any, i: number) => {
      return (
        <Link key={i} className={classes.linkBreadcrumb} to={`${link.path}?lang=${locale}`}>
          {link.message}
        </Link>
      )
    })
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
        {getLinksComponent(links)}
        <Typography color="textPrimary">{currentPageName}</Typography>
      </Breadcrumbs>
    </Paper>
  )
}

export { CustomBreadcrumbs }
