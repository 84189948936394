import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  FormHelperText,
  Box,
  Tooltip,
  Switch,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import EditIcon from '@material-ui/icons/Edit'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import LanguageIcon from '@material-ui/icons/Language'

import { ReportType } from '../../types/guillotina'
import { messages } from './messages'
import { DialogGenericAction, DialogSetTranslationsApplicationItemMenu } from '../../components'
import { useIntl } from 'react-intl'

export type DialogTranslateType = { open: boolean; data: any; itemToTranslate: string }
const useStyles = makeStyles((theme) => ({
  rootList: () => ({
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  }),
  rootLi: () => ({
    borderBottom: '1px solid #ccc',
  }),
  listInLi: () => ({
    display: 'flex',
    minWidth: '70%',
  }),
  nested: (props: any) => ({
    paddingLeft: theme.spacing(props.level * 5),
  }),
  textLi: () => ({
    paddingLeft: theme.spacing(1),
  }),
  formControl: () => ({
    margin: theme.spacing(1),
    minWidth: 120,
  }),
  textField: () => ({
    margin: 0,
  }),
  cursorPointer: () => ({
    cursor: 'pointer',
  }),
  selectWidth: () => ({
    width: 150,
  }),
}))

type Props = {
  data: MenuItemJsonSchema
  reports: ReportType[]
  isParent?: boolean
  addChildrenMenuItem: (id: any) => void
  updateMenuItemInfo: (id: any, data: MenuItemInfo) => void
  deleteMenuItem: (id: any, parentId: any) => void
}

const ApplicationMenuItem = (props: Props) => {
  const {
    data,
    reports = [],
    isParent = false,
    addChildrenMenuItem,
    updateMenuItemInfo,
    deleteMenuItem,
  } = props

  const classes = useStyles({ level: data.level })

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * React Hooks
   */
  const [edit_mode, setEditMode] = useState<boolean>(!!!data.name)
  const [is_report_link_mode, setIsReportLinkMode] = useState<boolean>(data.isReportLinkMode)
  const [render_header, setRenderHeader] = useState<boolean>(data.renderHeader)
  const [render_sidebar, setRenderSidebar] = useState<boolean>(data.renderSidebar)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [report_id, setReportId] = useState(data.reportId)
  const [external_link, setExternalLink] = useState<string>(data.externalLink)
  const [menu_item_name, setMenuItemName] = useState<string>(data.name)
  const [name_translations, setNameTranslations] = useState<any[]>(data.nameTranslations)
  const [delete_dialog_data, setDeleteDialogData] = useState<DialogState>({
    open: false,
    itemName: '',
    itemTitle: '',
  })

  const [translations_dialog_data, setTranslationsDialogData] = useState<DialogTranslateType>({
    open: false,
    data: null,
    itemToTranslate: '',
  })

  useEffect(() => {
    if (!edit_mode) {
      updateMenuItemInfo(data.id, {
        name: menu_item_name,
        reportId: report_id,
        isReportLinkMode: is_report_link_mode,
        externalLink: external_link,
        nameTranslations: name_translations,
        renderHeader: render_header,
        renderSidebar: render_sidebar,
      })
    }
  }, [
    edit_mode,
    data.id,
    updateMenuItemInfo,
    menu_item_name,
    report_id,
    is_report_link_mode,
    external_link,
    name_translations,
    render_header,
    render_sidebar,
  ])

  /**
   * Component functions
   */
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target as HTMLInputElement
    setReportId(value)
  }

  const getAttributesListComoponent = () => {
    if (isParent) {
      return {
        className: classes.rootList,
        disablePadding: true,
      }
    } else {
      return {
        component: 'div',
        disablePadding: true,
      }
    }
  }

  const getAttributesListItemComponent = () => {
    if (!isParent) {
      return { className: classes.nested + ' ' + classes.rootLi }
    }
    return { className: classes.rootLi }
  }

  const addChild = () => {
    setSubmitted(true)
    if (menu_item_name && ((is_report_link_mode && report_id) || external_link)) {
      updateMenuItemInfo(data.id, {
        name: menu_item_name,
        reportId: report_id,
        isReportLinkMode: is_report_link_mode,
        externalLink: external_link,
        nameTranslations: name_translations,
        renderHeader: render_header,
        renderSidebar: render_sidebar,
      })
      setEditMode(false)
      addChildrenMenuItem(data.id)
    }
  }

  const handleCloseTranslationModal = () => {
    setTranslationsDialogData((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const handleCloseModal = () => {
    setDeleteDialogData((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  /**
   * Render
   */

  const renderInputLinkMode = () => {
    if (is_report_link_mode) {
      return (
        <FormControl className={classes.formControl} error={submitted && !report_id}>
          <InputLabel>{formatMessage(messages.reportTitle)}</InputLabel>
          <Select value={report_id} onChange={handleChange} className={classes.selectWidth}>
            {reports.map((report: ReportType) => {
              return (
                <MenuItem key={report.id} value={report.id}>
                  {report.id}
                </MenuItem>
              )
            })}
          </Select>
          {submitted && !report_id ? (
            <FormHelperText>{formatMessage(messages.reportError)}</FormHelperText>
          ) : null}
        </FormControl>
      )
    } else {
      return (
        <FormControl className={classes.formControl} error={submitted && !report_id}>
          <TextField
            label={formatMessage(messages.externalLink)}
            className={classes.textField}
            error={submitted && !external_link}
            helperText={
              submitted && !external_link ? formatMessage(messages.externalLinkError) : ''
            }
            margin="normal"
            value={external_link}
            onChange={(e: React.ChangeEvent) => {
              const { value } = e.target as HTMLInputElement
              setExternalLink(value)
            }}
          />
        </FormControl>
      )
    }
  }

  const renderEditMode = () => {
    return (
      <List className={classes.listInLi}>
        <ListItem disableGutters>
          <FormControl className={classes.formControl}>
            <TextField
              label={formatMessage(messages.labelItemMenuTitle)}
              className={classes.textField}
              error={submitted && !menu_item_name}
              helperText={
                submitted && !menu_item_name ? formatMessage(messages.menuItemNameError) : ''
              }
              margin="normal"
              value={menu_item_name}
              onChange={(e: React.ChangeEvent) => {
                const { value } = e.target as HTMLInputElement
                setMenuItemName(value)
              }}
            />
          </FormControl>
          <Box mt={2} className={classes.cursorPointer}>
            <Tooltip title={formatMessage(messages.setTranslations)}>
              <IconButton
                edge="end"
                onClick={() => {
                  setSubmitted(true)
                  if (menu_item_name) {
                    setTranslationsDialogData({
                      open: true,
                      itemToTranslate: menu_item_name,
                      data: name_translations,
                    })
                  }
                }}
              >
                <LanguageIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </ListItem>

        <ListItem disableGutters>
          {renderInputLinkMode()}
          <Box mt={2} className={classes.cursorPointer}>
            <Tooltip
              title={formatMessage(
                is_report_link_mode
                  ? messages.changeLinkTypeToExternalLink
                  : messages.changeLinkTypeToReport
              )}
            >
              <IconButton
                edge="end"
                onClick={() => {
                  setIsReportLinkMode(!is_report_link_mode)
                }}
              >
                <SwapHorizIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </ListItem>
        {is_report_link_mode ? (
          <ListItem disableGutters>
            <Box ml={2} mt={2}>
              <Tooltip
                title={formatMessage(
                  render_header ? messages.renderHeader : messages.noRenderHeader
                )}
                aria-label="render-header"
              >
                <Switch checked={render_header} onChange={() => setRenderHeader(!render_header)} />
              </Tooltip>
            </Box>
          </ListItem>
        ) : null}
        {is_report_link_mode ? (
          <ListItem disableGutters>
            <Box ml={2} mt={2}>
              <Tooltip
                title={formatMessage(
                  render_sidebar ? messages.renderSidebar : messages.noRenderSidebar
                )}
                aria-label="render-sidebar"
              >
                <Switch
                  checked={render_sidebar}
                  onChange={() => setRenderSidebar(!render_sidebar)}
                />
              </Tooltip>
            </Box>
          </ListItem>
        ) : null}
      </List>
    )
  }

  const renderInfoMode = () => {
    return (
      <List className={classes.listInLi}>
        <ListItem disableGutters className={classes.textLi}>
          <ListItemText primary={menu_item_name} />
        </ListItem>
        <ListItem disableGutters className={classes.textLi}>
          <ArrowRightAltIcon />
        </ListItem>
        <ListItem disableGutters className={classes.textLi}>
          {is_report_link_mode ? (
            <ListItemText primary={report_id} />
          ) : (
            <ListItemText primary={external_link} />
          )}
        </ListItem>
        {is_report_link_mode ? (
          <>
            <ListItem disableGutters className={classes.textLi}>
              <ArrowRightAltIcon />
            </ListItem>
            <ListItem disableGutters className={classes.textLi}>
              <ListItemText
                primary={formatMessage(
                  render_header ? messages.renderHeader : messages.noRenderHeader
                )}
              />
            </ListItem>
          </>
        ) : null}
        {is_report_link_mode ? (
          <>
            <ListItem disableGutters className={classes.textLi}>
              <ArrowRightAltIcon />
            </ListItem>
            <ListItem disableGutters className={classes.textLi}>
              <ListItemText
                primary={formatMessage(
                  render_sidebar ? messages.renderSidebar : messages.noRenderSidebar
                )}
              />
            </ListItem>
          </>
        ) : null}
      </List>
    )
  }

  const renderChildren = () => {
    if (data.children.length) {
      return (
        <>
          {data.children.map((menuItem: MenuItemJsonSchema) => (
            <ApplicationMenuItem
              key={menuItem.id}
              data={menuItem}
              reports={reports}
              addChildrenMenuItem={addChildrenMenuItem}
              updateMenuItemInfo={updateMenuItemInfo}
              deleteMenuItem={deleteMenuItem}
            />
          ))}
        </>
      )
    }
  }

  return (
    <>
      <List {...getAttributesListComoponent()}>
        <ListItem {...getAttributesListItemComponent()}>
          <ListItemAvatar>
            <Avatar>
              <MenuIcon />
            </Avatar>
          </ListItemAvatar>

          {edit_mode ? renderEditMode() : renderInfoMode()}

          <ListItemSecondaryAction>
            <IconButton
              onClick={() => {
                setSubmitted(true)
                if (menu_item_name && ((is_report_link_mode && report_id) || external_link)) {
                  setEditMode(!edit_mode)
                }
              }}
              edge="end"
              aria-label="Edit name"
            >
              {edit_mode ? <CheckIcon /> : <EditIcon />}
            </IconButton>
            <IconButton edge="end" aria-label="Add child" onClick={() => addChild()}>
              <AddIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="Delete"
              onClick={() => {
                setDeleteDialogData({ open: true, itemName: '', itemTitle: '' })
              }}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        {renderChildren()}
      </List>
      <DialogGenericAction
        data={delete_dialog_data}
        handleCloseModal={handleCloseModal}
        action={() => {
          deleteMenuItem(data.id, data.parentId)
        }}
        messages={{
          title: formatMessage(messages.deleteMenuItemDialogTitle),
          cancel: formatMessage(messages.cancel),
          actionText: formatMessage(messages.delete),
        }}
      />

      <DialogSetTranslationsApplicationItemMenu
        action={(data: any) => setNameTranslations(data)}
        dialogData={translations_dialog_data}
        handleCloseDialog={handleCloseTranslationModal}
      />
    </>
  )
}

export { ApplicationMenuItem }
