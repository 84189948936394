export enum ContentFormat {
  RAW = 1,
  OBJECT_URL,
  DATA_URL,
}

export const getFile = (url: string, token: string) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          reject(response)
        }

        response.blob().then((data) => {
          const objectURL = URL.createObjectURL(data)
          resolve(objectURL)
        })
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getFileCss = (
  url: string,
  token: string,
  format: ContentFormat = ContentFormat.OBJECT_URL
) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        Accept: 'text/plain',
        'Content-Type': 'text/css',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          reject(response)
        }

        response.text().then((data) => {
          const blobFile = new Blob([data], { type: 'text/css' })
          if (format === ContentFormat.OBJECT_URL) {
            const objectURL = URL.createObjectURL(blobFile)
            resolve(objectURL)
          } else if (format === ContentFormat.DATA_URL) {
            const reader = new FileReader()
            reader.readAsDataURL(blobFile)
            reader.onloadend = function () {
              resolve(reader.result as string)
            }
          } else if (format === ContentFormat.RAW) {
            resolve(data)
          }
        })
      })
      .catch((error) => {
        reject(error)
      })
  })
}
