/**
 * Content actions
 * @module actions/content/content
 */

import {
  CLONE_CONTENT,
  CREATE_CONTENT,
  DELETE_CONTENT,
  GET_CONTENT,
  GET_SERVICE_TOKENS,
  GET_TRANSLATIONS,
  GET_VERSIONS,
  RESET_CONTENT,
  UPDATE_CONTENT,
} from '../../constants/ActionTypes'

/**
 * Create content function.
 * @function createContent
 * @param {string} url Parent url.
 * @param {Object|Array} content Content data.
 * @param {Object} headers Map of headers.
 * @returns {Object} Create content action.
 */
export function createContent(url: string, content: any, headers?: any) {
  return {
    type: CREATE_CONTENT,
    request: {
      op: 'post',
      path: url,
      data: content,
      headers: headers || {},
    },
  }
}

/**
 * Create content function.
 * @function createContent
 * @param {string} url Parent url.
 * @param {Object|Array} content Content data.
 * @param {Object} headers Map of headers.
 * @returns {Object} Create content action.
 */
export function cloneContent(url: string, content: any, headers?: any) {
  return {
    type: CLONE_CONTENT,
    request: {
      op: 'post',
      path: `${url}/@duplicate`,
      data: content,
      headers: headers || {},
    },
  }
}

/**
 * Create content function.
 * @function catalogReindex
 * @param {string} url Parent url.
 * @param {Object} headers Map of headers.
 * @returns {Object} Create content action.
 */
export function catalogReindex(url: string, headers?: any) {
  return {
    request: {
      op: 'post',
      path: `${url}/@catalog-reindex`,
      data: {},
      headers: headers || {},
    },
  }
}

/**
 * Delete content function.
 * @function deleteContent
 * @param {string|Array} urls Content url(s).
 * @returns {Object} Delete content action.
 */
export function deleteContent(urls: string | Array<string>) {
  return {
    type: DELETE_CONTENT,
    request:
      typeof urls === 'string'
        ? { op: 'del', path: urls }
        : urls.map((url) => ({ op: 'del', path: url })),
  }
}

/**
 * Get content function
 * @function getContent
 * @param {string} url Content url
 * @param {Object} headers Map of headers.
 * @returns {Object} Get content action
 */
export function getContent(url: string, headers?: any) {
  return {
    type: GET_CONTENT,
    request: {
      op: 'get',
      path: url,
      headers: headers || {},
    },
  }
}

/**
 * Get versions function
 * @function getVersions
 * @param {string} url Versions url
 * @returns {Object} Get versions action
 */
export function getVersions(url: string) {
  return {
    type: GET_VERSIONS,
    request: {
      op: 'get',
      path: `${url}/@versions`,
    },
  }
}

/**
 * Get translations function
 * @function getTranslations
 * @param {string} url Translations url
 * @returns {Object} Get translations action
 */
export function getTranslations(url: string) {
  return {
    type: GET_TRANSLATIONS,
    request: {
      op: 'get',
      path: `${url}/@getLanguagesTranslation`,
    },
  }
}

/**
 * Get service tokens function
 * @function getServiceTokens
 * @param {string} url Service tokens url
 * @returns {Object} Get service tokens action
 */
export function getServiceTokens(url: string) {
  return {
    type: GET_SERVICE_TOKENS,
    request: {
      op: 'get',
      path: `${url}/@servicetokens`,
    },
  }
}

/**
 * Update content function.
 * @function updateContent
 * @param {string} urls Content url(s).
 * @param {Object} content Content data.
 * @param {Object} headers Map of headers.
 * @returns {Object} Update content action.
 */
export function updateContent(urls: string, content: any, headers?: any) {
  return {
    type: UPDATE_CONTENT,
    request: {
      op: 'patch',
      path: urls,
      data: content,
      headers: headers || {},
    },
  }
}

/**
 * Reset content function
 * @function resetContent
 * @returns {Object} Get content action
 */
export function resetContent() {
  return {
    type: RESET_CONTENT,
  }
}
