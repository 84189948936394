import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { RichText } from '../../components/Fields/RichText'
import { useEffect, useState } from 'react'
import { CustomBreadcrumbs, SnackbarWrapper } from '../../components'
import { useIntl, defineMessages } from 'react-intl'
import routes, { HOME_PAGE_ROUTE } from '../../constants/Routes'
import { useGuillotina } from 'sharedUtils'
import { AppState } from '../../reducers'
import { useDispatch, useSelector } from 'react-redux'
import { DetailAccount } from '../../types/guillotina'
import { getAccountInfo, resetContent, updateContent } from '../../actions'

const useStyles = makeStyles((theme) => ({
  containerForm: {
    margin: theme.spacing(2),
  },
  submitButtonForm: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
}))

export const GenerateAccountText = () => {
  const {
    userSession: { token, current_account_selected },
    content: { update },
  } = useSelector((state: AppState) => state)
  const path = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_DB_API}/${current_account_selected}`

  const {
    data: account = { account_rich_text: '' },
    isLoading,
    refresh,
  } = useGuillotina<DetailAccount>({
    path: path,
    token: token,
  })
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    type: 'error',
    message: '',
    open: false,
  })

  const [val, setVal] = useState('')

  useEffect(() => {
    if (account) setVal(account?.account_rich_text)
  }, [account])

  const classes = useStyles({})
  const setValHandler = (value) => {
    setVal(value)
  }
  const intl = useIntl()
  const { formatMessage } = intl

  const messages = defineMessages({
    home: {
      id: 'home',
      defaultMessage: 'Home',
    },
    generateAccountText: {
      id: 'generate-account-text',
      defaultMessage: 'Generate Account Text',
    },
    btnSaveText: {
      id: 'btn-save-text',
      defaultMessage: 'Save Rich Text',
    },
  })

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
    ]

    return (
      <CustomBreadcrumbs
        links={links}
        currentPageName={formatMessage(messages.generateAccountText)}
      />
    )
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (update.loaded && !update.loading && !update.error && account && account['@name']) {
      refresh()
      dispatch(getAccountInfo(`db/${current_account_selected}/`))
      setAlertInfo({
        open: true,
        type: 'success',
        message: 'Submit Successful',
      })
      dispatch(resetContent())
    } else if (update.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: `Error: ${update.error}`,
      })
    }
  }, [update, account])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    dispatch(
      updateContent(`db/${current_account_selected}/`, { ...account, account_rich_text: val })
    )
  }
  const handleClose = () => {
    setAlertInfo((prevState) => {
      return { ...prevState, open: false }
    })
  }

  return (
    <Grid className={classes.containerForm}>
      {renderBreadcrumbs()}
      {!isLoading ? (
        <form onSubmit={handleSubmit}>
          <RichText val={val} setValue={setValHandler} disabled={!!update.loading} />
          <Grid className={classes.submitButtonForm}>
            {!update.loading ? (
              <Button type="submit" variant="contained" color="primary">
                {formatMessage(messages.btnSaveText)}
              </Button>
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <SnackbarWrapper alertInfo={alertInfo} handleCloseSnackbar={handleClose} />
        </form>
      ) : (
        <CircularProgress />
      )}
    </Grid>
  )
}
