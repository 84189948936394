export const findItemNested = (arr: any, itemId: any) =>
  /**
   * El primer paràmetre de la funció reduce, és resultat retornat en l'execució
   * de la funció del segon element. Sino retorna el valor per defecte ( null )
   */
  arr.reduce((resultItem: MenuItemJsonSchema, item: MenuItemJsonSchema) => {
    if (resultItem) return resultItem
    if (item.id === itemId) return item
    if (item.children) return findItemNested(item.children, itemId)
    return null
  }, null)

export const findItemEmpty = (arr: any) =>
  /**
   * El primer paràmetre de la funció reduce, és resultat retornat en l'execució
   * de la funció del segon element. Sino retorna el valor per defecte ( null )
   */
  arr.reduce((resultItem: MenuItemJsonSchema, item: MenuItemJsonSchema) => {
    if (resultItem) return resultItem
    if (item.name === '' || (item.reportId === '' && item.externalLink === '')) return item
    if (item.children) return findItemEmpty(item.children)
    return null
  }, null)

export const intersection = (a: any[], b: any[]) => {
  return a.filter((value) => b.indexOf(value) !== -1)
}

export const not = (a: any[], b: any[]) => {
  return a.filter((value) => b.indexOf(value) === -1)
}
