import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import caMessages from '../../locales/ca.json'
import esMessages from '../../locales/es.json'
import enMessages from '../../locales/en.json'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { IconButton, makeStyles, createStyles } from '@material-ui/core'
import Language from '@material-ui/icons/Language'
import { AppState } from '../../reducers/index.js'
import { push } from 'connected-react-router'

const useStyles = makeStyles(() =>
  createStyles({
    iconLanguage: {
      fill: '#fff',
    },
  })
)

const SwitchLocale = () => {
  const classes = useStyles({})

  const locales: { [id: string]: any } = {
    en: enMessages,
    es: esMessages,
    ca: caMessages,
  }
  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    router: { location },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  /**
   * Component functions
   */

  const handleClickItem = (event: React.SyntheticEvent) => {
    setAnchorEl(null)
    const { dataset } = event.target as HTMLInputElement
    const value: string = dataset.value || 'en'
    dispatch(
      push({
        pathname: location.pathname,
        search: `?lang=${value}`,
      })
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  /**
   * Render
   */

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-controls="long-menu"
        aria-haspopup="true"
        id="btn-change-language"
        onClick={handleClick}
      >
        <Language className={classes.iconLanguage} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(locales).map((locale) => (
          <MenuItem
            id={`item-select-${locale}`}
            key={locale}
            data-value={locale}
            onClick={handleClickItem}
          >
            {locale}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export { SwitchLocale }
