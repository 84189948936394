import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { push } from 'connected-react-router'
import {
  Container,
  TableRow,
  TableCell,
  Typography,
  Button,
  Paper,
  Table,
  TableHead,
  TableBody,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AppState } from '../../reducers'
import routes, {
  HOME_PAGE_ROUTE,
  CREATE_CONFIGURATION_REPORT_PAGE_ROUTE,
  EDIT_CONFIGURATION_REPORT_PAGE_ROUTE,
  NOT_FOUND_PAGE_ROUTE,
  VIEW_ALL_GENERATIONS_CONFIGURATION_REPORT_PAGE_ROUTE,
} from '../../constants/Routes'
import { FEAT_PDF_REPORT_CREATE_CONFIGURATION } from '../../constants/Roles'
import {
  CustomBreadcrumbs,
  ClipLoaderSpinner,
  SnackbarWrapper,
  DialogGenericAction,
  DialogCloneAction,
} from '../../components'
import { messages } from './messages'
import {
  getGenerateReports,
  deleteContent,
  createContent,
  resetContent,
  makePDFs,
  updateContent,
} from '../../actions'
import {
  getErrorMessage,
  useIsMount,
  userHasFeaturePermission,
  getFormattedObjectId,
} from '../../helpers'
import { SetPdfType, PdfGenerationType } from '../../types/guillotina'
import { RowGenerateReports } from './RowGenerateReport'
import { config } from '../../config'
import { SORT_DESC, SORT_ASC } from '../../constants/search'
import { makeHTMLs } from '../../actions/currentAccount/currentAccount'
import { SET_PDF } from '../../constants/FilesTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    maxWidth: 1200,
  },
  btnCreateConfig: {
    marginTop: theme.spacing(4),
  },
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    fill: '#000',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}))

export const GenerateReportsPage = () => {
  const classes = useStyles({})
  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Custom hooks
   */
  const isMount = useIsMount()

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account, permissions, token },
    currentAccount: {
      generate_reports,
      get_generate_reports,
      total_generate_reports,
      load_more_generate_reports,
    },
    intl: { locale },
    content,
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */
  const [generations_list, setGenerationsList] = useState<PdfGenerationType[]>([])
  const [is_all_loaded, setIsAllLoaded] = useState<boolean>(false)
  const [sort_obj, setSortObj] = useState<{
    field: string
    type: string
  } | null>(null)
  const [page, setPage] = useState<number | null>(null)

  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })

  const [delete_dialog_data, setDeleteDialogData] = useState<DialogState>({
    open: false,
    itemName: '',
    itemTitle: '',
  })

  const [clone_dialog_data, setCloneDialogData] = useState<DialogState>({
    open: false,
    itemName: '',
    itemTitle: '',
  })

  useEffect(() => {
    setTimeout(() => {
      window.location.reload()
    }, 30000)
    return () => {
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    if (basic_info_account !== null) {
      dispatch(getGenerateReports(basic_info_account.url, true))
    }
    return () => {
      dispatch(resetContent())
    }
  }, [basic_info_account, dispatch])

  useEffect(() => {
    if (basic_info_account !== null && sort_obj) {
      dispatch(getGenerateReports(basic_info_account.url, true, sort_obj, 0))
    }
  }, [basic_info_account, dispatch, sort_obj])

  useEffect(() => {
    if (basic_info_account !== null && page) {
      dispatch(
        getGenerateReports(
          basic_info_account.url,
          true,
          sort_obj ? sort_obj : { field: 'id', type: SORT_DESC },
          page,
          true
        )
      )
    }
  }, [basic_info_account, dispatch, page, sort_obj])

  useEffect(() => {
    if (
      basic_info_account &&
      generate_reports &&
      !get_generate_reports.loading &&
      !get_generate_reports.error &&
      get_generate_reports.loaded
    ) {
      if (generate_reports.length === 0) {
        setIsAllLoaded(true)
      }

      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      }

      const urls: string[] = []
      generate_reports.forEach((set_pdf: SetPdfType) => {
        let last_object: any = null
        set_pdf.items.forEach((item: any) => {
          if (!last_object || item['@name'] > last_object['@name']) {
            last_object = item
          }
        })
        if (last_object) {
          urls.push(
            `${config.apiUrl}/${basic_info_account.url}/${SET_PDF}/${set_pdf['@name']}/${last_object['@name']}`
          )
        }
      })

      const promises = urls.map((url) => fetch(url, { headers }))
      Promise.all(promises)
        .then((result) => Promise.all(result.map((v) => v.json())))
        .then((results) => {
          setIsAllLoaded(true)
          setGenerationsList(results)
        })
        .catch((err) => {
          setIsAllLoaded(true)
          setAlertInfo({
            open: true,
            type: 'error',
            message: err.toString(),
          })
        })
    }
  }, [basic_info_account, generate_reports, get_generate_reports, token])

  useEffect(() => {
    if (isMount) {
      if (content.create.loaded && !content.create.loading && !content.create.error) {
        setAlertInfo({
          open: true,
          type: 'success',
          message: formatMessage(messages.cloneConfigGenerateReportSuccessful),
        })
        dispatch(resetContent())
      } else if (content.delete.loaded && !content.delete.loading && !content.delete.error) {
        setAlertInfo({
          open: true,
          type: 'success',
          message: formatMessage(messages.deleteConfigGenerateReportSuccessful),
        })
      } else if (content.create.error) {
        setAlertInfo({
          open: true,
          type: 'error',
          message: getErrorMessage(content.create.error),
        })
      } else if (load_more_generate_reports.error) {
        setAlertInfo({
          open: true,
          type: 'error',
          message: getErrorMessage(content.update.error),
        })
      } else if (get_generate_reports.error) {
        setAlertInfo({
          open: true,
          type: 'error',
          message: getErrorMessage(get_generate_reports.error),
        })
      } else if (content.delete.error) {
        setAlertInfo({
          open: true,
          type: 'error',
          message: getErrorMessage(content.delete.error),
        })
      }
    }
  }, [get_generate_reports, content, formatMessage, dispatch, isMount, load_more_generate_reports])

  useEffect(() => {
    if (
      basic_info_account &&
      (content.delete.loaded || content.create.loaded || content.update.loaded)
    ) {
      dispatch(getGenerateReports(basic_info_account.url, true))
    }
  }, [dispatch, basic_info_account, content.delete, content.create, content.update])

  /**
   * Component functions
   */

  const actionDeleteApplication = () => {
    if (basic_info_account) {
      fetch(
        `${config.apiUrl}/${basic_info_account.url}/${SET_PDF}/${delete_dialog_data.itemName}/@deleteJob`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then(() => {
          dispatch(
            deleteContent(`${basic_info_account.url}/${SET_PDF}/${delete_dialog_data.itemName}`)
          )
        })
        .catch((error: any) => {
          setAlertInfo({
            open: true,
            type: 'error',
            message: error,
          })
        })
    }
  }

  const handleCloseSnackbar = () => {
    setAlertInfo((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const handleCloseModal = () => {
    setDeleteDialogData((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const handleCloseCloneDialog = () => {
    setCloneDialogData((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const actionCloneReport = (url: string, data: any, name_form: string) => {
    const bodyPost = {
      contract_ids: data.contract_ids,
      filters: data.filters,
      title: name_form,
      is_generated: false,
      id: getFormattedObjectId(name_form),
      '@type': 'SetPDF',
    }
    dispatch(createContent(`${url}/${SET_PDF}/`, bodyPost))
  }

  const getUrlToEditReport = (config_report: SetPdfType): string => {
    const routerObj = routes[EDIT_CONFIGURATION_REPORT_PAGE_ROUTE]
    if (routerObj.pathWithParam) {
      return routerObj.pathWithParam(config_report['@name'])
    }
    return routes[NOT_FOUND_PAGE_ROUTE].path
  }

  const getUrlViewAllGenerations = (config_report: SetPdfType): string => {
    const routerObj = routes[VIEW_ALL_GENERATIONS_CONFIGURATION_REPORT_PAGE_ROUTE]
    if (routerObj.pathWithParam) {
      return routerObj.pathWithParam(config_report['@name'])
    }
    return routes[NOT_FOUND_PAGE_ROUTE].path
  }

  const makePdfsAction = (config_name: string, buildPdf: boolean, buildHtml: boolean) => {
    if (basic_info_account) {
      if (buildPdf) {
        dispatch(makePDFs(`${basic_info_account.url}/${SET_PDF}/${config_name}`))
      }
      if (buildHtml) {
        dispatch(makeHTMLs(`${basic_info_account.url}/${SET_PDF}/${config_name}`))
      }

      dispatch(
        updateContent(`${basic_info_account.url}/${SET_PDF}/${config_name}`, {
          is_generated: true,
        })
      )
    }
  }

  const isLoadingData = () => {
    return get_generate_reports.loading || !is_all_loaded || load_more_generate_reports.loading
  }

  /**
   * Render
   */

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
    ]

    return (
      <CustomBreadcrumbs links={links} currentPageName={formatMessage(messages.generateReports)} />
    )
  }

  const renderSpinner = () => {
    if (isLoadingData()) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <ClipLoaderSpinner loading={isLoadingData()} />
          </TableCell>
        </TableRow>
      )
    }
  }

  const renderCellNoResults = () => {
    if (get_generate_reports.loaded && generate_reports.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <div>{formatMessage(messages.noResultsGenerateReports)}</div>
          </TableCell>
        </TableRow>
      )
    }
  }

  const renderResults = () => {
    if (!get_generate_reports.loading && is_all_loaded) {
      return generate_reports.map((config_report: SetPdfType) => (
        <RowGenerateReports
          key={config_report['@name']}
          configReport={config_report}
          getUrlToEditReport={getUrlToEditReport}
          getUrlViewAllGenerations={getUrlViewAllGenerations}
          setCloneDialogData={setCloneDialogData}
          setDeleteDialogData={setDeleteDialogData}
          makePdfs={makePdfsAction}
          generations_list={generations_list}
        />
      ))
    }
  }

  return (
    <Container>
      {renderBreadcrumbs()}

      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleCloseSnackbar} />

      <Typography variant="h4">{formatMessage(messages.generateReportsPageTitle)}</Typography>

      {userHasFeaturePermission(FEAT_PDF_REPORT_CREATE_CONFIGURATION, permissions) && (
        <Button
          variant="contained"
          color="primary"
          className={classes.btnCreateConfig}
          onClick={() => {
            dispatch(push(`${routes[CREATE_CONFIGURATION_REPORT_PAGE_ROUTE].path}?lang=${locale}`))
          }}
        >
          {formatMessage(messages.btnCreateReportConfig)}
        </Button>
      )}

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.cursorPointer}
                onClick={() => {
                  setPage(null)
                  if (sort_obj && sort_obj.type === SORT_ASC) {
                    setSortObj({
                      field: 'id',
                      type: SORT_DESC,
                    })
                  } else {
                    setSortObj({
                      field: 'id',
                      type: SORT_ASC,
                    })
                  }
                }}
              >
                {formatMessage(messages.name)}
              </TableCell>
              <TableCell
                className={classes.cursorPointer}
                onClick={() => {
                  setPage(null)
                  if (sort_obj && sort_obj.type === SORT_ASC) {
                    setSortObj({
                      field: 'modification_date',
                      type: SORT_DESC,
                    })
                  } else {
                    setSortObj({
                      field: 'modification_date',
                      type: SORT_ASC,
                    })
                  }
                }}
              >
                {formatMessage(messages.lastModificationDate)}
              </TableCell>
              <TableCell align="right">{formatMessage(messages.actions)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderCellNoResults()}
            {renderResults()}
            {renderSpinner()}
          </TableBody>
        </Table>

        {!isLoadingData() && generate_reports.length < total_generate_reports ? (
          <Box mt={3} mb={3} display="flex" width="100%" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setPage(page ? page + 1 : 1)
              }}
            >
              {formatMessage(messages.loadMore)}
            </Button>
          </Box>
        ) : null}
      </Paper>

      <DialogGenericAction
        data={delete_dialog_data}
        handleCloseModal={handleCloseModal}
        action={actionDeleteApplication}
        messages={{
          title: formatMessage(messages.deleteConfigGenerateReportDialogTitle, {
            name: delete_dialog_data.itemTitle,
          }),
          cancel: formatMessage(messages.cancel),
          actionText: formatMessage(messages.delete),
        }}
      />

      <DialogCloneAction
        cloneDialogData={clone_dialog_data}
        handleCloseCloneDialog={handleCloseCloneDialog}
        actionCloneReport={actionCloneReport}
        currentAccount={basic_info_account}
      />
    </Container>
  )
}
