import React, { useState, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { defineMessages, useIntl } from 'react-intl'
import { push } from 'connected-react-router'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { MenuItem, Menu as MenuMaterial, Box } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import List from '@material-ui/core/List'
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined'
import ListIcon from '@material-ui/icons/List'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import BarChartIcon from '@material-ui/icons/BarChart'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import LanguageIcon from '@material-ui/icons/Language'
import ImageIcon from '@material-ui/icons/Image'
import TitleIcon from '@material-ui/icons/Title'

import { SwitchLocale } from '..'
import { AppState } from '../../reducers'
import routes, {
  REPORTS_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  HOME_PAGE_ROUTE,
  APPLICATIONS_PAGE_ROUTE,
  CHOOSE_PLUGINS_PAGE_ROUTE,
  CUSTOMIZE_LAYOUT_PAGE_ROUTE,
  GENERATE_REPORTS_PAGE_ROUTE,
  MANAGE_LANGUAGES_PAGE_ROUTE,
  CUSTOMIZE_APPLICATION_PAGE_ROUTE,
  MANAGE_IMAGES_PAGE_ROUTE,
  SERVICE_TOKENS_PAGE_ROUTE,
  CHANGE_ACCOUNT_REDIRECT,
  GENERATE_ACCOUNT_TEXT,
} from '../../constants/Routes'
import { FEAT_BI_LINK } from '../../constants/Roles'
import { SimpleAccount } from '../../types/guillotina'
import { userHasRoutePermission, userHasFeaturePermission } from '../../helpers'
import { config } from '../../config'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      marginLeft: drawerWidth,
      fontSize: '0.875rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
    },
    iconLanguage: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    anchorHome: {
      color: '#fff',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    anchorLink: {
      color: 'rgba(0, 0, 0, 0.87) !important',
      textDecoration: 'none',
    },
  })
)

export const messages = defineMessages({
  logoutBtn: {
    id: 'Logout',
    defaultMessage: 'Logout',
  },
  users: {
    id: 'users',
    defaultMessage: 'Users',
  },
  manageReports: {
    id: 'manage-reports',
    defaultMessage: 'Manage reports',
  },
  manageApplications: {
    id: 'manage-applications',
    defaultMessage: 'Manage applications',
  },
  manageLanguages: {
    id: 'manage-languages',
    defaultMessage: 'Manage languages',
  },
  manageImages: {
    id: 'manage-images',
    defaultMessage: 'Manage images',
  },
  bussinesIntelligence: {
    id: 'bussines-intelligence',
    defaultMessage: 'Bussines intelligence',
  },
  choosePlugins: {
    id: 'choose-plugins',
    defaultMessage: 'Choose plugins',
  },
  customLayoutReports: {
    id: 'custom-layout-reports',
    defaultMessage: 'Custom layout reports',
  },
  customLayoutApplications: {
    id: 'custom-layout-applications',
    defaultMessage: 'Custom layout applications',
  },
  generateReports: {
    id: 'generate_reports',
    defaultMessage: 'Generate reports',
  },
  serviceTokens: {
    id: 'service-tokens',
    defaultMessage: 'Service tokens',
  },
  generateAccountText: {
    id: 'generate-text-account',
    defaultMessage: 'Generate Account Text',
  },
})

interface MenuProps {
  children: ReactNode
}

export const Menu = (props: MenuProps) => {
  const classes = useStyles({})
  const theme = useTheme()
  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { token, is_superuser, current_account_selected, user_id, permissions },
    accounts: { list_accounts },
    intl: { locale },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [menu_open, setMenuOpen] = useState(false)
  const [anchor_accounts, setAnchorAccounts] = useState<null | HTMLElement>(null)
  const [anchor_user, setAnchorUser] = useState<null | HTMLElement>(null)
  const open_menu_accounts = Boolean(anchor_accounts)
  const open_menu_user = Boolean(anchor_user)

  /**
   * Component functions
   */

  const handleMenuAccounts = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAccounts(event.currentTarget)
  }

  const handleCloseAccounts = () => {
    setAnchorAccounts(null)
  }

  const handleMenuUser = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUser(event.currentTarget)
  }

  const handleCloseUser = () => {
    setAnchorUser(null)
  }

  const handleDrawerToggle = () => {
    setMenuOpen(!menu_open)
  }

  const selectedAccountClick = (account_id: string) => {
    const routerObj = routes[CHANGE_ACCOUNT_REDIRECT]
    if (routerObj.pathWithParam) {
      dispatch(push(`${routerObj.pathWithParam(account_id)}?lang=${locale}`))
    }
  }

  /**
   * Render
   */

  const renderMenuItem = (
    route_id: string,
    title: any,
    tag_id: string,
    TagIcon: any,
    keep_menu_open = false
  ) => {
    if (userHasRoutePermission(route_id, permissions)) {
      return (
        <ListItem
          id={tag_id}
          button
          onClick={() => {
            setMenuOpen(keep_menu_open)
            dispatch(push(`${routes[route_id].path}?lang=${locale}`))
          }}
        >
          <ListItemIcon>
            <TagIcon />
          </ListItemIcon>
          <ListItemText primary={formatMessage(title)} />
        </ListItem>
      )
    }
  }

  const renderLinkMenuItem = (feature_id: string, title: any, tag_id: string, href: string) => {
    if (userHasFeaturePermission(feature_id, permissions)) {
      return (
        <a href={href} target="_blank" className={classes.anchorLink} rel="noopener noreferrer">
          <ListItem id={tag_id} button>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary={formatMessage(title)} />
          </ListItem>
        </a>
      )
    }
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        {renderMenuItem(
          REPORTS_PAGE_ROUTE,
          messages.manageReports,
          'menu-item-go-to-reports-list',
          InsertChartOutlinedIcon
        )}
        {renderMenuItem(
          APPLICATIONS_PAGE_ROUTE,
          messages.manageApplications,
          'menu-item-go-to-applications-list',
          MenuIcon
        )}
        {renderMenuItem(
          CHOOSE_PLUGINS_PAGE_ROUTE,
          messages.choosePlugins,
          'menu-item-go-to-choose-plugins',
          CompareArrowsIcon
        )}
        {renderMenuItem(
          CUSTOMIZE_LAYOUT_PAGE_ROUTE,
          messages.customLayoutReports,
          'menu-item-go-to-custom-layout-reports',
          ListIcon
        )}
        {renderMenuItem(
          CUSTOMIZE_APPLICATION_PAGE_ROUTE,
          messages.customLayoutApplications,
          'menu-item-go-to-custom-applications',
          ListIcon
        )}
        {renderMenuItem(
          MANAGE_LANGUAGES_PAGE_ROUTE,
          messages.manageLanguages,
          'menu-item-go-to-manage-languages',
          LanguageIcon
        )}
        {renderMenuItem(
          MANAGE_IMAGES_PAGE_ROUTE,
          messages.manageImages,
          'menu-item-go-to-manage-images',
          ImageIcon
        )}
        {renderMenuItem(
          GENERATE_REPORTS_PAGE_ROUTE,
          messages.generateReports,
          'menu-item-go-to-custom-layout-reports',
          CreateNewFolderIcon
        )}
        {renderMenuItem(
          SERVICE_TOKENS_PAGE_ROUTE,
          messages.serviceTokens,
          'menu-item-go-to-service-tokens-home',
          TitleIcon
        )}
        {renderMenuItem(
          GENERATE_ACCOUNT_TEXT,
          messages.generateAccountText,
          'menu-item-go-to-generate-account-text-home',
          TitleIcon
        )}
        {renderLinkMenuItem(
          FEAT_BI_LINK,
          messages.bussinesIntelligence,
          'menu-item-go-to-bussines-intelligence',
          `${config.businessIntelligenceUrl}/login?token=${token}`
        )}
      </List>
    </div>
  )

  const renderChooseAccount = () => {
    if (is_superuser) {
      return (
        <div>
          <IconButton
            aria-label="Choose current account"
            aria-controls="menu-appbar-accounts"
            aria-haspopup="true"
            onClick={handleMenuAccounts}
            color="inherit"
          >
            <SwapHorizIcon />
          </IconButton>
          <MenuMaterial
            id="menu-appbar-accounts"
            anchorEl={anchor_accounts}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open_menu_accounts}
            onClose={handleCloseAccounts}
          >
            {list_accounts.map((account: SimpleAccount) => {
              return (
                <MenuItem
                  onClick={() => {
                    selectedAccountClick(account.id)
                    handleCloseAccounts()
                  }}
                  key={account.id}
                >
                  {account.title}
                </MenuItem>
              )
            })}
          </MenuMaterial>
        </div>
      )
    }
  }

  const sideBarNav = (
    <nav>
      <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={menu_open}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  )

  return (
    <div>
      <AppBar position="fixed" className={token ? classes.appBar : ''} id="header-app">
        <Toolbar>
          {token && (
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              id="btn-open-menu"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Box
            onClick={() => dispatch(push(`${routes[HOME_PAGE_ROUTE].path}?lang=${locale}`))}
            className={classes.anchorHome}
          >
            <Typography variant="h6" noWrap>
              Bee Data {current_account_selected ? ` --- ${current_account_selected}` : ''}
            </Typography>
          </Box>

          <div className={classes.iconLanguage}>
            <SwitchLocale />
          </div>

          {renderChooseAccount()}

          {token && (
            <div>
              <span>{user_id}</span>
              <IconButton
                aria-label="Account of current user"
                aria-controls="menu-appbar-user"
                aria-haspopup="true"
                onClick={handleMenuUser}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <MenuMaterial
                id="menu-appbar-user"
                anchorEl={anchor_user}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open_menu_user}
                onClose={handleCloseUser}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorUser(null)
                    setMenuOpen(false)
                    dispatch(push(`${routes[LOGIN_PAGE_ROUTE].path}?lang=${locale}`))
                  }}
                >
                  {formatMessage(messages.logoutBtn)}
                </MenuItem>
              </MenuMaterial>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {token && sideBarNav}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  )
}
