import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import routes, { LOGIN_PAGE_ROUTE } from '../../constants/Routes'
import { AppState } from '../../reducers'

type PrivateRouteProps = {
  component: React.ComponentType<any>
}

const PrivateRoute = ({ component: Component, ...props }: PrivateRouteProps & RouteProps) => {
  /**
   * Redux Hooks
   */
  const {
    userSession: { token },
  } = useSelector((state: AppState) => state)

  /**
   * Render
   */

  return (
    <Route
      {...props}
      render={(props) =>
        token || localStorage.getItem('auth_token') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: routes[LOGIN_PAGE_ROUTE].path, state: { from: props.location } }}
          />
        )
      }
    />
  )
}
export { PrivateRoute }
