import { ReducerStatusAsync } from '../../types/reducer'
import { AnyAction } from 'redux'
import {
  GET_CONTENT,
  UPDATE_CONTENT,
  RESET_CONTENT,
  CREATE_CONTENT,
  DELETE_CONTENT,
  CLONE_CONTENT,
  GET_VERSIONS,
  GET_TRANSLATIONS,
  GET_SERVICE_TOKENS,
} from '../../constants/ActionTypes'
import { config } from '../../config'

export type ContentReducer = {
  data: any
  get: ReducerStatusAsync
  update: ReducerStatusAsync
  create: ReducerStatusAsync
  delete: ReducerStatusAsync
  clone: ReducerStatusAsync
}

const initialState = {
  get: {
    loaded: false,
    loading: false,
    error: null,
  },
  update: {
    loaded: false,
    loading: false,
    error: null,
  },
  create: {
    loaded: false,
    loading: false,
    error: null,
  },
  delete: {
    loaded: false,
    loading: false,
    error: null,
  },
  clone: {
    loaded: false,
    loading: false,
    error: null,
  },
  data: null,
}

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType: string) {
  return actionType.split('_')[0].toLowerCase()
}

/**
 * Content reducer.
 * @function content
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function content(
  state: ContentReducer = initialState,
  action: AnyAction
): ContentReducer {
  const { result } = action
  switch (action.type) {
    case `${CLONE_CONTENT}_PENDING`:
    case `${CREATE_CONTENT}_PENDING`:
    case `${DELETE_CONTENT}_PENDING`:
    case `${GET_CONTENT}_PENDING`:
    case `${GET_VERSIONS}_PENDING`:
    case `${GET_TRANSLATIONS}_PENDING`:
    case `${UPDATE_CONTENT}_PENDING`:
    case `${GET_SERVICE_TOKENS}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      }
    case `${CLONE_CONTENT}_SUCCESS`:
    case `${CREATE_CONTENT}_SUCCESS`:
    case `${GET_CONTENT}_SUCCESS`:
      return {
        ...initialState,
        data: {
          ...result,
          items:
            action.result &&
            action.result.items &&
            action.result.items.map((item: any) => ({
              ...item,
              url: item['@id'].replace(config.apiUrl, ''),
            })),
        },
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      }
    case `${GET_VERSIONS}_SUCCESS`:
    case `${GET_TRANSLATIONS}_SUCCESS`:
    case `${GET_SERVICE_TOKENS}_SUCCESS`:
      return {
        ...initialState,
        data: result,
        get: {
          loading: false,
          loaded: true,
          error: null,
        },
      }
    case `${DELETE_CONTENT}_SUCCESS`:
    case `${UPDATE_CONTENT}_SUCCESS`:
      return {
        ...initialState,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      }
    case `${CLONE_CONTENT}_FAIL`:
    case `${CREATE_CONTENT}_FAIL`:
    case `${DELETE_CONTENT}_FAIL`:
    case `${UPDATE_CONTENT}_FAIL`:
    case `${GET_CONTENT}_FAIL`:
    case `${GET_VERSIONS}_FAIL`:
    case `${GET_TRANSLATIONS}_FAIL`:
    case `${GET_SERVICE_TOKENS}_FAIL`:
      return {
        ...state,
        data: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      }
    case RESET_CONTENT:
      return initialState
    default:
      return state
  }
}
