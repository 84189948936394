import {
  Box,
  Button,
  Container,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import routes, { APPLICATIONS_PAGE_ROUTE, HOME_PAGE_ROUTE } from '../../constants/Routes'
import { AppState } from '../../reducers'

import { getContent, resetContent, updateContent } from '../../actions'
import { ClipLoaderSpinner, CustomBreadcrumbs, SnackbarWrapper } from '../../components'
import { WEB_APPLICATION } from '../../constants/FilesTypes'
import { findItemEmpty, findItemNested, getErrorMessage } from '../../helpers'
import { ApplicationManageMenuItem } from './ApplicationManageMenuItem'
import { InitMenuItemJsonSchema } from './InitMenuItemJsonSchema'
import { messages } from './messages'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
  },
  containerForm: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  inputFile: {
    display: 'none',
  },
  btnAddMenuItem: {
    marginTop: theme.spacing(2),
  },
}))

export const EditApplicationPage = (props: RouteComponentProps) => {
  const classes = useStyles({})
  const { match } = props

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account },
    currentAccount: { account },
    content: { data, update, get },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */
  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })
  const [data_menu, setDataMenu] = useState<MenuItemJsonSchema[]>([])
  const [data_header_menu, setDataHeaderMenu] = useState<MenuHeader>({
    logo: false,
    menu_positon: 'left',
    menu_in: 'title',
  })

  useEffect(() => {
    return () => {
      dispatch(resetContent())
    }
  }, [dispatch])

  /**
   * Get content data
   */

  useEffect(() => {
    if (basic_info_account && data === null && !get.loading && !get.error) {
      const matchParams: any = match.params
      dispatch(getContent(`${basic_info_account.url}/${WEB_APPLICATION}/${matchParams.name}`))
    }
  }, [basic_info_account, data, dispatch, match.params, get])

  useEffect(() => {
    if (data !== null) {
      setDataMenu(data.menus)
      if (data.menu_header) {
        setDataHeaderMenu(data.menu_header)
      }
    }
  }, [data])

  useEffect(() => {
    if (update.loaded && !update.loading && !update.error) {
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.updateApplicationSuccessful),
      })
    } else if (update.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(update.error),
      })
    } else if (get.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(get.error),
      })
    } else {
      setAlertInfo((prev_state) => {
        return { ...prev_state, open: false }
      })
    }
  }, [dispatch, update, get.error, formatMessage])

  /**
   * Component functions
   */

  const handleClose = () => {
    setAlertInfo((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const itemEmpty = findItemEmpty(data_menu)
    if (basic_info_account && !itemEmpty) {
      const bodyPost = { menus: data_menu, menu_header: data_header_menu }
      const matchParams: any = match.params
      dispatch(
        updateContent(`${basic_info_account.url}/${WEB_APPLICATION}/${matchParams.name}`, bodyPost)
      )
    } else {
      setAlertInfo({
        open: true,
        type: 'error',
        message: formatMessage(messages.errorMsgMenuNotFill),
      })
    }
  }

  const addMenuItem = () => {
    setDataMenu((prev_state: MenuItemJsonSchema[]) => [
      ...prev_state,
      InitMenuItemJsonSchema(account, 0),
    ])
  }

  const addChildrenMenuItem = (id: any) => {
    const item: MenuItemJsonSchema = findItemNested(data_menu, id)
    item.children = [...item.children, InitMenuItemJsonSchema(account, item.level + 1, id)]
    setDataMenu((prev_state: MenuItemJsonSchema[]) => Object.assign([], prev_state))
  }

  const deleteMenuItem = (id: any, parentId: any) => {
    if (parentId !== null) {
      const menuItem: MenuItemJsonSchema = findItemNested(data_menu, parentId)
      const index = menuItem.children.findIndex((item: MenuItemJsonSchema) => item.id === id)
      if (index !== -1) {
        menuItem.children.splice(index, 1)
      }
    } else {
      const index = data_menu.findIndex((item: MenuItemJsonSchema) => item.id === id)
      if (index !== -1) {
        data_menu.splice(index, 1)
      }
    }

    setDataMenu((prev_state: MenuItemJsonSchema[]) => Object.assign([], prev_state))
  }

  const updateMenuItemInfo = (id: any, data: MenuItemInfo) => {
    const item: MenuItemJsonSchema = findItemNested(data_menu, id)
    item.name = data.name
    item.reportId = data.reportId
    item.externalLink = data.externalLink
    item.isReportLinkMode = data.isReportLinkMode
    item.nameTranslations = data.nameTranslations
    item.renderHeader = data.renderHeader
    item.renderSidebar = data.renderSidebar
  }

  /**
   * Render
   */

  const renderSpinner = () => {
    if (get.loading) {
      return <ClipLoaderSpinner loading={get.loading} size={150} />
    }
  }

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
      {
        path: routes[APPLICATIONS_PAGE_ROUTE].path,
        message: formatMessage(messages.applications),
      },
    ]
    const pageName = formatMessage(messages.edit)
    return <CustomBreadcrumbs links={links} currentPageName={pageName} />
  }

  const renderContent = () => {
    if (!get.error && !get.loading && data !== null) {
      return (
        <>
          <Typography component="h1" variant="h4" align="center">
            {data ? data.title : ''}
          </Typography>

          <form noValidate onSubmit={handleSubmit} className={classes.form}>
            <Button type="submit" variant="contained" color="primary">
              {formatMessage(messages.btnSaveAppplication)}
            </Button>
          </form>
          <h4>Menu Position</h4>
          <Box mb={6} display={'flex'} alignItems={'center'} style={{ gap: '20px' }}>
            <FormControlLabel
              value="logo"
              control={
                <Switch
                  checked={data_header_menu.logo}
                  onChange={() => {
                    if (!data_header_menu.logo) {
                      setDataHeaderMenu({ ...data_header_menu, logo: !data_header_menu.logo })
                    } else {
                      setDataHeaderMenu({
                        ...data_header_menu,
                        logo: !data_header_menu.logo,
                        menu_in: 'title',
                      })
                    }
                  }}
                  color="primary"
                />
              }
              label="Logo:"
              labelPlacement="start"
            />
            <FormControlLabel
              value="menu_positon"
              control={
                <Select
                  style={{ marginLeft: '10px', minWidth: '70px' }}
                  value={data_header_menu.menu_positon ? data_header_menu.menu_positon : ''}
                  onChange={(event) => {
                    setDataHeaderMenu({
                      ...data_header_menu,
                      menu_positon: event.target.value as 'left' | 'right',
                    })
                  }}
                >
                  <MenuItem value={'left'}>Left</MenuItem>
                  <MenuItem value={'right'}>Right</MenuItem>
                </Select>
              }
              label="Menu Position:"
              labelPlacement="start"
            />
            {data_header_menu.logo && (
              <FormControlLabel
                value="menu_in"
                control={
                  <Select
                    style={{ marginLeft: '10px', minWidth: '70px' }}
                    value={data_header_menu.menu_in ? data_header_menu.menu_in : ''}
                    onChange={(event) => {
                      setDataHeaderMenu({
                        ...data_header_menu,
                        menu_in: event.target.value as 'logo' | 'title',
                      })
                    }}
                  >
                    <MenuItem value={'logo'}>Logo</MenuItem>
                    <MenuItem value={'title'}>Title</MenuItem>
                  </Select>
                }
                label="Menu Position:"
                labelPlacement="start"
              />
            )}
          </Box>

          <h4>Menu Items</h4>
          <ApplicationManageMenuItem
            data={data_menu}
            addChildrenMenuItem={addChildrenMenuItem}
            updateMenuItemInfo={updateMenuItemInfo}
            deleteMenuItem={deleteMenuItem}
          />

          <Button
            variant="contained"
            color="primary"
            className={classes.btnAddMenuItem}
            onClick={() => addMenuItem()}
          >
            {formatMessage(messages.btnAddMenuItem)}
          </Button>
        </>
      )
    }
  }

  return (
    <Container className={classes.containerForm}>
      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />

      {renderBreadcrumbs()}
      {renderSpinner()}
      {renderContent()}
    </Container>
  )
}
