import { combineReducers } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'

import userSession, { UserSessionReducer } from './userSession/userSession'

import { intlReducer, IntlState } from 'react-intl-redux'
import currentAccount, { CurrentAccountsReducer } from './currentAccount/currentAccount'
import content, { ContentReducer } from './content/content'
import accounts, { AccountsReducer } from './accounts/accounts'

export type AppState = {
  intl: IntlState
  userSession: UserSessionReducer
  currentAccount: CurrentAccountsReducer
  router: RouterState
  content: ContentReducer
  accounts: AccountsReducer
}

const createRootReducer = (history: History) =>
  combineReducers<AppState>({
    router: connectRouter(history),
    intl: intlReducer,
    userSession,
    currentAccount,
    content,
    accounts,
  })

export default createRootReducer
