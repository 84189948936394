import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core'

import { InfoDataBaseAccount } from '../../types/guillotina'

const messages = defineMessages({
  createVersionDialogTitle: {
    id: 'create-version-dialog-title',
    defaultMessage: 'Do you want to create version of "{name}"?',
  },
  createVersionDialogText: {
    id: 'create-version-dialog-text',
    defaultMessage: 'Enter name',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  nameError: {
    id: 'name-error',
    defaultMessage: 'Name is mandatory',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  create: {
    id: 'create',
    defaultMessage: 'Create',
  },
})

type FormData = {
  submitted: boolean
  version_name: string
}

type Props = {
  dialogData: DialogState
  basicInfoAccount: InfoDataBaseAccount | null
  handleCloseDialog: () => void
  actionForm: (url: string, nameForm: string) => void
}

export const DialogCreateVersionAction = (props: Props) => {
  const { dialogData, basicInfoAccount, actionForm, handleCloseDialog } = props

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * React Hooks
   */

  const [form_data, setFormData] = useState<FormData>({
    submitted: false,
    version_name: '',
  })

  /**
   * Component functions
   */

  const handleClickClone = () => {
    setFormData((prev_state) => {
      return { ...prev_state, submitted: true }
    })

    if (basicInfoAccount && form_data.version_name) {
      actionForm(`${basicInfoAccount.url}`, form_data.version_name)

      handleCloseDialog()
    }
  }

  /**
   * Render
   */

  return (
    <Dialog open={dialogData.open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {formatMessage(messages.createVersionDialogTitle, { name: dialogData.itemTitle })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{formatMessage(messages.createVersionDialogText)}</DialogContentText>
        <TextField
          error={form_data.submitted && !form_data.version_name}
          helperText={
            form_data.submitted && !form_data.version_name ? formatMessage(messages.nameError) : ''
          }
          autoFocus
          margin="dense"
          label={formatMessage(messages.name)}
          type="text"
          fullWidth
          value={form_data.version_name}
          onChange={(e: React.ChangeEvent) => {
            const { value } = e.target as HTMLInputElement
            setFormData((prev_state) => {
              return { ...prev_state, version_name: value }
            })
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          {formatMessage(messages.cancel)}
        </Button>
        <Button onClick={handleClickClone} color="primary">
          {formatMessage(messages.create)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
