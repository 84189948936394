import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  reportsPageTitle: {
    id: 'reports-list',
    defaultMessage: 'Reports list',
  },
  btnCreateReport: {
    id: 'btn-create-report',
    defaultMessage: 'Create new report',
  },
  deleteReportDialogTitle: {
    id: 'delete-report-dialog-title',
    defaultMessage: 'Do you want to delete the report "{name}"?',
  },
  deleteVersionReportDialogTitle: {
    id: 'delete-version-report-dialog-title',
    defaultMessage: 'Do you want to delete the version "{version}" of the report "{name}"?',
  },
  restoreVersionDialogTitle: {
    id: ' restore-version-dialog-title',
    defaultMessage: 'Are you sure to restore the version "{version}"?',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  restore: {
    id: 'restore',
    defaultMessage: 'Restore',
  },
  deleteTranslation: {
    id: 'delete-translation',
    defaultMessage: 'Delete translation',
  },
  deleteTranslationReportDialogTitle: {
    id: 'delete-translation-report-dialog-title',
    defaultMessage: 'Do you want to delete the translation "{language}"?',
  },
  deleteVersion: {
    id: 'delete-version',
    defaultMessage: 'Delete version',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit',
  },
  clone: {
    id: 'clone',
    defaultMessage: 'Clone',
  },
  preview: {
    id: 'preview',
    defaultMessage: 'Preview',
  },
  download: {
    id: 'download',
    defaultMessage: 'Download',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  actions: {
    id: 'actions',
    defaultMessage: 'Actions',
  },
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  reports: {
    id: 'reports',
    defaultMessage: 'Reports',
  },
  noResultsReports: {
    id: 'no-results-reports',
    defaultMessage: 'There are no reports created',
  },
  noResultsVersions: {
    id: 'no-results-versions',
    defaultMessage: 'There are no versions created',
  },
  noResultsTranslations: {
    id: 'no-results-translations',
    defaultMessage: 'There are no translations added yet',
  },
  lastModificationDate: {
    id: 'last-modification-date',
    defaultMessage: 'Last modification',
  },
  deleteReportSuccessful: {
    id: 'delete-report-successful',
    defaultMessage: 'Great, report has been deleted',
  },
  deleteVersionReportSuccessful: {
    id: 'delete-version-report-successful',
    defaultMessage: 'Great, version of report has been deleted',
  },
  deleteTranslationReportSuccessful: {
    id: 'delete-translation-report-successful',
    defaultMessage: 'Great, translation of report has been deleted',
  },
  restoreVersionReportSuccessful: {
    id: 'restore-version-report-successful',
    defaultMessage: 'Great, version of report has been restored',
  },
  cloneReportSuccessful: {
    id: 'clone-report-successful',
    defaultMessage: 'Great, report has been cloned',
  },
  contractId: {
    id: 'contract-id',
    defaultMessage: 'Contract Id',
  },
  contractNotFound: {
    id: 'contract-not-found',
    defaultMessage: 'Contract not found',
  },
  selectLanguage: {
    id: 'select-language',
    defaultMessage: 'Select language',
  },
  selectDate: {
    id: 'select-date',
    defaultMessage: 'Select date',
  },
  notLanguagesDefined: {
    id: 'not-languages-defined',
    defaultMessage: 'You must define the available languages',
  },
  viewVersions: {
    id: 'view-versions',
    defaultMessage: 'View versions',
  },
  date: {
    id: 'date',
    defaultMessage: 'Date',
  },
  titleVersions: {
    id: 'title-versions',
    defaultMessage: 'Versions',
  },
  restoreVersion: {
    id: 'restore-version',
    defaultMessage: 'Restore version',
  },
  titleTranslations: {
    id: 'title-translations',
    defaultMessage: 'Translations',
  },
  langName: {
    id: 'lang-name',
    defaultMessage: 'Language',
  },
  dateUpdated: {
    id: 'date-updated',
    defaultMessage: 'Updated',
  },
  translateCompleted: {
    id: 'translate-completed',
    defaultMessage: 'Completed',
  },
  uploadTranslationFile: {
    id: 'upload-translation-file',
    defaultMessage: 'Upload translation',
  },
  downloadTranslationFile: {
    id: 'download-translation-file',
    defaultMessage: 'Download translation',
  },
  typeTranslationFileIncorrect: {
    id: 'type-translation-file-incorrect',
    defaultMessage: 'The file selected is not a valid PO file',
  },
  uploadTranslationFileSuccess: {
    id: 'upload-translation-file-success',
    defaultMessage: 'Translation has been updated',
  },
  user: {
    id: 'user',
    defaultMessage: 'User',
  },
  loadMore: {
    id: 'load-more',
    defaultMessage: 'Load More',
  },
})
