import { AnyAction } from 'redux'
import { LOCATION_CHANGE } from 'connected-react-router'
import { history } from '../helpers'
import { locales } from '../constants/locale'
const queryString = require('query-string')

/**
 * Query param middleware.
 * @module middleware/queryParam
 */

export const queryParamMiddleware = (store: any) => (next: any) => (action: AnyAction) => {
  const newAction = Object.assign({}, action)
  let result = next(action)
  if (action.type === LOCATION_CHANGE) {
    const location = newAction.payload.location
    const intl = store.getState().intl
    const parsed = queryString.parse(location.search)
    if (parsed['lang'] === undefined || !(parsed['lang'] in locales)) {
      result = next(newAction)
      newAction.payload.location.search = `?lang=${intl.locale}`
      history.replace({
        pathname: location.pathname,
        search: `?lang=${intl.locale}`,
      })
    }
  }

  return result
}
