/**
 * Current account actions
 * @module actions/currentAccount/currentAccount
 */

import {
  GET_ACCOUNT,
  GET_APPLICATIONS,
  GET_GENERATE_REPORTS,
  GET_REPORTS,
  LOAD_MORE_GENERATE_REPORTS,
  LOAD_MORE_REPORTS,
  MAKE_HTMLS,
  MAKE_PDFS,
} from '../../constants/ActionTypes'
import { RECORDS_BY_PAGE, SORT_ASC } from '../../constants/search'
import { searchContent } from '../search/search'

/**
 * Get account info
 * @function getAccountInfo
 * @param {string} url Content url.
 * @returns {Object} Get account info action.
 */
export function getAccountInfo(url: string) {
  return {
    type: GET_ACCOUNT,
    request: {
      op: 'get',
      path: `${url}`,
    },
  }
}

/**
 * Get reports
 * @function getReports
 * @param {string} url Content url.
 * @param {boolean} fullobjects Get all payload of object.
 * @param {Object} options Search options.
 * @returns {Object} Get all reports.
 */
export function getReports(
  url: string,
  fullobjects = false,
  sort: any = { field: 'id', type: SORT_ASC },
  page = 0,
  isLoadMore = false
) {
  const params: any = {
    type_name: 'ReportDesign',
    [`_sort_${sort.type}`]: sort.field,
    _size: RECORDS_BY_PAGE,
    _from: page * RECORDS_BY_PAGE,
  }
  if (fullobjects) {
    params['_fullobjects'] = true
  }

  if (isLoadMore) {
    return searchContent(url, params, LOAD_MORE_REPORTS)
  } else {
    return searchContent(url, params, GET_REPORTS)
  }
}

/**
 * Get account info
 * @function getAccountInfo
 * @param {string} url Content url.
 * @returns {Object} Get account info action.
 */
export function getApplication(url: string) {
  return {
    request: {
      op: 'get',
      path: `${url}`,
    },
  }
}

/**
 * Get account info
 * @function getAccountInfo
 * @param {string} url Content url.
 * @returns {Object} Get account info action.
 */
export function getReportFolder(url: string) {
  return {
    request: {
      op: 'get',
      path: `${url}`,
    },
  }
}

/**
 * Get applications
 * @function getApplications
 * @param {string} url Content url.
 * @param {boolean} fullobjects Get all payload of object.
 * @param {Object} options Search options.
 * @returns {Object} Get all applications.
 */
export function getApplications(url: string, fullobjects = false) {
  const params: any = {
    type_name: 'WebApplication',
  }
  if (fullobjects) {
    params['_fullobjects'] = true
  }
  return searchContent(url, params, GET_APPLICATIONS)
}

/**
 * Get generate reports config
 * @function getGenerateReports
 * @param {string} url Content url.
 * @param {boolean} fullobjects Get all payload of object.
 * @param {Object} options Search options.
 * @returns {Object} Get all configurations of generate reports.
 */
export function getGenerateReports(
  url: string,
  fullobjects = false,
  sort: any = { field: 'id', type: SORT_ASC },
  page = 0,
  isLoadMore = false
) {
  const params: any = {
    type_name: 'SetPDF',
    [`_sort_${sort.type}`]: sort.field,
    _size: RECORDS_BY_PAGE,
    _from: page * RECORDS_BY_PAGE,
  }
  if (fullobjects) {
    params['_fullobjects'] = true
  }

  if (isLoadMore) {
    return searchContent(url, params, LOAD_MORE_GENERATE_REPORTS)
  } else {
    return searchContent(url, params, GET_GENERATE_REPORTS)
  }
}

/**
 * Make PDFs
 * @function makePDFs
 * @param {string} url Content url.
 */
export function makePDFs(url: string) {
  return {
    type: MAKE_PDFS,
    request: {
      op: 'get',
      path: `${url}/@makePdfs`,
    },
  }
}

/**
 * Make HTMLs
 * @function makeHTMLs
 * @param {string} url Content url.
 */
export function makeHTMLs(url: string) {
  return {
    type: MAKE_HTMLS,
    request: {
      op: 'get',
      path: `${url}/@makeHtmls`,
    },
  }
}
