/**
 * Search actions
 * @module actions/currentAccount/currentAccount
 */

import { compact, concat, isArray, join, map, pickBy, toPairs } from 'lodash'

import { SEARCH } from '../../constants/ActionTypes'

/**
 * Search content function.
 * @function searchContent
 * @param {string} url Url to use as base.
 * @param {Object} options Search options.
 * @param {string} subrequest Key of the subrequest.
 * @returns {Object} Search content action.
 */
export function searchContent(url: string, options: any, type = SEARCH) {
  let queryArray: string[] = []
  const arrayOptions = pickBy(options, (item: any) => isArray(item))

  queryArray = concat(
    queryArray,
    options
      ? join(
          map(toPairs(pickBy(options, (item: any) => !isArray(item))), (item: any) =>
            join(item, '=')
          ),
          '&'
        )
      : ''
  )

  queryArray = concat(
    queryArray,
    arrayOptions
      ? join(
          map(pickBy(arrayOptions), (item: any, key: any) =>
            join(
              item.map((value: any) => `${key}:list=${value}`),
              '&'
            )
          ),
          '&'
        )
      : ''
  )

  const querystring = join(compact(queryArray), '&')

  return {
    type,
    request: {
      op: 'get',
      path: `${url}/@search${querystring ? `?${querystring}` : ''}`,
    },
  }
}
