import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import {
  Container,
  Typography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { AppState } from '../../reducers'
import { useIsMount, getErrorMessage } from '../../helpers'
import { getAccountInfo, resetContent, updateContent } from '../../actions'
import routes, { HOME_PAGE_ROUTE } from '../../constants/Routes'
import {
  CustomBreadcrumbs,
  SnackbarWrapper,
  TransferList,
  ClipLoaderSpinner,
} from '../../components'
import { UTILITIES_LANGUAGES } from '../../constants/locale'

const messages = defineMessages({
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  chooseLanguages: {
    id: 'choose-plugins',
    defaultMessage: 'Choose languages',
  },
  updateAccountSuccessful: {
    id: 'update-account-successful',
    defaultMessage: 'Great, account has been updated',
  },
  btnSave: {
    id: 'save',
    defaultMessage: 'Save',
  },
  languages: {
    id: 'languages',
    defaultMessage: 'Languages',
  },
  selectDefaultLanguage: {
    id: 'select-default-language',
    defaultMessage: 'Select default language',
  },
  errorDefaultLanguageNotSelected: {
    id: 'default-language-not-selected',
    defaultMessage: 'The default language is not selected as the language of the account',
  },
})

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: 446,
    [theme.breakpoints.down('sm')]: {
      width: 396,
    },
    [theme.breakpoints.down('xs')]: {
      width: 266,
    },
  },
}))

export const LanguagesPage = () => {
  const classes = useStyles({})

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Custom hooks
   */
  const isMount = useIsMount()

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    currentAccount: { account },
    content: { update },
    userSession: { basic_info_account },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [default_language, setDefaultLanguage] = useState<string>('')
  const [languages_result, setLanguagesResult] = useState<string[]>([])
  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })

  useEffect(() => {
    if (account && account.languages) {
      setDefaultLanguage(account.default_language)
      setLanguagesResult(account.languages)
    }
    return () => {
      dispatch(resetContent())
    }
  }, [account, dispatch])

  useEffect(() => {
    if (
      isMount &&
      update.loaded &&
      !update.loading &&
      !update.error &&
      account &&
      basic_info_account
    ) {
      dispatch(getAccountInfo(basic_info_account.url))
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.updateAccountSuccessful),
      })
    } else if (update.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(update.error),
      })
    }
  }, [dispatch, update, account, formatMessage, isMount, basic_info_account])

  /**
   * Component functions
   */

  const handleClose = () => {
    setAlertInfo((prevState) => {
      return { ...prevState, open: false }
    })
  }

  const updateContentPluginsResult = (result: string[]) => {
    setLanguagesResult(result)
  }

  const saveChanges = () => {
    if (account) {
      if (languages_result.indexOf(default_language) !== -1) {
        dispatch(
          updateContent(`db/${account['@name']}/`, {
            languages: languages_result,
            default_language: default_language,
          })
        )
      } else {
        setAlertInfo({
          open: true,
          type: 'error',
          message: formatMessage(messages.errorDefaultLanguageNotSelected),
        })
      }
    }
  }

  /**
   * Render
   */

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
    ]

    return (
      <CustomBreadcrumbs links={links} currentPageName={formatMessage(messages.chooseLanguages)} />
    )
  }

  const btnSubmit = () => {
    if (!update.loading && account) {
      return (
        <Button variant="contained" color="primary" onClick={() => saveChanges()}>
          {formatMessage(messages.btnSave)}
        </Button>
      )
    }
  }

  const renderTransferList = () => {
    if (account) {
      return (
        <TransferList
          initData={account && account.languages ? account.languages : []}
          allData={UTILITIES_LANGUAGES}
          updateResult={updateContentPluginsResult}
        />
      )
    } else {
      return <ClipLoaderSpinner loading={true} size={150} />
    }
  }

  return (
    <Container>
      {renderBreadcrumbs()}

      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />

      <Typography component="h1" variant="h4" align="center">
        {formatMessage(messages.languages)}
      </Typography>

      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item>
          <FormControl className={classes.formControl}>
            <InputLabel>{formatMessage(messages.selectDefaultLanguage)}</InputLabel>
            <Select
              value={default_language}
              onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                const { value } = e.target as HTMLInputElement
                setDefaultLanguage(value)
              }}
            >
              {account &&
                account.languages.map((language: string) => {
                  return (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {renderTransferList()}

      <Grid container>
        <ClipLoaderSpinner loading={update.loading} />
        {btnSubmit()}
      </Grid>
    </Container>
  )
}
