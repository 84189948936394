import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { defineMessages, useIntl } from 'react-intl'
import { Container, Grid, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import routes, { HOME_PAGE_ROUTE } from '../../constants/Routes'
import {
  CustomBreadcrumbs,
  TransferList,
  SnackbarWrapper,
  ClipLoaderSpinner,
} from '../../components'
import { AppState } from '../../reducers'
import { ALL_CONTENT_PLUGINS, ALL_LAYOUT_PLUGINS } from '../../constants/plugins'
import { getErrorMessage, useIsMount, userHasFeaturePermission } from '../../helpers'
import { getAccountInfo, resetContent, updateContent } from '../../actions'
import { FEAT_PLUGIN_ACTIVES_VIEW, FEAT_PLUGIN_ACTIVES_EDIT } from '../../constants/Roles'

const messages = defineMessages({
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  choosePlugins: {
    id: 'choose-plugins',
    defaultMessage: 'Choose plugins',
  },
  btnSave: {
    id: 'save',
    defaultMessage: 'Save',
  },
  updateAccountSuccessful: {
    id: 'update-account-successful',
    defaultMessage: 'Great, account has been updated',
  },
  contentPluginsTitle: {
    id: 'content-plugin-title',
    defaultMessage: 'Content plugins',
  },
  layoutPluginsTitle: {
    id: 'layout-plugin-title',
    defaultMessage: 'Layout plugins',
  },
})

const useStyles = makeStyles((theme) => ({
  sectionLayoutPlugins: {
    marginTop: theme.spacing(4),
  },
}))

export const ChoosePluginsPage = () => {
  const classes = useStyles({})

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Custom hooks
   */

  const isMount = useIsMount()

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    currentAccount: { account },
    userSession: { basic_info_account, permissions },
    content: { update },
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [content_plugins_result, setContentPluginsResult] = useState<string[]>([])
  const [layout_plugins_result, setLayoutPluginsResult] = useState<string[]>([])
  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })

  useEffect(() => {
    if (account && account.content_plugins) {
      setContentPluginsResult(account.content_plugins)
      setLayoutPluginsResult(account.layout_plugins)
    }
    return () => {
      dispatch(resetContent())
    }
  }, [account, dispatch])

  useEffect(() => {
    if (
      isMount &&
      update.loaded &&
      !update.loading &&
      !update.error &&
      account &&
      basic_info_account
    ) {
      dispatch(getAccountInfo(basic_info_account.url))
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.updateAccountSuccessful),
      })
    } else if (update.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(update.error),
      })
    }
  }, [dispatch, update, account, formatMessage, isMount, basic_info_account])

  /**
   * Component functions
   */

  const handleClose = () => {
    setAlertInfo((prevState) => {
      return { ...prevState, open: false }
    })
  }

  const updateContentPluginsResult = (result: string[]) => {
    setContentPluginsResult(result)
  }

  const updateLayoutPluginsResult = (result: string[]) => {
    setLayoutPluginsResult(result)
  }

  const saveChanges = () => {
    if (account) {
      dispatch(
        updateContent(`db/${account['@name']}/`, {
          content_plugins: content_plugins_result,
          layout_plugins: layout_plugins_result,
        })
      )
    }
  }

  /**
   * Render
   */

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
    ]

    return (
      <CustomBreadcrumbs links={links} currentPageName={formatMessage(messages.choosePlugins)} />
    )
  }

  const btnSubmit = () => {
    if (
      !update.loading &&
      account &&
      userHasFeaturePermission(FEAT_PLUGIN_ACTIVES_EDIT, permissions)
    ) {
      return (
        <Button variant="contained" color="primary" onClick={() => saveChanges()}>
          {formatMessage(messages.btnSave)}
        </Button>
      )
    }
  }

  const renderContentPluginsTransferList = () => {
    if (account) {
      return (
        <TransferList
          initData={account && account.content_plugins ? account.content_plugins : []}
          allData={ALL_CONTENT_PLUGINS}
          updateResult={updateContentPluginsResult}
          showSelectedItems={userHasFeaturePermission(FEAT_PLUGIN_ACTIVES_VIEW, permissions)}
        />
      )
    } else {
      return <ClipLoaderSpinner loading={true} size={150} />
    }
  }

  const renderLayoutPluginsTransferList = () => {
    if (account) {
      return (
        <TransferList
          initData={account && account.layout_plugins ? account.layout_plugins : []}
          allData={ALL_LAYOUT_PLUGINS}
          updateResult={updateLayoutPluginsResult}
          showSelectedItems={userHasFeaturePermission(FEAT_PLUGIN_ACTIVES_VIEW, permissions)}
        />
      )
    } else {
      return <ClipLoaderSpinner loading={true} size={150} />
    }
  }

  return (
    <Container>
      {renderBreadcrumbs()}

      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleClose} />

      <Typography component="h1" variant="h4" align="center">
        {formatMessage(messages.contentPluginsTitle)}
      </Typography>
      {renderContentPluginsTransferList()}

      <Typography
        component="h1"
        variant="h4"
        align="center"
        className={classes.sectionLayoutPlugins}
      >
        {formatMessage(messages.layoutPluginsTitle)}
      </Typography>
      {renderLayoutPluginsTransferList()}

      <Grid container>
        <ClipLoaderSpinner loading={update.loading} />
        {btnSubmit()}
      </Grid>
    </Container>
  )
}
