/**
 * AuthToken helper.
 * @module helpers/AuthToken
 */

import jwtDecode from 'jwt-decode'

import { loginRenew } from '../../actions'

/**
 * Get auth token method.
 * @method getAuthToken
 * @returns {undefined}
 */
export function getAuthToken() {
  return localStorage.getItem('auth_token')
}

/**
 * Persist auth token method.
 * @method persistAuthToken
 * @param {object} store Redux store.
 * @returns {undefined}
 */
export function persistAuthToken(store) {
  let currentValue = getAuthToken()

  /**
   * handleChange method.
   * @method handleChange
   * @param {bool} initial Initial call.
   * @returns {undefined}
   */
  function handleChange(initial) {
    const previousValue = currentValue
    const state = store.getState()
    currentValue = state.userSession.token
    if (previousValue !== currentValue || initial) {
      if (!currentValue) {
        localStorage.removeItem('auth_token')
      } else {
        localStorage.setItem('auth_token', currentValue)
        setTimeout(() => {
          if (store.getState().userSession.token) {
            if (jwtDecode(store.getState().userSession.token).exp * 1000 > new Date().getTime()) {
              store.dispatch(loginRenew())
            } else {
            }
          }
        }, (jwtDecode(store.getState().userSession.token).exp * 1000 - new Date().getTime()) * 0.9)
      }
    }
  }

  store.subscribe(handleChange)
  handleChange(true)
}
