/**
 * Current accounts actions
 * @module actions/accounts/accounts
 */

import { GET_ACCOUNTS } from '../../constants/ActionTypes'

/**
 * Get account info
 * @function getAccountInfo
 * @param {string} url Content url.
 * @returns {Object} Get account info action.
 */
export function getAccounts(url: string) {
  return {
    type: GET_ACCOUNTS,
    request: {
      op: 'get',
      path: `${url}/@utilities`,
    },
  }
}
