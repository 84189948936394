export const removeUndefinedFielsFromJson = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeUndefinedFielsFromJson(obj[key])
    } else if (obj[key] === undefined) {
      delete obj[key]
    }
  })
  return obj
}
