/**
 * User session reducer.
 * @module reducers/userSession/userSession
 */

import { LOGIN, LOGIN_RENEW, LOGOUT, SELECT_ACCOUNT } from '../../constants/ActionTypes'
import { AnyAction } from 'redux'
import { ReducerStatusAsync } from '../../types/reducer'
import { Login, InfoDataBaseAccount } from '../../types/guillotina'
import jwtDecode from 'jwt-decode'
import { config } from '../../config'
import roles_catalog from '../../constants/Roles'

export type UserSessionReducer = {
  login: ReducerStatusAsync
  basic_info_account: InfoDataBaseAccount | null
  user_id: string
  current_account_selected: string
  is_superuser: boolean
  permissions: BeePermissions
} & Login

const currentTokenLocalStorage: any = localStorage.getItem('auth_token')
const tokenDecoded = currentTokenLocalStorage ? jwtDecode<any>(currentTokenLocalStorage) : null

const getCurrentAccount = (tokenDecoded: any): string => {
  const currentAccountLocalStorage: string | null = localStorage.getItem('current_account')
  let result = ''
  if (currentAccountLocalStorage) {
    result = currentAccountLocalStorage
  } else if (tokenDecoded) {
    result = tokenDecoded.account
  }
  return result
}

const getPermissions = (roles: Array<string> = []): BeePermissions => {
  const permissions: BeePermissions = { routes: [], features: [] }
  roles.forEach(function (role: string) {
    if (roles_catalog.hasOwnProperty(role)) {
      permissions.routes = permissions.routes.concat(roles_catalog[role].routes)
      permissions.features = permissions.features.concat(roles_catalog[role].features)
    }
  })
  permissions.routes = Array.from(new Set(permissions.routes)) // Remove duplicated routes in array
  permissions.features = Array.from(new Set(permissions.features)) // Remove duplicated features in array
  return permissions
}

const initialState: UserSessionReducer = {
  token: currentTokenLocalStorage || null,
  permissions: getPermissions(tokenDecoded ? tokenDecoded.roles : []),
  exp: 0,
  user_id: tokenDecoded ? tokenDecoded.id : '',
  is_superuser: tokenDecoded ? tokenDecoded.superuser : false,
  current_account_selected: getCurrentAccount(tokenDecoded),
  basic_info_account: tokenDecoded
    ? {
        url: `${config.dbDataBase}/${getCurrentAccount(tokenDecoded)}`,
        data: `${config.dataDatabase}/${getCurrentAccount(tokenDecoded)}`,
      }
    : null,
  login: {
    loaded: false,
    loading: false,
    error: null,
  },
}

/**
 * User session reducer.
 * @function userSession
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function userSession(state = initialState, action: AnyAction) {
  switch (action.type) {
    case `${LOGIN}_PENDING`:
      return {
        ...state,
        token: null,
        permissions: { routes: [], features: [] },
        login: {
          loading: true,
          loaded: false,
          error: null,
        },
      }
    case `${LOGIN}_SUCCESS`:
    case `${LOGIN_RENEW}_SUCCESS`:
      const _tokenDecoded: any = jwtDecode(action.result.token)
      if (_tokenDecoded.account) {
        localStorage.setItem('current_account', _tokenDecoded.account)
      }
      return {
        ...state,
        basic_info_account: {
          url: `${config.dbDataBase}/${_tokenDecoded.account}`,
          data: `${config.dataDatabase}/${_tokenDecoded.account}`,
        },
        token: action.result.token,
        permissions: getPermissions(_tokenDecoded ? _tokenDecoded.roles : []),
        exp: action.result.exp,
        user_id: _tokenDecoded.id,
        is_superuser: _tokenDecoded.superuser,
        current_account_selected: _tokenDecoded.account,
        login: {
          loading: false,
          loaded: true,
          error: null,
        },
      }
    case `${LOGIN}_FAIL`:
    case `${LOGIN_RENEW}_FAIL`:
      localStorage.removeItem('current_account')
      return {
        ...state,
        token: null,
        permissions: { routes: [], features: [] },
        basic_info_account: null,
        exp: 0,
        login: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      }
    case LOGOUT:
      localStorage.removeItem('current_account')
      return {
        ...state,
        token: null,
        permissions: { routes: [], features: [] },
        exp: 0,
        basic_info_account: null,
        current_account_selected: '',
        user_id: '',
        is_superuser: false,
        welcome: {
          loaded: false,
          loading: false,
          error: null,
        },
      }
    case SELECT_ACCOUNT:
      localStorage.setItem('current_account', action.account)
      return {
        ...state,
        current_account_selected: action.account,
        basic_info_account: {
          url: `${config.dbDataBase}/${action.account}`,
          data: `${config.dataDatabase}/${action.account}`,
        },
      }
    default:
      return state
  }
}
