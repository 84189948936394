/**
 * User session actions.
 * @module actions/userSession/userSession
 */

import { LOGIN, LOGIN_RENEW, LOGOUT, SELECT_ACCOUNT } from '../../constants/ActionTypes'
import routes, { HOME_PAGE_ROUTE } from '../../constants/Routes'

/**
 * Login function.
 * @function login
 * @param {string} username Username.
 * @param {string} password Password.
 * @returns {Object} Login action.
 */
export function login(username: string, password: string) {
  return {
    type: LOGIN,
    request: {
      op: 'post',
      path: '@login',
      redirectTo: routes[HOME_PAGE_ROUTE].path,
      data: { login: username, password },
    },
  }
}

/**
 * Login renew function.
 * @function loginRenew
 * @returns {Object} Login renew action.
 */
export function loginRenew() {
  return {
    type: LOGIN_RENEW,
    request: {
      op: 'post',
      path: '@login-renew',
    },
  }
}

/**
 * Logout function.
 * @function logout
 * @returns {Object} Logout action.
 */
export function logout() {
  return {
    type: LOGOUT,
  }
}

/**
 * Select account .
 * @function selectAccount
 * @returns {Object} select account action.
 */
export function selectAccount(account_id: string) {
  return {
    type: SELECT_ACCOUNT,
    account: account_id,
  }
}
