import { defineMessages, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import routes, { HOME_PAGE_ROUTE } from '../../constants/Routes'

const messages = defineMessages({
  notFoundTitle: {
    id: 'not-found-title',
    defaultMessage: 'Page Not Found',
  },
  notFoundText: {
    id: 'not-found-text',
    defaultMessage:
      "Looks like you've followed a broken link or entered a URL that doesn't exist on this site.",
  },
  notFoundLink: {
    id: 'not-found-link',
    defaultMessage: 'Back to our site',
  },
})

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    background: 'white',
    color: 'rgb(14, 30, 37)',
    borderRadius: theme.spacing(1),
    boxShadow: '0 2px 4px 0 rgba(14, 30, 37, .16)',
  },
  backLink: {
    margin: theme.spacing(0),
    textDecoration: 'none',
    lineHeight: '24px',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  backText: {
    marginLeft: theme.spacing(0.5),
  },
}))

export const NotFoundPage = () => {
  const classes = useStyles({})

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  return (
    <Container maxWidth="xs">
      <div className={classes.card}>
        <div className="header">
          <h1>{formatMessage(messages.notFoundTitle)}</h1>
        </div>
        <div className="body">
          <p>{formatMessage(messages.notFoundText)}</p>
          <p>
            <Link className={classes.backLink} to={routes[HOME_PAGE_ROUTE].path}>
              <ArrowBackIosIcon fontSize="small" />
              <span className={classes.backText}>{formatMessage(messages.notFoundLink)}</span>
            </Link>
          </p>
        </div>
      </div>
    </Container>
  )
}
