import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  applicationsPageTitle: {
    id: 'applications-list',
    defaultMessage: 'Applications list',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  copy: {
    id: 'copy',
    defaultMessage: 'Copy',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit',
  },
  clone: {
    id: 'clone',
    defaultMessage: 'Clone',
  },
  download: {
    id: 'download',
    defaultMessage: 'Download',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  actions: {
    id: 'actions',
    defaultMessage: 'Actions',
  },
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  applications: {
    id: 'applications',
    defaultMessage: 'Applications',
  },
  deleteApplicationDialogTitle: {
    id: 'delete-application-dialog-title',
    defaultMessage: 'Do you want to delete application "{name}"?',
  },
  btnCreateApplication: {
    id: 'btn-create-application',
    defaultMessage: 'Create new application',
  },
  add: {
    id: 'add',
    defaultMessage: 'Add',
  },
  btnSaveAppplication: {
    id: 'btn-save-application',
    defaultMessage: 'Save application',
  },
  applicationNameError: {
    id: 'application-name-error',
    defaultMessage: 'Application name is mandatory',
  },
  menuItemNameError: {
    id: 'menu-item-name-error',
    defaultMessage: 'Menu title is mandatory',
  },
  externalLinkError: {
    id: 'external-link-error',
    defaultMessage: 'External link is mandatory',
  },
  reportError: {
    id: 'report-item-menu-error',
    defaultMessage: 'Report is mandatory',
  },
  reportTitle: {
    id: 'report-title',
    defaultMessage: 'Report',
  },
  deleteMenuItemDialogTitle: {
    id: 'delete-menu-item-dialog-title',
    defaultMessage: 'Do you want to delete this item ?',
  },
  btnAddMenuItem: {
    id: 'btn-add-menu-item',
    defaultMessage: 'Add menu item',
  },
  labelApplicationName: {
    id: 'label-application-name',
    defaultMessage: 'Application name',
  },
  labelItemMenuTitle: {
    id: 'label-item-menu-title',
    defaultMessage: 'Default menu title',
  },
  noResultsApplications: {
    id: 'no-results-applications',
    defaultMessage: 'There are no application created',
  },
  errorMsgMenuNotFill: {
    id: 'error-menu-not-fill',
    defaultMessage: 'Not all menu info is completed',
  },
  lastModificationDate: {
    id: 'last-modification-date',
    defaultMessage: 'Last modification',
  },
  updateApplicationSuccessful: {
    id: 'update-application-successful',
    defaultMessage: 'Great, application has been updated',
  },
  deleteApplicationSuccessful: {
    id: 'delete-application-successful',
    defaultMessage: 'Great, application has been deleted',
  },
  defineMenus: {
    id: 'define-menus',
    defaultMessage: 'Define all menus',
  },
  changeLinkTypeToExternalLink: {
    id: 'change-link-type-externa-link',
    defaultMessage: 'Change to external link',
  },
  changeLinkTypeToReport: {
    id: 'change-link-type-report-link',
    defaultMessage: 'Change to report link',
  },
  externalLink: {
    id: 'external-link',
    defaultMessage: 'External link',
  },
  setTranslations: {
    id: 'set-translations',
    defaultMessage: 'Set translations',
  },
  preview: {
    id: 'preview',
    defaultMessage: 'Preview',
  },
  notLanguagesDefined: {
    id: 'not-languages-defined',
    defaultMessage: 'You must define the available languages',
  },
  selectLanguage: {
    id: 'select-language',
    defaultMessage: 'Select language',
  },
  selectDate: {
    id: 'select-date',
    defaultMessage: 'Select date',
  },
  contractId: {
    id: 'contract-id',
    defaultMessage: 'Contract Id',
  },
  contractNotFound: {
    id: 'contract-not-found',
    defaultMessage: 'Contract not found',
  },
  renderHeader: {
    id: 'render-header',
    defaultMessage: 'Header',
  },
  noRenderHeader: {
    id: 'no-render-header',
    defaultMessage: 'No header',
  },
  renderSidebar: {
    id: 'render-sidebar',
    defaultMessage: 'Sidebar',
  },
  noRenderSidebar: {
    id: 'no-render-sidebar',
    defaultMessage: 'No sidebar',
  },
})
