import React, { useState, useEffect } from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  TableRow,
  TableCell,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  Tooltip,
  makeStyles,
  Grid,
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { v4 } from 'uuid'

import { AppState } from '../../reducers'
import routes, { HOME_PAGE_ROUTE } from '../../constants/Routes'

import {
  CustomBreadcrumbs,
  SnackbarWrapper,
  ClipLoaderSpinner,
  DialogGenericAction,
} from '../../components'
import {
  getServiceTokens,
  deleteContent,
  resetContent,
  createContent,
} from '../../actions/content/content'
import {
  getErrorMessage,
  userHasFeaturePermission,
  getFormattedObjectId,
  useIsMount,
} from '../../helpers'
import { ServiceToken } from '../../types/guillotina'
import { FEAT_SERVICE_TOKEN_DELETE, FEAT_SERVICE_TOKEN_CREATE } from '../../constants/Roles'

const messages = defineMessages({
  serviceTokens: {
    id: 'service-tokens',
    defaultMessage: 'Service tokens',
  },
  serviceToken: {
    id: 'service-token',
    defaultMessage: 'Service token',
  },
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  serviceTokensPageTitle: {
    id: 'service-tokens-page-title',
    defaultMessage: 'Service tokens list',
  },
  noResultsServiceTokens: {
    id: 'no-results-service-tokens',
    defaultMessage: 'There are no service tokens created',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'description',
    defaultMessage: 'Description',
  },
  actions: {
    id: 'actions',
    defaultMessage: 'Actions',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  deleteServiceTokenDialogTitle: {
    id: 'delete-service-token-dialog-title',
    defaultMessage: 'Do you want to delete service token "{name}"?',
  },
  deleteServiceTokenSuccessful: {
    id: 'delete-service-token-successful',
    defaultMessage: 'Great, service token has been deleted',
  },
  createServiceTokenSuccessful: {
    id: 'create-service-token-successful',
    defaultMessage: 'Great, service token has been created',
  },
  genericTextRequired: {
    id: 'generic-text-required',
    defaultMessage: 'This field is required',
  },
  identifier: {
    id: 'identifier',
    defaultMessage: 'Identifier',
  },
  titleCreateToken: {
    id: 'create-service-token',
    defaultMessage: 'Create service token',
  },
  createNewToken: {
    id: 'create-new-token',
    defaultMessage: 'Create new token',
  },
  btnCreate: {
    id: 'create',
    defaultMessage: 'Create',
  },
  msgCreateTokenServiceModal: {
    id: 'msg-create-token-service-modal',
    defaultMessage:
      "This is the service token, you must copy it, because you can't see it anymore.",
  },
  close: {
    id: 'close',
    defaultMessage: 'Close',
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  btnCreateApplication: {
    marginTop: theme.spacing(4),
  },
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    fill: '#000',
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}))

export const ServiceTokenPage = () => {
  const classes = useStyles({})

  /**
   * Custom hooks
   */

  const isMount = useIsMount()

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account, permissions },
    content,
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */
  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })

  const [delete_dialog_data, setDeleteDialogData] = useState<DialogState>({
    open: false,
    itemName: '',
    itemTitle: '',
  })

  const [is_open_info_dialog, setIsOpenInfoDialog] = useState<boolean>(false)

  const [add_mode, setAddMode] = useState<boolean>(false)
  const [form_state, setFormState] = useState<any>({
    id: '',
    description: '',
    submitted: false,
  })
  const [new_token_created, setNewTokenCreated] = useState<string | null>(null)

  useEffect(() => {
    return () => {
      dispatch(resetContent())
    }
  }, [dispatch])

  useEffect(() => {
    if (basic_info_account) {
      dispatch(getServiceTokens(basic_info_account.url))
    }
  }, [basic_info_account, dispatch])

  useEffect(() => {
    if (
      content.delete.loaded &&
      !content.delete.loading &&
      !content.delete.error &&
      basic_info_account &&
      !content.get.loading
    ) {
      dispatch(getServiceTokens(basic_info_account.url))
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.deleteServiceTokenSuccessful),
      })
    } else if (
      content.create.loaded &&
      !content.create.loading &&
      !content.create.error &&
      basic_info_account &&
      !content.get.loading
    ) {
      setIsOpenInfoDialog(true)
      setFormState({
        id: '',
        description: '',
        submitted: false,
      })
      setAddMode(false)
      dispatch(getServiceTokens(basic_info_account.url))
      setAlertInfo({
        open: true,
        type: 'success',
        message: formatMessage(messages.createServiceTokenSuccessful),
      })
    } else if (content.get.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(content.get.error),
      })
    } else if (content.create.error) {
      setAlertInfo({
        open: true,
        type: 'error',
        message: getErrorMessage(content.create.error),
      })
    }
  }, [content, formatMessage, dispatch, basic_info_account])

  /**
   * Component functions
   */

  const actionDeleteServiceToken = () => {
    if (basic_info_account) {
      dispatch(
        deleteContent(`${basic_info_account.url}/@servicetokens/${delete_dialog_data.itemName}`)
      )
    }
  }

  const handleCloseSnackbar = () => {
    setAlertInfo((prevState) => {
      return { ...prevState, open: false }
    })
  }

  const handleCloseModal = () => {
    setDeleteDialogData((prevState) => {
      return { ...prevState, open: false }
    })
  }

  const handleCloseInfoModal = () => {
    setIsOpenInfoDialog(false)
    setNewTokenCreated(null)
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    setFormState((prev_state: any) => {
      return { ...prev_state, submitted: true }
    })
    const { id, description } = form_state

    const new_token = v4()

    if (basic_info_account && id) {
      setNewTokenCreated(new_token)

      const objToSend = {
        id: getFormattedObjectId(id),
        description: description,
        token: new_token,
      }

      dispatch(createContent(`${basic_info_account.url}/@servicetokens`, objToSend))
    }
  }

  const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = e.target as HTMLInputElement
    setFormState((prev_state: any) => {
      return { ...prev_state, [name]: value }
    })
  }

  /**
   * Render
   */
  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
    ]

    return (
      <CustomBreadcrumbs links={links} currentPageName={formatMessage(messages.serviceTokens)} />
    )
  }

  const renderSpinner = () => {
    if (content.get.loading) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <ClipLoaderSpinner loading={content.get.loading} />
          </TableCell>
        </TableRow>
      )
    }
  }

  const renderCellNoResults = () => {
    if (content.get.loaded && (!content.data || content.data.length === 0)) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <div>{formatMessage(messages.noResultsServiceTokens)}</div>
          </TableCell>
        </TableRow>
      )
    }
  }

  const bntCreateToken = () => {
    if (
      !content.create.loading &&
      userHasFeaturePermission(FEAT_SERVICE_TOKEN_CREATE, permissions) &&
      !add_mode
    ) {
      return (
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAddMode(true)
            }}
          >
            {formatMessage(messages.createNewToken)}
          </Button>
        </Box>
      )
    }
  }

  const btnSubmit = () => {
    if (
      !content.create.loading &&
      userHasFeaturePermission(FEAT_SERVICE_TOKEN_CREATE, permissions)
    ) {
      return (
        <Button variant="contained" color="primary" type="submit">
          {formatMessage(messages.btnCreate)}
        </Button>
      )
    }
  }

  const renderAddMode = () => {
    const { id, description, submitted } = form_state

    if (add_mode) {
      return (
        <form noValidate onSubmit={handleSubmit}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h6" align="center">
              {formatMessage(messages.titleCreateToken)}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  error={submitted && !id}
                  helperText={submitted && !id ? formatMessage(messages.genericTextRequired) : ''}
                  required
                  id="id"
                  name="id"
                  label={formatMessage(messages.identifier)}
                  fullWidth
                  margin="normal"
                  value={id}
                  onChange={handleChange}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="description"
                  name="description"
                  label={formatMessage(messages.description)}
                  fullWidth
                  margin="normal"
                  value={description}
                  onChange={handleChange}
                  className={classes.textField}
                />
              </Grid>
            </Grid>
            <Box mt={3} width="100%" display="flex">
              {btnSubmit()}
              <ClipLoaderSpinner loading={content.create.loading} size={25} />
              <Box ml={3}>
                <Button
                  onClick={() => {
                    setAddMode(false)
                  }}
                  color="primary"
                >
                  {formatMessage(messages.cancel)}
                </Button>
              </Box>
            </Box>
          </Paper>
        </form>
      )
    }
  }

  return (
    <Container>
      {renderBreadcrumbs()}

      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleCloseSnackbar} />

      <Typography variant="h4">{formatMessage(messages.serviceTokensPageTitle)}</Typography>
      {bntCreateToken()}
      {renderAddMode()}

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{formatMessage(messages.name)}</TableCell>
              <TableCell>{formatMessage(messages.description)}</TableCell>
              <TableCell align="right">{formatMessage(messages.actions)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderSpinner()}
            {renderCellNoResults()}
            {isMount &&
              content.data &&
              Array.isArray(content.data) &&
              content.data.map((service_token: ServiceToken) => {
                return (
                  <TableRow key={service_token.id}>
                    <TableCell scope="row">{service_token.id}</TableCell>
                    <TableCell scope="row">{service_token.description}</TableCell>
                    <TableCell align="right">
                      {userHasFeaturePermission(FEAT_SERVICE_TOKEN_DELETE, permissions) && (
                        <Tooltip title={formatMessage(messages.delete)}>
                          <DeleteIcon
                            className={classes.icon}
                            onClick={() => {
                              setDeleteDialogData({
                                open: true,
                                itemName: service_token.id,
                                itemTitle: service_token.id,
                              })
                            }}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </Paper>

      <DialogGenericAction
        data={delete_dialog_data}
        handleCloseModal={handleCloseModal}
        action={actionDeleteServiceToken}
        messages={{
          title: formatMessage(messages.deleteServiceTokenDialogTitle, {
            name: delete_dialog_data.itemTitle,
          }),
          cancel: formatMessage(messages.cancel),
          actionText: formatMessage(messages.delete),
        }}
      />

      <Dialog
        open={is_open_info_dialog}
        onClose={handleCloseInfoModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formatMessage(messages.serviceToken)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {formatMessage(messages.msgCreateTokenServiceModal)}
          </DialogContentText>
          <DialogContentText>{new_token_created}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoModal} color="primary">
            {formatMessage(messages.close)}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default ServiceTokenPage
