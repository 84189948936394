import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'

import { useSelector } from 'react-redux'
import { AppState } from '../../reducers'
import { SimpleAccount } from '../../types/guillotina'

const messages = defineMessages({
  cloneReportDialogTitle: {
    id: 'clone-report-dialog-title',
    defaultMessage: 'Do you want to clone "{name}"?',
  },
  cloneDialogText: {
    id: 'clone-dialog-text',
    defaultMessage: 'Enter name',
  },
  name: {
    id: 'name',
    defaultMessage: 'New id name',
  },
  nameError: {
    id: 'name-error',
    defaultMessage: 'Name is mandatory',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  copy: {
    id: 'copy',
    defaultMessage: 'Copy',
  },
})

type CloneFormData = {
  submitted: boolean
  new_id: string
  copy_to: string
}

type Props = {
  copyDialogData: DialogState
  handleCloseCopyDialog: () => void
  handleCopyCopyDialog: (new_id: string, copy_to: string) => void
}

export const DialogCopyApplication = (props: Props) => {
  const { copyDialogData, handleCloseCopyDialog, handleCopyCopyDialog } = props
  const {
    accounts: { list_accounts },
  } = useSelector((state: AppState) => state)

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * React Hooks
   */

  const [clone_form_data, setCloneFormData] = useState<CloneFormData>({
    submitted: false,
    new_id: '',
    copy_to: '',
  })

  useEffect(() => {
    setCloneFormData((prevState) => {
      return { ...prevState, new_id: copyDialogData.itemTitle }
    })
  }, [copyDialogData])

  /**
   * Component functions
   */

  const handleClickClone = () => {
    setCloneFormData((prevState) => {
      return { ...prevState, submitted: true }
    })
    handleCopyCopyDialog(clone_form_data.new_id, clone_form_data.copy_to)
    handleCloseCopyDialog()
  }

  /**
   * Render
   */
  return (
    <Dialog
      open={copyDialogData.open}
      onClose={handleCloseCopyDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {formatMessage(messages.cloneReportDialogTitle, { name: copyDialogData.itemTitle })}
      </DialogTitle>
      <DialogContent>
        <p style={{ marginBottom: '0px' }}>Name:</p>
        <TextField
          error={clone_form_data.submitted && !clone_form_data.new_id}
          helperText={
            clone_form_data.submitted && !clone_form_data.new_id
              ? formatMessage(messages.nameError)
              : ''
          }
          autoFocus
          margin="dense"
          label={formatMessage(messages.name)}
          type="text"
          fullWidth
          value={clone_form_data.new_id}
          onChange={(e: React.ChangeEvent) => {
            const { value } = e.target as HTMLInputElement
            setCloneFormData((prevState) => {
              return { ...prevState, new_id: value }
            })
          }}
        />
        <p style={{ marginTop: '50px' }}>Copy to:</p>
        <Select
          value={clone_form_data.copy_to}
          onChange={(e) => {
            const { value } = e.target as HTMLInputElement
            setCloneFormData((prevState) => {
              return { ...prevState, copy_to: value }
            })
          }}
        >
          <MenuItem value={''}></MenuItem>
          {list_accounts.map((account: SimpleAccount) => {
            return (
              <MenuItem value={account.id} key={account.id}>
                {account.title}
              </MenuItem>
            )
          })}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCopyDialog} color="primary">
          {formatMessage(messages.cancel)}
        </Button>
        <Button onClick={handleClickClone} color="primary">
          {formatMessage(messages.copy)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
