import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { push } from 'connected-react-router'

import { Container, Typography, Button, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { AppState } from '../../reducers'
import {
  getReports,
  resetContent,
  deleteContent,
  createContent,
  updateContent,
} from '../../actions'

import { ReportType } from '../../types/guillotina'
import { FEAT_REPORT_CREATE } from '../../constants/Roles'

import routes, {
  ADD_REPORT_ROUTE,
  EDIT_REPORT_ROUTE,
  NOT_FOUND_PAGE_ROUTE,
  HOME_PAGE_ROUTE,
  PREVIEW_REPORT_PAGE_ROUTE,
} from '../../constants/Routes'

import {
  CustomBreadcrumbs,
  DialogGenericAction,
  SnackbarWrapper,
  ClipLoaderSpinner,
  DialogCloneAction,
} from '../../components'

import { messages } from './messages'
import { RowReport } from './RowReport'
import {
  createFile,
  getErrorMessage,
  useIsMount,
  getFormattedObjectId,
  userHasFeaturePermission,
} from '../../helpers'

import { config } from '../../config'
import { SORT_ASC, SORT_DESC } from '../../constants/search'
import { REPORT_DESIGN } from '../../constants/FilesTypes'

export type UploadTranslationFileData = {
  reportName: string
  language: string
  content: string
}

export type DownloadTranslationFileData = {
  reportName: string
  language: string
  filename: string
}

export type DialogDeleteRestoreReport = DialogState & {
  actionText: string
  version?: string
  title: any
  action: string
  data?: any
}
export const DELETE_REPORT_ACTION = 'DELETE_REPORT_ACTION'
export const DELETE_VERSION_REPORT_ACTION = 'DELETE_VERSION_REPORT_ACTION'
export const RESTORE_VERSION_REPORT_ACTION = 'RESTORE_VERSION_REPORT_ACTION'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  btnCreateReport: {
    marginTop: theme.spacing(4),
  },
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    fill: '#000',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}))

export const ReportsPage = () => {
  const classes = useStyles({})

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Custom hooks
   */

  const isMount = useIsMount()

  /**
   * Redux Hooks
   */
  const dispatch = useDispatch()
  const {
    userSession: { basic_info_account, token, permissions },
    currentAccount: { reports, get_reports, total_reports, load_more_reports },
    intl: { locale },
    content,
  } = useSelector((state: AppState) => state)

  /**
   * React Hooks
   */

  const [alert_info, setAlertInfo] = useState<AlertInfo>({
    type: 'success',
    message: '',
    open: false,
  })

  const [dialog_data, setDialogData] = useState<DialogDeleteRestoreReport>({
    open: false,
    itemName: '',
    itemTitle: '',
    title: '',
    action: '',
    actionText: '',
    data: null,
  })

  const [clone_dialog_data, setCloneDialogData] = useState<DialogState>({
    open: false,
    itemName: '',
    itemTitle: '',
    item: null,
  })

  const [report_name_updated, setReportNameUpdated] = useState<string>('')
  const [is_restore_version_action, setIsRestoreAction] = useState<boolean>(false)
  const [is_update_report_translation_action, setIsUpdateReportTranslationAction] =
    useState<boolean>(false)
  const [sort_obj, setSortObj] = useState<{ field: string; type: string } | null>(null)
  const [page, setPage] = useState<number | null>(null)

  useEffect(() => {
    if (basic_info_account !== null) {
      dispatch(getReports(basic_info_account.url, true))
    }
    return () => {
      dispatch(resetContent())
    }
  }, [basic_info_account, dispatch])

  useEffect(() => {
    if (basic_info_account !== null && sort_obj) {
      dispatch(getReports(basic_info_account.url, true, sort_obj, 0))
    }
  }, [basic_info_account, dispatch, sort_obj])

  useEffect(() => {
    if (basic_info_account !== null && page) {
      dispatch(
        getReports(
          basic_info_account.url,
          true,
          sort_obj ? sort_obj : { field: 'id', type: SORT_DESC },
          page,
          true
        )
      )
    }
  }, [basic_info_account, dispatch, page, sort_obj])

  useEffect(() => {
    if (isMount) {
      if (content.create.loaded && !content.create.loading && !content.create.error) {
        const getMessage = () => {
          let message = formatMessage(messages.cloneReportSuccessful)
          if (is_update_report_translation_action) {
            message = formatMessage(messages.uploadTranslationFileSuccess)
          }
          return message
        }

        setReportNameUpdated('')
        setAlertInfo({
          open: true,
          type: 'success',
          message: getMessage(),
        })
        dispatch(resetContent())
      } else if (content.delete.loaded && !content.delete.loading && !content.delete.error) {
        const getMessage = () => {
          const message = formatMessage(messages.deleteReportSuccessful)
          return message
        }

        setAlertInfo({
          open: true,
          type: 'success',
          message: getMessage(),
        })
      } else if (content.update.loaded && !content.update.loading && !content.update.error) {
        const getMessage = () => {
          let message = formatMessage(messages.deleteVersionReportSuccessful)
          if (is_restore_version_action) {
            message = formatMessage(messages.restoreVersionReportSuccessful)
          }
          return message
        }

        setReportNameUpdated('')
        setAlertInfo({
          open: true,
          type: 'success',
          message: getMessage(),
        })
      } else if (get_reports.error) {
        setAlertInfo({
          open: true,
          type: 'error',
          message: getErrorMessage(get_reports.error),
        })
      } else if (content.update.error) {
        setAlertInfo({
          open: true,
          type: 'error',
          message: getErrorMessage(content.update.error),
        })
      } else if (load_more_reports.error) {
        setAlertInfo({
          open: true,
          type: 'error',
          message: getErrorMessage(content.update.error),
        })
      } else if (content.create.error) {
        setAlertInfo({
          open: true,
          type: 'error',
          message: getErrorMessage(content.create.error),
        })
      } else if (content.delete.error) {
        setAlertInfo({
          open: true,
          type: 'error',
          message: getErrorMessage(content.delete.error),
        })
      } else if (content.get.error) {
        setAlertInfo({
          open: true,
          type: 'error',
          message: getErrorMessage(content.get.error),
        })
      }
    }
  }, [
    get_reports,
    content,
    formatMessage,
    dispatch,
    isMount,
    is_restore_version_action,
    is_update_report_translation_action,
    load_more_reports,
  ])

  useEffect(() => {
    if (basic_info_account && (content.delete.loaded || content.create.loaded)) {
      dispatch(getReports(basic_info_account.url, true))
    }
  }, [dispatch, basic_info_account, content.delete, content.create])

  /**
   * Component functions
   */

  const downloadReport = (data: ReportType) => {
    createFile(
      JSON.stringify(data['layout']),
      `${data['@name']}.json`,
      'application/json;charset=utf-8;'
    )
  }

  const handleCloseSnackbar = () => {
    setAlertInfo((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const handleCloseModal = () => {
    setDialogData((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const handleCloseCloneDialog = () => {
    setCloneDialogData((prev_state) => {
      return { ...prev_state, open: false }
    })
  }

  const cloneReport = (url: string, data: any, name_form: string) => {
    const bodyPost = {
      layout: data.layout,
      translations: data.translations,
      title: name_form,
      id: getFormattedObjectId(name_form),
      '@type': 'ReportDesign',
    }
    dispatch(createContent(`${url}/${REPORT_DESIGN}/`, bodyPost))
  }

  const saveReportTranslationFile = (data: UploadTranslationFileData) => {
    if (basic_info_account) {
      setIsUpdateReportTranslationAction(true)
      dispatch(
        createContent(
          `${basic_info_account.url}/${REPORT_DESIGN}/${data.reportName}/@uploadPo?language=${data.language}`,
          data.content,
          { 'content-type': 'text/plain' }
        )
      )
    }
  }

  const downloadReportTranslationFile = (data: DownloadTranslationFileData) => {
    if (basic_info_account && token) {
      setIsRestoreAction(false)
      setIsUpdateReportTranslationAction(false)

      fetch(
        `${config.apiUrl}/${basic_info_account.url}/${REPORT_DESIGN}/${data.reportName}/@downloadPo?language=${data.language}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
            Accept: 'text/plain',
            'Content-Type': 'text/plain',
          },
        }
      )
        .then((response: any) => {
          setReportNameUpdated('')

          if (response.status !== 200) {
            throw new Error(response)
          }

          response.text().then((dataResponse: any) => {
            createFile(dataResponse as string, data.filename, 'text/plain;charset=utf-8;')
          })
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  const handleGenericAction = () => {
    switch (dialog_data.action) {
      case DELETE_REPORT_ACTION:
        deleteReport()
        break
      case DELETE_VERSION_REPORT_ACTION:
        deleteReportVersion(dialog_data.data.version)
        break
      case RESTORE_VERSION_REPORT_ACTION:
        restoreVersion(dialog_data.data.layout)
        break
      default:
        break
    }
  }

  const deleteReportVersion = (version: string) => {
    if (basic_info_account && dialog_data.itemName !== '') {
      setIsRestoreAction(false)
      setIsUpdateReportTranslationAction(false)
      const verison_data = version.split('-')
      const bodyPost = {
        versions: {
          op: 'del',
          value: {
            bucket_index: parseInt(verison_data[0]),
            item_index: parseInt(verison_data[1]),
          },
        },
      }
      setReportNameUpdated(dialog_data.itemName)
      dispatch(
        updateContent(
          `${basic_info_account.url}/${REPORT_DESIGN}/${dialog_data.itemName}`,
          bodyPost
        )
      )
    }
  }

  const deleteReport = () => {
    if (basic_info_account && dialog_data.itemName !== '') {
      setIsRestoreAction(false)
      setIsUpdateReportTranslationAction(false)
      dispatch(deleteContent(`${basic_info_account.url}/${REPORT_DESIGN}/${dialog_data.itemName}`))
    }
  }

  const restoreVersion = (layout: any) => {
    if (basic_info_account && dialog_data.itemName !== '') {
      setIsRestoreAction(true)
      setIsUpdateReportTranslationAction(false)
      const bodyPost = {
        layout: layout,
      }
      dispatch(
        updateContent(
          `${basic_info_account.url}/${REPORT_DESIGN}/${dialog_data.itemName}`,
          bodyPost
        )
      )
    }
  }

  const getUrlToEditReport = (report: ReportType): string => {
    const routerObj = routes[EDIT_REPORT_ROUTE]
    if (routerObj.pathWithParam) {
      return routerObj.pathWithParam(report['@name'])
    }
    return routes[NOT_FOUND_PAGE_ROUTE].path
  }

  const getUrlPreviewReport = (report: ReportType): string => {
    const routerObj = routes[PREVIEW_REPORT_PAGE_ROUTE]
    if (routerObj.pathWithParam) {
      return routerObj.pathWithParam(report['@name'])
    }
    return routes[NOT_FOUND_PAGE_ROUTE].path
  }

  const isLoadingData = () => {
    return get_reports.loading || load_more_reports.loading
  }

  /**
   * Render
   */

  const renderBreadcrumbs = () => {
    const links = [
      {
        path: routes[HOME_PAGE_ROUTE].path,
        message: formatMessage(messages.home),
      },
    ]

    return <CustomBreadcrumbs links={links} currentPageName={formatMessage(messages.reports)} />
  }

  const renderSpinner = () => {
    if (isLoadingData()) {
      return (
        <TableRow>
          <TableCell colSpan={3} align="center">
            <ClipLoaderSpinner loading={isLoadingData()} />
          </TableCell>
        </TableRow>
      )
    }
  }

  const renderCellNoResults = () => {
    if (get_reports.loaded && reports.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={3} align="center">
            <div>{formatMessage(messages.noResultsReports)}</div>
          </TableCell>
        </TableRow>
      )
    }
  }

  return (
    <Container>
      {renderBreadcrumbs()}

      <SnackbarWrapper alertInfo={alert_info} handleCloseSnackbar={handleCloseSnackbar} />

      <Typography variant="h4">{formatMessage(messages.reportsPageTitle)}</Typography>
      {userHasFeaturePermission(FEAT_REPORT_CREATE, permissions) && (
        <Button
          variant="contained"
          color="primary"
          className={classes.btnCreateReport}
          onClick={() => {
            dispatch(push(`${routes[ADD_REPORT_ROUTE].path}?lang=${locale}`))
          }}
        >
          {formatMessage(messages.btnCreateReport)}
        </Button>
      )}

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.cursorPointer}
                onClick={() => {
                  setPage(null)
                  if (sort_obj && sort_obj.type === SORT_ASC) {
                    setSortObj({
                      field: 'id',
                      type: SORT_DESC,
                    })
                  } else {
                    setSortObj({
                      field: 'id',
                      type: SORT_ASC,
                    })
                  }
                }}
              >
                {formatMessage(messages.name)}
              </TableCell>
              <TableCell
                className={classes.cursorPointer}
                onClick={() => {
                  setPage(null)
                  if (sort_obj && sort_obj.type === SORT_ASC) {
                    setSortObj({
                      field: 'modification_date',
                      type: SORT_DESC,
                    })
                  } else {
                    setSortObj({
                      field: 'modification_date',
                      type: SORT_ASC,
                    })
                  }
                }}
              >
                {formatMessage(messages.lastModificationDate)}
              </TableCell>
              <TableCell>{formatMessage(messages.user)}</TableCell>
              <TableCell align="right">{formatMessage(messages.actions)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderCellNoResults()}
            {reports.map((report: ReportType) => (
              <RowReport
                key={report['@name']}
                report={report}
                reset_data={
                  report_name_updated === report['@name'] &&
                  !content.delete.loading &&
                  !content.update.loading &&
                  !content.create.loading
                }
                getUrlToEditReport={getUrlToEditReport}
                getUrlPreviewReport={getUrlPreviewReport}
                downloadReport={downloadReport}
                setCloneDialogData={setCloneDialogData}
                setDialogData={setDialogData}
                setAlertInfo={setAlertInfo}
                saveReportTranslationFile={saveReportTranslationFile}
                downloadReportTranslationFile={downloadReportTranslationFile}
              />
            ))}
            {renderSpinner()}
          </TableBody>
        </Table>

        {!isLoadingData() && reports.length < total_reports ? (
          <Box mt={3} mb={3} display="flex" width="100%" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setPage(page ? page + 1 : 1)
              }}
            >
              {formatMessage(messages.loadMore)}
            </Button>
          </Box>
        ) : null}
      </Paper>

      <DialogGenericAction
        data={dialog_data}
        handleCloseModal={handleCloseModal}
        action={handleGenericAction}
        messages={{
          title: dialog_data.title,
          cancel: formatMessage(messages.cancel),
          actionText: dialog_data.actionText,
        }}
      />

      <DialogCloneAction
        cloneDialogData={clone_dialog_data}
        handleCloseCloneDialog={handleCloseCloneDialog}
        actionCloneReport={cloneReport}
        currentAccount={basic_info_account}
      />
    </Container>
  )
}
