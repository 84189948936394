import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import BarChartIcon from '@material-ui/icons/BarChart'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import ImageIcon from '@material-ui/icons/Image'
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined'
import LanguageIcon from '@material-ui/icons/Language'
import ListIcon from '@material-ui/icons/List'
import MenuIcon from '@material-ui/icons/Menu'
import TitleIcon from '@material-ui/icons/Title'
import { push } from 'connected-react-router'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { ClipLoaderSpinner, CustomBreadcrumbs } from '../../components'
import { FEAT_BI_LINK } from '../../constants/Roles'
import routes, {
  APPLICATIONS_PAGE_ROUTE,
  CHOOSE_PLUGINS_PAGE_ROUTE,
  CUSTOMIZE_APPLICATION_PAGE_ROUTE,
  CUSTOMIZE_LAYOUT_PAGE_ROUTE,
  GENERATE_ACCOUNT_TEXT,
  GENERATE_REPORTS_PAGE_ROUTE,
  MANAGE_IMAGES_PAGE_ROUTE,
  MANAGE_LANGUAGES_PAGE_ROUTE,
  REPORTS_PAGE_ROUTE,
  SERVICE_TOKENS_PAGE_ROUTE,
} from '../../constants/Routes'

import { selectAccount } from '../../actions'
import { userHasFeaturePermission, userHasRoutePermission } from '../../helpers'
import { AppState } from '../../reducers'
import { SimpleAccount } from '../../types/guillotina'

import { messages as messagesMenu } from '../../components/Menu/Menu'
import { config } from '../../config'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  icon: {
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    fill: '#000',
  },
  anchorLink: {
    color: 'rgba(0, 0, 0, 0.87) !important',
    textDecoration: 'none',
  },
  paperMenu: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    cursor: 'pointer',
  },
}))

const messages = defineMessages({
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  actions: {
    id: 'actions',
    defaultMessage: 'Actions',
  },
  selectAccount: {
    id: 'select-account',
    defaultMessage: 'Select account',
  },
})

export const HomePage = () => {
  const classes = useStyles({})

  /**
   * Intl Hook
   */
  const intl = useIntl()
  const { formatMessage } = intl

  /**
   * Redux Hooks
   */

  const dispatch = useDispatch()
  const {
    userSession: { current_account_selected, permissions, token },
    accounts: { list_accounts, get_accounts },
    intl: { locale },
  } = useSelector((state: AppState) => state)

  /**
   * Component functions
   */

  const selectedAccountClick = (account_id: string) => {
    dispatch(selectAccount(account_id))
  }

  /**
   * Render
   */

  const renderSpinner = () => {
    if (get_accounts.loading) {
      return (
        <TableRow>
          <TableCell colSpan={2} align="center">
            <ClipLoaderSpinner loading={get_accounts.loading} />
          </TableCell>
        </TableRow>
      )
    }
  }

  const renderChooseAccount = () => {
    if (!current_account_selected) {
      return (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{formatMessage(messages.name)}</TableCell>
                <TableCell align="right">{formatMessage(messages.actions)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderSpinner()}
              {list_accounts.map((account: SimpleAccount) => (
                <TableRow
                  key={account.id}
                  onClick={() => selectedAccountClick(account.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell scope="row">{account.title}</TableCell>

                  <TableCell align="right">
                    <Tooltip title={formatMessage(messages.selectAccount)}>
                      <ArrowForwardIcon className={classes.icon} />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )
    }
  }

  const renderOption = (route_id: string, title: any, tag_id: string, TagIcon: any) => {
    if (userHasRoutePermission(route_id, permissions)) {
      return (
        <Grid item xs={4} id={tag_id}>
          <Paper
            onClick={() => {
              dispatch(push(`${routes[route_id].path}?lang=${locale}`))
            }}
            className={classes.paperMenu}
          >
            <TagIcon />
            <Box ml={2}>{formatMessage(title)}</Box>
          </Paper>
        </Grid>
      )
    }
  }

  const renderLinkOption = (feature_id: string, title: any, tag_id: string, href: string) => {
    if (userHasFeaturePermission(feature_id, permissions)) {
      return (
        <Grid item xs={4} id={tag_id}>
          <a href={href} target="_blank" className={classes.anchorLink} rel="noopener noreferrer">
            <Paper className={classes.paperMenu}>
              <BarChartIcon />
              <Box ml={2}>{formatMessage(title)}</Box>
            </Paper>
          </a>
        </Grid>
      )
    }
  }

  const renderMenuOptions = () => {
    if (current_account_selected) {
      return (
        <Grid container spacing={3}>
          {renderOption(
            REPORTS_PAGE_ROUTE,
            messagesMenu.manageReports,
            'menu-item-go-to-reports-list-home',
            InsertChartOutlinedIcon
          )}
          {renderOption(
            APPLICATIONS_PAGE_ROUTE,
            messagesMenu.manageApplications,
            'menu-item-go-to-applications-list-home',
            MenuIcon
          )}
          {renderOption(
            CHOOSE_PLUGINS_PAGE_ROUTE,
            messagesMenu.choosePlugins,
            'menu-item-go-to-choose-plugins-home',
            CompareArrowsIcon
          )}
          {renderOption(
            CUSTOMIZE_LAYOUT_PAGE_ROUTE,
            messagesMenu.customLayoutReports,
            'menu-item-go-to-custom-layout-reports-home',
            ListIcon
          )}
          {renderOption(
            CUSTOMIZE_APPLICATION_PAGE_ROUTE,
            messagesMenu.customLayoutApplications,
            'menu-item-go-to-custom-applications-home',
            ListIcon
          )}
          {renderOption(
            MANAGE_LANGUAGES_PAGE_ROUTE,
            messagesMenu.manageLanguages,
            'menu-item-go-to-manage-languages-home',
            LanguageIcon
          )}
          {renderOption(
            MANAGE_IMAGES_PAGE_ROUTE,
            messagesMenu.manageImages,
            'menu-item-go-to-manage-images-home',
            ImageIcon
          )}
          {renderOption(
            GENERATE_REPORTS_PAGE_ROUTE,
            messagesMenu.generateReports,
            'menu-item-go-to-custom-layout-reports-home',
            CreateNewFolderIcon
          )}
          {renderOption(
            SERVICE_TOKENS_PAGE_ROUTE,
            messagesMenu.serviceTokens,
            'menu-item-go-to-service-tokens-home',
            TitleIcon
          )}
          {renderLinkOption(
            FEAT_BI_LINK,
            messagesMenu.bussinesIntelligence,
            'menu-item-go-to-bussines-intelligence-home',
            `${config.businessIntelligenceUrl}/login?token=${token}`
          )}
          {renderOption(
            GENERATE_ACCOUNT_TEXT,
            messagesMenu.generateAccountText,
            'menu-item-go-to-generate-account-text-home',
            TitleIcon
          )}
        </Grid>
      )
    }
  }

  return (
    <Container maxWidth="lg">
      <CustomBreadcrumbs currentPageName={formatMessage(messages.home)} />
      {renderChooseAccount()}
      {renderMenuOptions()}
    </Container>
  )
}
