import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  generateReports: {
    id: 'generate_reports',
    defaultMessage: 'Generate reports',
  },
  noResultsGenerateReports: {
    id: 'no-results-generate-reports',
    defaultMessage: 'There are no configuration created',
  },
  noResultsPdf: {
    id: 'no-results-pdf',
    defaultMessage: 'There are no PDF',
  },
  noResultsHtml: {
    id: 'no-results-html',
    defaultMessage: 'There are no HTML',
  },
  generateReportsPageTitle: {
    id: 'generate-reports-list',
    defaultMessage: 'List of configurations to generate reports',
  },
  btnCreateReportConfig: {
    id: 'btn-create-report-config',
    defaultMessage: 'Create new configuration',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  actions: {
    id: 'actions',
    defaultMessage: 'Actions',
  },
  lastModificationDate: {
    id: 'last-modification-date',
    defaultMessage: 'Last modification',
  },
  generate: {
    id: 'generate',
    defaultMessage: 'Generate',
  },
  generated: {
    id: 'generated',
    defaultMessage: 'Generated',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  deleteConfigGenerateReportDialogTitle: {
    id: 'delete-config-generate-report-dialog-title',
    defaultMessage: 'Do you want to delete the configuration "{name}" ?',
  },
  deletePdfReportDialogTitle: {
    id: 'delete-pdf-report-dialog-title',
    defaultMessage: 'Do you want to delete all pdf ?',
  },
  deleteHtmlReportDialogTitle: {
    id: 'delete-html-report-dialog-title',
    defaultMessage: 'Do you want to delete all html ?',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit',
  },
  add: {
    id: 'add',
    defaultMessage: 'Add',
  },
  clone: {
    id: 'clone',
    defaultMessage: 'Clone',
  },
  btnSave: {
    id: 'save',
    defaultMessage: 'Save',
  },
  genericTextRequired: {
    id: 'generic-text-required',
    defaultMessage: 'This field is required',
  },
  reportTitle: {
    id: 'report-title',
    defaultMessage: 'Report',
  },
  contractsTitle: {
    id: 'contracts-title',
    defaultMessage: 'Contracts',
  },
  contractError: {
    id: 'contract-item-menu-error',
    defaultMessage: 'Contracts are mandatory',
  },
  errorMsgReportNotFill: {
    id: 'error-msg-report-generate-pdf',
    defaultMessage: 'Report is mandatory',
  },
  errorMsgBuildAnyThing: {
    id: 'error-msg-build-any-thing',
    defaultMessage: 'You must build pdfs or htmls',
  },
  deleteConfigGenerateReportSuccessful: {
    id: 'delete-conf-generate-report-successful',
    defaultMessage: 'Great, the configuration to generate set of pdf has been deleted',
  },
  deletePdfSuccessful: {
    id: 'delete-pdf-successful',
    defaultMessage: 'Great, the pdf has been deleted',
  },
  deleteHtmlSuccessful: {
    id: 'delete-html-successful',
    defaultMessage: 'Great, the html has been deleted',
  },
  generateZipSuccessful: {
    id: 'generate-zip-successful',
    defaultMessage: 'Great, the zip has been generated',
  },
  updateConfigGenerateReportSuccessful: {
    id: 'update-conf-generate-report-successful',
    defaultMessage: 'Great, the configuration to generate set of pdf has been updated',
  },
  cloneConfigGenerateReportSuccessful: {
    id: 'clone-conf-generate-report-successful',
    defaultMessage: 'Great,  the configuration to generate set of pdf has been cloned',
  },
  enterListContracts: {
    id: 'enter-list-contracts',
    defaultMessage: 'Enter contracts ids, separate by comas',
  },
  upload: {
    id: 'upload',
    defaultMessage: 'Upload',
  },
  typeFileIncorrect: {
    id: 'type-file-incorrect',
    defaultMessage: 'File must be csv',
  },
  titleSectionSetReports: {
    id: 'title-section-define-reports',
    defaultMessage: 'Set the report to generate PDF',
  },
  titleSectionSetVersion: {
    id: 'title-section-define-version',
    defaultMessage: 'Set the report version (optional)',
  },
  titleInputContractsId: {
    id: 'title-inputs-contracts-id',
    defaultMessage: 'Set contracts ids',
  },
  uploadCsvFileContracts: {
    id: 'upload-csv-file-contracts',
    defaultMessage: 'Or upload cvs file',
  },
  setDateRange: {
    id: 'set-range-dates',
    defaultMessage: 'Set the date range to generate PDF',
  },
  errorMessageSetDateRange: {
    id: 'error-msg-set-date-range',
    defaultMessage: 'Date range is mandatory',
  },
  viewMoreInfoConfigGeneratePdf: {
    id: 'view-more-info-generate-pdf-item',
    defaultMessage: 'View more info',
  },
  viewLogsTitle: {
    id: 'view-logs-title',
    defaultMessage: 'View logs',
  },
  viewAllGenerations: {
    id: 'view-all-generations',
    defaultMessage: 'View all generations',
  },
  detailInfoReportId: {
    id: 'detail-info-report-id',
    defaultMessage: 'Report Id',
  },
  detailInfoDate: {
    id: 'detail-info-date',
    defaultMessage: 'Date',
  },
  detailInfoContracts: {
    id: 'detail-info-contracts',
    defaultMessage: 'Contracts',
  },
  selectDate: {
    id: 'select-date',
    defaultMessage: 'Select date',
  },
  downloadPdf: {
    id: 'download-pdf',
    defaultMessage: 'Download PDFs',
  },
  downloadHtml: {
    id: 'download-html',
    defaultMessage: 'Download HTMLs',
  },
  contractId: {
    id: 'contract-id',
    defaultMessage: 'Contract Id',
  },
  version: {
    id: 'version',
    defaultMessage: 'Version',
  },
  downloadAll: {
    id: 'download-all',
    defaultMessage: 'Download all',
  },
  createZipFile: {
    id: 'create-zip-file',
    defaultMessage: 'Create zip file',
  },
  deleteAll: {
    id: 'delete-all',
    defaultMessage: 'Delete all',
  },
  generating: {
    id: 'generating',
    defaultMessage: 'Generating',
  },
  allGenerations: {
    id: 'all-generations',
    defaultMessage: 'All Generations',
  },
  loadMore: {
    id: 'load-more',
    defaultMessage: 'Load More',
  },
  buildHtml: {
    id: 'build-html',
    defaultMessage: 'Build Html',
  },
  buildPdf: {
    id: 'build-pdf',
    defaultMessage: 'Build pdf',
  },
})
