import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer, { AppState } from '../reducers'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

import { crashReporter, queryParamMiddleware, ApiMiddleware } from '../middleware'

import { History } from 'history'

const loggerMiddleware = createLogger()

export const configureStore = (preloadedState: AppState, history: History, apiHelper: any) => {
  const middlewares = composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      crashReporter,
      thunkMiddleware,
      ApiMiddleware(apiHelper),
      loggerMiddleware,
      queryParamMiddleware
    )
  )

  const store = createStore(createRootReducer(history), preloadedState, middlewares)
  return store
}
