/**
 * Current account reducer.
 * @module reducers/currentAccount/currentAccount
 */

import { ReducerStatusAsync } from '../../types/reducer'
import { AnyAction } from 'redux'
import {
  DetailAccount,
  ReportType,
  ApplicationType,
  SetPdfType,
  ContractsType,
} from '../../types/guillotina'

import {
  GET_ACCOUNT,
  GET_REPORTS,
  GET_APPLICATIONS,
  GET_GENERATE_REPORTS,
  LOGOUT,
  GET_CONTRACTS,
  SELECT_ACCOUNT,
  LOAD_MORE_REPORTS,
  LOAD_MORE_GENERATE_REPORTS,
  LOAD_MORE_CONTRACTS,
  LOGIN,
} from '../../constants/ActionTypes'

export type CurrentAccountsReducer = {
  account: DetailAccount | null
  reports: ReportType[]
  total_reports: number
  applications: ApplicationType[]
  total_generate_reports: number
  generate_reports: SetPdfType[]
  total_contracts: number
  contracts: ContractsType[]
  get_account: ReducerStatusAsync
  get_reports: ReducerStatusAsync
  load_more_reports: ReducerStatusAsync
  get_applications: ReducerStatusAsync
  get_generate_reports: ReducerStatusAsync
  load_more_generate_reports: ReducerStatusAsync
  get_contracts: ReducerStatusAsync
  load_more_contracts: ReducerStatusAsync
}

const initialState: CurrentAccountsReducer = {
  account: null,
  reports: [],
  total_reports: 0,
  applications: [],
  total_generate_reports: 0,
  generate_reports: [],
  total_contracts: 0,
  contracts: [],
  get_account: {
    error: null,
    loaded: false,
    loading: false,
  },
  get_reports: {
    error: null,
    loaded: false,
    loading: false,
  },
  load_more_reports: {
    error: null,
    loaded: false,
    loading: false,
  },
  get_applications: {
    error: null,
    loaded: false,
    loading: false,
  },
  get_generate_reports: {
    error: null,
    loaded: false,
    loading: false,
  },
  load_more_generate_reports: {
    error: null,
    loaded: false,
    loading: false,
  },
  get_contracts: {
    error: null,
    loaded: false,
    loading: false,
  },
  load_more_contracts: {
    error: null,
    loaded: false,
    loading: false,
  },
}

/**
 * Get request value
 * @function getRequestValue
 * @param {string} actionType Action type.
 * @returns {string} Request value.
 */
const getRequestValue = (actionType: string) => {
  const splitAction = actionType.split('_')

  if (actionType.indexOf('LOAD_MORE') !== -1) {
    if (splitAction.length === 5) {
      return `${splitAction[2]}_${splitAction[3]}`.toLowerCase()
    }
    return splitAction[2].toLowerCase()
  } else {
    if (splitAction.length === 4) {
      return `${splitAction[1]}_${splitAction[2]}`.toLowerCase()
    }
    return splitAction[1].toLowerCase()
  }
}

/**
 * Get initial value from key
 * @function getInitialValue
 * @param {string} key key value.
 * @returns {string} Value.
 */
const getInitialValue = (key: string) => {
  if (key === 'account') return null
  return []
}

/**
 * Get value from key
 * @function getValue
 * @param {string} key key value.
 * @param {any} data data after call.
 * @returns {any} Value.
 */
const getValue = (key: string, data: any) => {
  if (key === 'account') return data.result
  return data.result.items
}

const getTotal = (key: string, data: any, state: CurrentAccountsReducer) => {
  const total_key = `total_${key}`
  if (total_key in state) {
    return { [total_key]: data.result.items_total }
  } else {
    return {}
  }
}

/**
 * Currenta account reducer.
 * @function actions
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function actions(
  state: CurrentAccountsReducer = initialState,
  action: AnyAction
): CurrentAccountsReducer {
  let request_value: any
  switch (action.type) {
    case `${GET_ACCOUNT}_PENDING`:
    case `${GET_REPORTS}_PENDING`:
    case `${GET_APPLICATIONS}_PENDING`:
    case `${GET_GENERATE_REPORTS}_PENDING`:
    case `${GET_CONTRACTS}_PENDING`:
      request_value = getRequestValue(action.type)
      return {
        ...state,
        [request_value]: getInitialValue(request_value),
        [`get_${request_value}`]: {
          loading: true,
          loaded: false,
          error: null,
        },
      }
    case `${LOAD_MORE_REPORTS}_PENDING`:
    case `${LOAD_MORE_GENERATE_REPORTS}_PENDING`:
    case `${LOAD_MORE_CONTRACTS}_PENDING`:
      request_value = getRequestValue(action.type)
      return {
        ...state,
        [`load_more_${request_value}`]: {
          loading: true,
          loaded: false,
          error: null,
        },
      }
    case `${GET_ACCOUNT}_SUCCESS`:
    case `${GET_REPORTS}_SUCCESS`:
    case `${GET_APPLICATIONS}_SUCCESS`:
    case `${GET_GENERATE_REPORTS}_SUCCESS`:
    case `${GET_CONTRACTS}_SUCCESS`:
      request_value = getRequestValue(action.type)
      return {
        ...state,
        ...getTotal(request_value, action, state),
        [request_value]: getValue(request_value, action),
        [`get_${request_value}`]: {
          loading: false,
          loaded: true,
          error: null,
        },
      }
    case `${LOAD_MORE_REPORTS}_SUCCESS`:
    case `${LOAD_MORE_GENERATE_REPORTS}_SUCCESS`:
    case `${LOAD_MORE_CONTRACTS}_SUCCESS`:
      request_value = getRequestValue(action.type)
      return {
        ...state,
        [request_value]: [...(state as any)[request_value], ...action.result.member],
        [`load_more_${request_value}`]: {
          loading: false,
          loaded: true,
          error: null,
        },
      }
    case `${GET_ACCOUNT}_FAIL`:
    case `${GET_REPORTS}_FAIL`:
    case `${GET_APPLICATIONS}_FAIL`:
    case `${GET_GENERATE_REPORTS}_FAIL`:
    case `${GET_CONTRACTS}_FAIL`:
      request_value = getRequestValue(action.type)
      return {
        ...state,
        [request_value]: getInitialValue(request_value),
        [`get_${request_value}`]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      }
    case `${LOAD_MORE_REPORTS}_FAIL`:
    case `${LOAD_MORE_GENERATE_REPORTS}_FAIL`:
    case `${LOAD_MORE_CONTRACTS}_FAIL`:
      request_value = getRequestValue(action.type)
      return {
        ...state,
        [`load_more_${request_value}`]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      }
    case LOGOUT:
    case LOGIN:
    case SELECT_ACCOUNT:
      return initialState
    default:
      return state
  }
}
